import { CommonMetricField, MetricField } from '@/components/metrics/types/MetricField';
import { FunctionComponent } from 'react';
import Metric from '../../../../components/metrics/Metric';
import { MAX_METRICS_CAN_SELECT } from '../../../../components/metrics/MetricsConfig';
import { MetricModel } from '../../../../components/metrics/models/MetricModel';

interface MetricsListProps {
  visibleMetrics: MetricField[];
  selectedMetrics: MetricField[];
  selectedMetricColors: { key: MetricField; color: string }[];
  metricValues: MetricModel[] | undefined;
  onSingleSelectionChanged: (changedMetric: MetricField) => void;
  availableMetrics?: MetricField[];
}

const MetricsList: FunctionComponent<MetricsListProps> = ({
  visibleMetrics,
  selectedMetrics,
  selectedMetricColors,
  metricValues,
  onSingleSelectionChanged,
  availableMetrics = Object.values(CommonMetricField),
}) => {
  const visibleMetricsFiltered = visibleMetrics.filter((metric) => availableMetrics.includes(metric));

  const generateTooltipText = (isSelected: boolean, selectedMetricsLength: number, maxMetrics: number): string => {
    const actionText = isSelected
      ? 'Remove from timeline'
      : selectedMetrics.length >= MAX_METRICS_CAN_SELECT
        ? 'Timeline full'
        : 'Add to timeline';

    return `${actionText} (${selectedMetricsLength}/${maxMetrics} selected)`;
  };

  return visibleMetricsFiltered.map((visibleMetric) => {
    const isSelected = selectedMetrics.includes(visibleMetric);
    const isDisabled = !isSelected && selectedMetrics.length >= MAX_METRICS_CAN_SELECT;
    const tooltipText = generateTooltipText(isSelected, selectedMetrics.length, MAX_METRICS_CAN_SELECT);
    const metricValue = metricValues ? metricValues.find((mv) => mv.key === visibleMetric) : undefined;

    return (
      <Metric
        key={visibleMetric}
        metricField={visibleMetric}
        currentValue={metricValue ? metricValue.current : 0}
        previousValue={metricValue ? metricValue.previous : 0}
        isSelected={isSelected}
        selectionChanged={onSingleSelectionChanged}
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        color={selectedMetricColors.find((color) => color.key === visibleMetric)?.color}
      />
    );
  });
};

export default MetricsList;
