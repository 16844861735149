import MetricsContainer from '@/components/metrics/MetricsContainer';
import { createAdvertisedProductsMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useAdvertisedProductsContext } from '../contexts/AdvertisedProductsContext';

const AdvertisedProductsMetrics: FunctionComponent = () => {
  const { filters, visibleMetrics, setVisibleMetrics, selectedMetrics, setSelectedMetrics, selectedMetricColors, setSelectedMetricColors } =
    useAdvertisedProductsContext();

  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricValues,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: createAdvertisedProductsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getAdvertisedProductsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values\n' + JSON.stringify(result));
      }
    },

    retry: 1,
    enabled: !isEmpty(filters),
  });

  return (
    <MetricsContainer
      metricValues={metricValues}
      isLoading={isLoading}
      isError={isError}
      error={error}
      visibleMetrics={visibleMetrics}
      setVisibleMetrics={setVisibleMetrics}
      selectedMetrics={selectedMetrics}
      setSelectedMetrics={setSelectedMetrics}
      selectedMetricColors={selectedMetricColors}
      setSelectedMetricColors={setSelectedMetricColors}
    />
  );
};

export default AdvertisedProductsMetrics;
