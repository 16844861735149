import { ReactECharts } from '@/components/chart/ReactEcharts';
import { TimeUnit } from '@/components/chart/types';
import { aggregateByPeriod } from '@/components/chart/utils';
import CollapsibleCopyableDetails from '@/components/feedback/CollapsibleCopyableDetails';
import { useFileDownload } from '@/hooks/useFileDownload';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { Alert, AlertTitle, Card, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import {
  BarSeriesOption,
  ECElementEvent,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
  YAXisComponentOption,
  type ECharts,
  type EChartsOption,
  type TooltipComponentOption,
} from 'echarts';
import { isArray, isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import LoadingBlock from '../feedback/LoadingBlock';
import { MetricColor, getConfigForMetric } from '../metrics/MetricsConfig';
import { CommonMetricField, MetricField } from '../metrics/types/MetricField';
import ChartToolbox from './ChartToolbox';
import { ViewChartDataModal } from './ViewChartDataModal';

const GRAPH_HEIGHT = 380;

interface MetricTimelineChartProps {
  selectedMetrics: MetricField[];
  selectedMetricColors: MetricColor[];
  timelineData: TimelineModel | undefined;
  isLoading: boolean;
  error: Error | null;
  isError: boolean;
}

const CampaignTimelineChart: FunctionComponent<MetricTimelineChartProps> = ({
  selectedMetrics,
  selectedMetricColors,
  timelineData,
  isLoading,
  error,
  isError,
}) => {
  const chartInstance = useRef<ECharts | null>(null);
  const hoveredSeries = useRef<string | undefined>();
  const hoveredPoint = useRef<number | undefined>();
  const { downloadBase64ImageSrc, downloadCsvString } = useFileDownload();

  const [isJsonDataModalOpen, setIsJsonDataModalOpen] = useState(false);

  const { t } = useTranslation();

  const [view, setView] = useState<TimeUnit>(TimeUnit.DAY);

  const { getLongFormatterForMetricField, getShortFormatterForMetricField, getWeekday } = useFormatting();

  const getChartOptions = useCallback(
    (data: TimelineModel): EChartsOption => {
      const series: (LineSeriesOption | BarSeriesOption)[] = [];
      const yAxis: YAXisComponentOption[] = [];

      const processedData = view === TimeUnit.DAY ? data : aggregateByPeriod(data, view);

      const xAxisData = processedData.xAxisData;
      const yAxisData = processedData.yAxisData.filter((item) => selectedMetrics.includes(item.key));

      yAxisData.forEach((dataItem, index: number) => {
        const metricConfig = getConfigForMetric(dataItem.key);
        if (!metricConfig) {
          return;
        }

        const color = selectedMetricColors.find((color) => color.key === dataItem.key)?.color;

        if (metricConfig.chartSeriesType === 'bar') {
          const barSeries: BarSeriesOption = {
            id: dataItem.key,
            name: metricConfig.title,
            type: 'bar',
            yAxisIndex: index,
            data: dataItem.values,
            color: color,
            emphasis: {
              focus: 'none',
            },
          };
          series.push(barSeries);
        } else if (metricConfig.chartSeriesType === 'line') {
          const lineSeries: LineSeriesOption = {
            id: dataItem.key,
            name: metricConfig.title,
            type: 'line',
            yAxisIndex: index,
            data: dataItem.values,
            color: color,
            symbol: 'emptyCircle',
            symbolSize: 6,
            emphasis: {
              focus: 'none',
            },
            showSymbol: true,
          };
          series.push(lineSeries);
        }

        yAxis.push({
          name: metricConfig?.title,
          type: 'value',
          position: index === 0 ? 'left' : 'right',
          offset: index === 0 ? 0 : yAxisData.length === 2 ? 0 : 60 * (index - 1), // Conditional offset based on number of Y-axes
          nameTextStyle: {
            fontWeight: 'bold', // Make axis name bold
            fontFamily: 'Roboto',
          },
          axisLabel: {
            fontWeight: 'bold', // Make axis labels bold
            fontFamily: 'Roboto',
            formatter: (value: number) => getShortFormatterForMetricField(dataItem.key)(value),
          },
          axisPointer: {
            // Configure hover formatting for the Y-axis
            label: {
              show: true,
              formatter: (params) => getShortFormatterForMetricField(dataItem.key)(params.value as number),
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: color,
            },
          },
        });
      });
      return {
        xAxis: {
          type: 'category',
          data: xAxisData,
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            formatter: function (value: string) {
              const date = dayjs(value);
              return view === TimeUnit.DAY ? date.format('MMM DD') : `${value}`;
            },
            fontWeight: 'bolder',
            fontFamily: 'Roboto',
          },
          splitLine: {
            show: false, // Hide vertical lines
          },
        },
        yAxis: yAxis,
        series: series,
        grid: {
          left: 65,
          right: yAxisData.length === 1 ? 45 : yAxisData.length === 2 ? 45 : yAxisData.length === 3 ? 115 : yAxisData.length > 3 ? 175 : 10,
          top: 45,
          bottom: 45,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          formatter: function (params: TooltipComponentFormatterCallbackParams): string {
            if (isArray(params)) {
              let result = `<b>${params[0].name}</b>` + '<br/>'; // Add the x-axis series name (date)
              const description = view === TimeUnit.DAY ? `${getWeekday(params[0].name)}<br/>` : '';

              result += description;
              params.forEach((param) => {
                const formattedValue = getLongFormatterForMetricField(param.seriesId as CommonMetricField)(param.value as number);
                if (param.seriesName === hoveredSeries.current) {
                  result += `<b>${param.marker}${param.seriesName}: ${formattedValue}</b><br/>`;
                } else {
                  result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
                }
              });
              return result;
            } else {
              return '';
            }
          },
        } as TooltipComponentOption,
      };
    },
    [view, selectedMetrics],
  );

  function onChartReady(instance: ECharts) {
    chartInstance.current = instance;
    instance.on('mouseover', (params: ECElementEvent) => {
      hoveredSeries.current = params.seriesName;
      hoveredPoint.current = params.dataIndex;
    });
    instance.on('mouseout', () => {
      hoveredSeries.current = undefined;
      hoveredPoint.current = undefined;
    });
  }

  function onViewChartDataClick() {
    if (chartInstance.current) {
      setIsJsonDataModalOpen(true);
    }
  }

  function onDownloadPngClick() {
    if (chartInstance.current) {
      const downloadImgSrc = chartInstance.current.getDataURL({
        excludeComponents: ['toolbox', 'dataZoom'],
      });
      downloadBase64ImageSrc(downloadImgSrc, 'chart.png');
    }
  }

  // Function to get the displayed data
  const getDisplayedData = () => {
    if (chartInstance.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const option: any = chartInstance.current.getOption(); // Get current chart options

      if (isNil(option) || isNil(option.series) || isNil(option.xAxis)) {
        return [];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const seriesData = option.series.map((s: any) => ({
      //   name: s.name,
      //   data: s.data,
      // }));

      const xAxisData = option.xAxis[0].data; // Get the xAxis data (dates)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const seriesData2 = option.series.map((s: any) => s.data); // Get the data for each series

      // Format the data as a table (first row: date, subsequent rows: series data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const table = xAxisData.map((date: any, index: number) => {
        const row: Record<string, unknown> = { Date: date }; // Create a row starting with the date
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        option.series.forEach((s: any, i: number) => {
          row[s.name as string] = seriesData2[i][index]; // Add each series' data to the row
        });
        return row;
      });

      return table;
    }
  };

  const handleJsonDataModalClose = () => {
    setIsJsonDataModalOpen(false);
  };

  const onDownloadDataAsCsv = () => {
    const data = getDisplayedData();

    // Get the data as a CSV string
    const csvData = data
      .map((row: Record<string, string>) => {
        return Object.values(row).join(',');
      })
      .join('\n');

    // Put header row up top
    const headerRow = Object.keys(data[0]).join(',');

    const csvDataWithHeader = headerRow + '\n' + csvData;
    downloadCsvString(csvDataWithHeader, 'chart-data.csv');
  };

  const renderError = () => (
    <Alert severity="error">
      <AlertTitle>Oops!</AlertTitle>
      <div className="flex w-full flex-col">
        <Typography variant="body1">{t('messages.errors.generic')}</Typography>

        <CollapsibleCopyableDetails headerText={'Details'} message={(error as Error).message} />
      </div>
    </Alert>
  );

  const keysInData = timelineData?.yAxisData.map((item) => item.key) ?? [];
  const hasDataForAllSelectedMetrics = selectedMetrics.filter((item) => keysInData.includes(item)).length > 0;

  return (
    <Card className="rounded-xl py-0 ">
      {isError ? renderError() : null}
      {isLoading && !timelineData && selectedMetrics.length > 0 ? (
        <div className="relative w-full" style={{ height: GRAPH_HEIGHT }}>
          <Skeleton variant="rectangular" width="100%" height={GRAPH_HEIGHT} />
          <div className="absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
            <LoadingBlock />
          </div>
        </div>
      ) : timelineData &&
        timelineData.xAxisData.length > 0 &&
        timelineData.yAxisData.length > 0 &&
        selectedMetrics.length > 0 &&
        hasDataForAllSelectedMetrics ? (
        <div className="flex">
          <ReactECharts
            option={getChartOptions(timelineData)}
            style={{ height: `${GRAPH_HEIGHT}px`, paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
            onChartReady={onChartReady}
            settings={{ notMerge: true }}
          />
          {/* Chart toolbox */}
          <div className="flex mr-4 mt-4">
            <ChartToolbox
              view={view}
              setView={setView}
              onViewChartDataClick={onViewChartDataClick}
              onDownloadPngClick={onDownloadPngClick}
              onDownloadDataAsCsv={onDownloadDataAsCsv}
            />
          </div>
          <ViewChartDataModal
            open={isJsonDataModalOpen}
            onClose={handleJsonDataModalClose}
            rowData={getDisplayedData()}
            onDownloadAsCsv={onDownloadDataAsCsv}
          />
        </div>
      ) : (
        !isError && (
          <div className="text-center">
            <Typography variant="overline" className="font-bold text-primary-600">
              select at least one metric to display the timeline graph
            </Typography>
          </div>
        )
      )}
    </Card>
  );
};

export default CampaignTimelineChart;
