import CollapsibleCopyableDetails from '@/components/feedback/CollapsibleCopyableDetails';
import AddMetricButton from '@/components/metrics/AddMetricButton';
import { MetricColor, getUpdatedSelectionColors } from '@/components/metrics/MetricsConfig';
import { MetricsCustomizationModal } from '@/components/metrics/MetricsCustomizationModal';
import { MetricField } from '@/components/metrics/types/MetricField';
import { useTranslation } from '@/lib';
import MetricsList from '@/modules/dashboards/components/forms/MetricsList';
import { Alert, AlertTitle, Card, Skeleton, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { MetricModel } from './models/MetricModel';

interface MetricsContainerProps {
  metricValues: MetricModel[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  visibleMetrics: MetricField[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetrics: MetricField[];
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetricColors: MetricColor[];
  setSelectedMetricColors: React.Dispatch<React.SetStateAction<MetricColor[]>>;
  availableMetrics?: MetricField[];
}

const MetricsContainer: FunctionComponent<MetricsContainerProps> = ({
  metricValues,
  isLoading,
  isError,
  error,
  visibleMetrics,
  setVisibleMetrics,
  selectedMetrics,
  setSelectedMetrics,
  selectedMetricColors,
  setSelectedMetricColors,
  availableMetrics,
}) => {
  const { t } = useTranslation();
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCustomizationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomizationModalOpen(false);
  };

  function onSingleSelectionChanged(changedMetric: MetricField) {
    const newSelectedMetrics = selectedMetrics.includes(changedMetric)
      ? selectedMetrics.filter((metric) => metric !== changedMetric)
      : [...selectedMetrics, changedMetric];

    setSelectedMetrics(newSelectedMetrics);
    const updatedColors = getUpdatedSelectionColors(selectedMetricColors, newSelectedMetrics);
    setSelectedMetricColors(updatedColors);
  }

  const onApplyVisibilityChanges = (newVisibleMetrics: MetricField[]) => {
    setVisibleMetrics(newVisibleMetrics);
    setSelectedMetrics(selectedMetrics.filter((metric) => newVisibleMetrics.includes(metric)));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {isError && (
        <Card className="flex-grow rounded-xl py-0">
          <Alert severity="error">
            <AlertTitle>Oops!</AlertTitle>
            <div className="flex w-full flex-col">
              <Typography variant="body1">{t('messages.errors.generic')}</Typography>
              <CollapsibleCopyableDetails headerText={'Details'} message={error instanceof Error ? error.message : ''} />
            </div>
          </Alert>
        </Card>
      )}
      {isLoading && (
        <div className="flex animate-fadeInBg gap-2">
          {Array(5)
            .fill(1)
            .map((_, i) => (
              <Skeleton key={i} variant="rectangular" width={138} height={90} style={{ borderRadius: '12px' }} />
            ))}
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <MetricsList
            metricValues={metricValues}
            selectedMetrics={selectedMetrics}
            selectedMetricColors={selectedMetricColors}
            visibleMetrics={visibleMetrics}
            onSingleSelectionChanged={onSingleSelectionChanged}
            availableMetrics={availableMetrics}
          />
          <AddMetricButton onClick={handleOpenModal} />
        </>
      )}
      <MetricsCustomizationModal
        isOpen={isCustomizationModalOpen}
        onClose={handleCloseModal}
        visibleMetrics={visibleMetrics}
        onApplyVisibilityChanges={onApplyVisibilityChanges}
        availableMetrics={availableMetrics}
      />
    </div>
  );
};

export default MetricsContainer;
