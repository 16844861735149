import { MetricData } from '@/components/metrics/types/MetricData';
import { ProfileCommonMetricsDTO, ProfileSellerMetricsDTO, ProfilesMetricsWithTeamDTO } from '../api/profile.contracts';
import { ProfileCreateArguments, ProfileModel } from './ProfileModel';

export class ProfileWithMetricsModel extends ProfileModel {
  public teamId: number;
  public teamName: string;
  public metricsDTO: ProfileCommonMetricsDTO;
  public sellerMetricsDTO: ProfileSellerMetricsDTO;
  public comparisonMissing: boolean;

  constructor(args: ProfileMetricsArguments) {
    super(args);
    this.teamId = args.teamId;
    this.teamName = args.teamName;
    this.metricsDTO = args.metrics;
    this.sellerMetricsDTO = args.sellerMetrics;
    this.comparisonMissing = args.comparisonMissing;
  }

  public get impressions(): MetricData {
    return this.metricsDTO.impressions;
  }

  public get clicks(): MetricData {
    return this.metricsDTO.clicks;
  }

  public get orders(): MetricData {
    return this.metricsDTO.orders;
  }

  public get units(): MetricData {
    return this.metricsDTO.units;
  }

  public get ctr(): MetricData {
    return this.metricsDTO.ctr;
  }

  public get cvr(): MetricData {
    return this.metricsDTO.cvr;
  }

  public get cpc(): MetricData {
    return this.metricsDTO.cpc;
  }

  public get spend(): MetricData {
    return this.metricsDTO.spend;
  }

  public get sales(): MetricData {
    return this.metricsDTO.sales;
  }

  public get sso(): MetricData {
    return this.metricsDTO.sso;
  }

  public get sss(): MetricData {
    return this.metricsDTO.sss;
  }

  public get acos(): MetricData {
    return this.metricsDTO.acos;
  }

  public get roas(): MetricData {
    return this.metricsDTO.roas;
  }

  public get rpc(): MetricData {
    return this.metricsDTO.rpc;
  }

  public get cpa(): MetricData {
    return this.metricsDTO.cpa;
  }

  public get aov(): MetricData {
    return this.metricsDTO.aov;
  }

  public get cpm(): MetricData {
    return this.metricsDTO.cpm;
  }

  public get seller_acos(): MetricData {
    return this.sellerMetricsDTO.acos;
  }

  public get seller_asp(): MetricData {
    return this.sellerMetricsDTO.asp;
  }

  public get seller_org_sales(): MetricData {
    return this.sellerMetricsDTO.org_sales;
  }

  public get seller_views(): MetricData {
    return this.sellerMetricsDTO.views;
  }

  public get seller_units(): MetricData {
    return this.sellerMetricsDTO.units;
  }

  public get seller_sales(): MetricData {
    return this.sellerMetricsDTO.sales;
  }

  public get seller_unit_sess(): MetricData {
    return this.sellerMetricsDTO.unit_sess;
  }

  public get seller_cvr(): MetricData {
    return this.sellerMetricsDTO.cvr;
  }

  public get seller_clicks(): MetricData {
    return this.sellerMetricsDTO.clicks;
  }

  public get seller_orders(): MetricData {
    return this.sellerMetricsDTO.orders;
  }

  public static fromUserProfilesWithMetricsResponse(dtos: ProfilesMetricsWithTeamDTO[]): ProfileWithMetricsModel[] {
    return dtos.map(
      (dto) =>
        new ProfileWithMetricsModel({
          ...ProfileModel.fromDTO(dto),
          teamId: dto.team_id,
          teamName: dto.team_name,
          metrics: dto.metrics,
          sellerMetrics: dto.seller_metrics,
          comparisonMissing: dto.comparison_missing,
        }),
    );
  }
}

interface ProfileMetricsArguments extends ProfileCreateArguments {
  teamId: number;
  teamName: string;
  metrics: ProfileCommonMetricsDTO;
  sellerMetrics: ProfileSellerMetricsDTO;
  comparisonMissing: boolean;
}
