import { SortByUnit } from '@/components/buttons/SortByToggleButton';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ComparisonUnit } from '@/components/grid/types';
import { MetricColor } from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/types/MetricField';
import { PropsWithChildren } from 'react';

// INTERFACES
export interface ContextWithFilters {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  setFilterValue: (filter: AlFilterModel) => void;
  setFilterValues: (filters: AlFilterModel[]) => void;
}

export interface ContextWithMetricSelection {
  visibleMetrics: MetricField[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetrics: MetricField[];
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetricColors: MetricColor[];
  setSelectedMetricColors: React.Dispatch<React.SetStateAction<MetricColor[]>>;
}

export interface ContextWithTableToggles {
  comparisonUnit: ComparisonUnit;
  setComparisonUnit: React.Dispatch<React.SetStateAction<ComparisonUnit>>;
  sortByUnit: SortByUnit;
  setSortByUnit: React.Dispatch<React.SetStateAction<SortByUnit>>;
}

export interface MetricsGraphTablePageContext extends ContextWithFilters, ContextWithMetricSelection, ContextWithTableToggles {}

// DEFAULT VALUES
// ContextWithFilters default values
export const defaultFiltersContext: ContextWithFilters = {
  filters: [],
  setFilters: () => {},
  setFilterValue: () => {},
  setFilterValues: () => {},
};

// ContextWithMetricSelection default values
export const defaultMetricSelectionContext: ContextWithMetricSelection = {
  visibleMetrics: [],
  setVisibleMetrics: () => {},
  selectedMetrics: [],
  setSelectedMetrics: () => {},
  selectedMetricColors: [],
  setSelectedMetricColors: () => {},
};

// ContextWithTableToggles default values
export const defaultTableTogglesContext: ContextWithTableToggles = {
  comparisonUnit: 'nominal', // Assuming this is the default value for ComparisonUnit
  setComparisonUnit: () => {},
  sortByUnit: SortByUnit.Count, // Assuming default sort is by count
  setSortByUnit: () => {},
};

// Combined
export const defaultMetricsGraphTablePageContext: MetricsGraphTablePageContext = {
  ...defaultFiltersContext,
  ...defaultMetricSelectionContext,
  ...defaultTableTogglesContext,
};

// PROPS
export interface FiltersPropsWithChildren extends PropsWithChildren {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}
