import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateAdvertisedProductsTableColumnState(): ColumnState[] {
  return [
    //{ colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    //{ colId: ColumnId.ID, hide: true, pinned: 'left' },
    { colId: ColumnId.TITLE, hide: false, width: 440, pinned: 'left' },
    { colId: ColumnId.ASIN, hide: false, width: 90 },
    { colId: ColumnId.SKU, hide: false, width: 90 },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false },
    { colId: ColumnId.AD_GROUP, hide: false },
    { colId: ColumnId.IMPRESSIONS, hide: false },
    { colId: ColumnId.CLICKS, hide: false },
    { colId: ColumnId.ORDERS, hide: false },
    { colId: ColumnId.UNITS, hide: false },
    { colId: ColumnId.CTR, hide: false },
    { colId: ColumnId.CVR, hide: false },
    { colId: ColumnId.CPC, hide: false },
    { colId: ColumnId.SPEND, hide: false },
    { colId: ColumnId.SALES, hide: false },
    { colId: ColumnId.SALES_PREVIOUS_DAYS, hide: false },
    { colId: ColumnId.SPEND_PREVIOUS_DAYS, hide: false },
    { colId: ColumnId.ACOS, hide: false },
    { colId: ColumnId.ROAS, hide: false },
    { colId: ColumnId.RPC, hide: false },
    { colId: ColumnId.CPA, hide: false },
    { colId: ColumnId.AOV, hide: false },
    { colId: ColumnId.CPM, hide: false },
    { colId: ColumnId.SAME_SKU_ORDERS, hide: false },
    { colId: ColumnId.SAME_SKU_SALES, hide: false },
  ];
}
