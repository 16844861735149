import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { DTO } from '@/types/dto-wrapper';
import { isNil, round } from 'lodash-es';
import { OptimizationPreset } from '../OptimizerConfig';
import { MatchType, OptimizationReason, ResponsePreviewDataRow } from '../api/optimization-contracts';
import { BiddingEntity } from './OptimizationModel';

export const MAX_DELTA = 999.99;
export class PreviewDataRow extends DTO<ResponsePreviewDataRow> {
  originalNewValue?: number;

  constructor(data: ResponsePreviewDataRow) {
    super(data);

    this.originalNewValue = data.nv ?? 0;
  }

  public get id(): string {
    return this.dto.i;
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.at;
  }

  public get campaignId(): string {
    return this.dto.ci;
  }

  public get campaignName(): string {
    return this.dto.cn;
  }

  public get campaignGroup(): string {
    return this.dto.cg;
  }

  public get biddingEntity(): BiddingEntity {
    if (this.campaignAdType == CampaignAdType.BRANDS && this.dto.b == BiddingEntity.PLACEMENT_TOP) {
      return BiddingEntity.BRANDS_PLACEMENT_TOP;
    }
    return this.dto.b;
  }

  public get targeting(): string {
    return this.dto.t;
  }

  public get match(): MatchType {
    return this.dto.m;
  }

  public get adGroup(): string {
    return this.dto.ag;
  }

  public get oldValue(): number {
    return this.dto.ov;
  }

  public get newValue(): number {
    return this.dto.nv;
  }

  public set newValue(value: number) {
    this.dto.nv = value;
  }

  public get delta(): number {
    if (this.dto.ov == this.dto.nv) {
      return 0;
    }

    if (this.biddingEntity == BiddingEntity.KEYWORD || this.biddingEntity == BiddingEntity.PRODUCT_TARGET) {
      return this.dto.ov != 0 ? this.dto.nv / this.dto.ov - 1 : MAX_DELTA;
    }

    return round(this.dto.nv - this.dto.ov, 2); // placements are rounded
  }

  public get reasons(): OptimizationReason[] {
    return this.dto.rs;
  }

  public get lastOptimizedAt(): string | null {
    return this.dto.lo;
  }

  public get groupTacos(): number {
    return this.dto.gt;
  }

  public get groupPreset(): OptimizationPreset {
    return this.dto.gp;
  }

  public get groupBidCeiling(): number {
    return this.dto.gbc;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get sso(): MetricData {
    return this.dto.sso;
  }

  public get sss(): MetricData {
    return this.dto.sss;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get campaignIsVideo(): boolean {
    return this.dto.v ?? false;
  }

  public get isOptimizingDisabled(): boolean {
    return this.isSBToS || (this.isPlacement && this.campaignIsVideo);
  }

  public get isSBToS(): boolean {
    // bidding entity is mapped for brands
    return (
      this.campaignAdType == CampaignAdType.BRANDS &&
      (this.biddingEntity == BiddingEntity.BRANDS_PLACEMENT_TOP || this.biddingEntity == BiddingEntity.PLACEMENT_TOP)
    );
  }

  public get isTarget(): boolean {
    return [BiddingEntity.KEYWORD, BiddingEntity.PRODUCT_TARGET].includes(this.biddingEntity);
  }

  public get isPlacement(): boolean {
    return [
      BiddingEntity.PLACEMENT_TOP,
      BiddingEntity.PLACEMENT_PRODUCT_PAGE,
      BiddingEntity.PLACEMENT_REST_OF_SEARCH,
      BiddingEntity.HOME,
      BiddingEntity.DETAIL_PAGE,
      BiddingEntity.OTHER,
    ].includes(this.biddingEntity);
  }

  public static fromResponseArray(responseArray: ResponsePreviewDataRow[]): PreviewDataRow[] {
    return isNil(responseArray) ? [] : responseArray.map(PreviewDataRow.fromResponse);
  }

  public static fromResponse(dto: ResponsePreviewDataRow): PreviewDataRow {
    return new PreviewDataRow(dto);
  }
}
