import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { METRICS } from './MetricsConfig';
import { MetricField } from './types/MetricField';

interface MetricsCheckboxListProps {
  value: MetricField[];
  onChange: (selectedMetrics: MetricField[]) => void;
  maxSelectedItems?: number;
}

const MetricsCheckboxList: FunctionComponent<MetricsCheckboxListProps> = ({ value, onChange, maxSelectedItems }) => {
  const [showHelperText, setShowHelperText] = useState(false);

  const handleCheckboxChange = (metric: MetricField) => {
    const isSelected = value.includes(metric);
    let updatedValue: MetricField[];

    if (isSelected) {
      updatedValue = value.filter((selectedMetric) => selectedMetric !== metric);
    } else {
      if (!maxSelectedItems || value.length < maxSelectedItems) {
        updatedValue = [...value, metric];
      } else {
        setShowHelperText(true);
        return;
      }
    }

    setShowHelperText(false);
    onChange(updatedValue);
  };

  if (!value) {
    value = [];
  }

  return (
    <FormGroup>
      <div className="grid grid-cols-2">
        {Object.values(METRICS).map((metric) => (
          <FormControlLabel
            key={metric.key}
            control={<Checkbox checked={value.includes(metric.key)} onChange={() => handleCheckboxChange(metric.key)} />}
            label={metric.title}
          />
        ))}
      </div>
      {showHelperText && maxSelectedItems && <FormHelperText error>You can only select up to {maxSelectedItems} items.</FormHelperText>}
    </FormGroup>
  );
};

export default MetricsCheckboxList;
