import { SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';
import { CommonMetricField } from '../metrics/types/MetricField';
import AlSelect from './AlSelect';

interface MetricSelectProps {
  value: CommonMetricField | '';
  onChange: (value: CommonMetricField | '') => void;
  helperText?: string;
}

const MetricSelect: FunctionComponent<MetricSelectProps> = ({ value, onChange, helperText }) => {
  const handleChange = (event: SelectChangeEvent<CommonMetricField | ''>) => {
    onChange(event.target.value as CommonMetricField | '');
  };

  // TODO: handle seller central metrics
  const options: { label: string; value: CommonMetricField }[] = [
    { label: 'Impressions', value: CommonMetricField.IMPRESSIONS },
    { label: 'Clicks', value: CommonMetricField.CLICKS },
    { label: 'Orders', value: CommonMetricField.ORDERS },
    { label: 'CTR', value: CommonMetricField.CTR },
    { label: 'CVR', value: CommonMetricField.CVR },
    { label: 'CPC', value: CommonMetricField.CPC },
    { label: 'Spend', value: CommonMetricField.SPEND },
    { label: 'Sales', value: CommonMetricField.SALES },
    { label: 'ACOS', value: CommonMetricField.ACOS },
    { label: 'ROAS', value: CommonMetricField.ROAS },
    { label: 'RPC', value: CommonMetricField.RPC },
    { label: 'CPA', value: CommonMetricField.CPA },
    { label: 'AOV', value: CommonMetricField.AOV },
    { label: 'CPM', value: CommonMetricField.CPM },
  ];

  return (
    <AlSelect
      label="Select Metric"
      value={value}
      options={options}
      onChange={handleChange}
      renderOption={(v) => v.label}
      valueExtractor={(v) => v.value}
      helperText={helperText}
    />
  );
};

export default MetricSelect;
