import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { Routes } from '@/router/router-paths';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SellerCentralAuthorizationDialog } from './SellerCentralAuthorizationDialog';
import { VendorCentralAuthorizationDialog } from './VendorCentralAuthorizationDialog';

export interface SellerOrVendorCentralSelectionDialogProps {
  open: boolean;
  onClose: () => void;
}

export function SellerOrVendorCentralSelectionDialog({ onClose, open }: SellerOrVendorCentralSelectionDialogProps) {
  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const [isSellerCentralDialogOpen, setIsSellerCentralDialogOpen] = useState(false);

  const onAuthSellerCentralClicked = () => {
    onClose();
    setIsSellerCentralDialogOpen(true);
  };

  const [isVendorCentralDialogOpen, setIsVendorCentralDialogOpen] = useState(false);

  // const onAuthVendorCentralClicked = () => {
  //   onClose();
  //   setIsVendorCentralDialogOpen(true);
  // };

  const handleClose = () => {
    onClose();
    setIsSellerCentralDialogOpen(false);
    setIsVendorCentralDialogOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>
          <div className="flex flex-row">
            Authorize Seller Central
            <div className="flex flex-grow"></div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="flex flex-row justify-between p-4 gap-4 min-h-56">
            <Paper
              elevation={3}
              className="flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
            >
              <Typography variant="h5">Authorize Seller Central</Typography>

              <Button variant="contained" onClick={onAuthSellerCentralClicked}>
                Authorize
              </Button>
            </Paper>
            <Paper
              elevation={3}
              className="flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
            >
              <Typography variant="h5">Authorize Vendor Central</Typography>
              <Typography className="p-1" variant="subtitle1">
                Coming Soon!
              </Typography>
              {/* <Button variant="contained" disabled={true} onClick={onAuthVendorCentralClicked}>
                Authorize
              </Button> */}
            </Paper>
          </div>
        </DialogContent>
      </Dialog>

      <SellerCentralAuthorizationDialog open={isSellerCentralDialogOpen} onClose={() => setIsSellerCentralDialogOpen(false)} />

      <VendorCentralAuthorizationDialog open={isVendorCentralDialogOpen} onClose={() => setIsVendorCentralDialogOpen(false)} />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to auth seller or vendor central"
      ></PaywallModal>
    </>
  );
}
