import { ListItemIcon } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

// Interface for individual dropdown items
export interface DropdownButtonItem extends ButtonProps {
  label: string;
  onClick: () => void;
  startIcon?: React.JSX.Element;
}

export interface DropdownButtonProps extends ButtonProps {
  items: DropdownButtonItem[];
}

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(({ items, ...buttonProps }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const variantDisplay = buttonProps.variant ? buttonProps.variant : open ? 'contained' : 'outlined';
  return (
    <div>
      <Button
        {...buttonProps} // Spread buttonProps to inherit all Button attributes
        onClick={handleClick}
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        className="whitespace-nowrap"
        variant={variantDisplay}
        ref={ref} // Attach forwarded ref
      >
        {buttonProps.children}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              handleClose(); // Close the menu after item click
            }}
          >
            {item.startIcon && <ListItemIcon>{item.startIcon}</ListItemIcon>}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
