import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignMappingFilters, getUpdatedFiltersValue } from '@/components/filter-builder/models/AlFilterModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ContextWithFilters, FiltersPropsWithChildren, defaultFiltersContext } from '@/types/context-shared';
import { cloneDeep, isEqual } from 'lodash-es';
import { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

export const CAMPAIGN_MAPPING_CONTEXT_KEY = 'campaign-mapping-context';

export const CampaignMappingContext = createContext<ContextWithFilters>({
  ...defaultFiltersContext,
});

export const CampaignMappingProvider: FunctionComponent<FiltersPropsWithChildren> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  const [isMounted, setIsMounted] = useState(false);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  //TODO: review
  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(CAMPAIGN_MAPPING_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        CAMPAIGN_MAPPING_CONTEXT_KEY,
        activeProfile?.id,
        getDefaultCampaignMappingFilters(),
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <CampaignMappingContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
      }}
    >
      {children}
    </CampaignMappingContext.Provider>
  );
};

export const useCampaignMappingContext = (): ContextWithFilters => {
  const context = useContext(CampaignMappingContext);
  if (!context) {
    throw new Error('useCampaignMappingContext must be used within a CampaignMappingProvider');
  }
  return context;
};
