import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import dayjs from 'dayjs';
import { FilterDTO } from '../../filter-builder/api/filters-contracts';
import { CommonMetricField, SellerMetricField } from '../types/MetricField';

export interface CommonMetricsDTO {
  impressions: MetricDTO;
  clicks: MetricDTO;
  orders: MetricDTO;
  units: MetricDTO;
  ctr: MetricDTO;
  cvr: MetricDTO;
  cpc: MetricDTO;
  spend: MetricWithPreviousDaysDTO;
  sales: MetricWithPreviousDaysDTO;
  sso: MetricDTO; // same SKU orders
  sss: MetricDTO; // same SKU sales
  acos: MetricDTO;
  roas: MetricDTO;
  rpc: MetricDTO;
  cpa: MetricDTO;
  aov: MetricDTO;
  cpm: MetricDTO;
}

export type MetricDTO = [current: number, previous: number];

export type MetricWithPreviousDaysDTO = [current: number, previous: number, ...previousDays: number[]];

export const METRIC_PREVIOUS_AMOUNT_OF_DAYS = 30;

export function expandPreviousDayFromMetricDTO(metricDTO: MetricWithPreviousDaysDTO, timezone: string): DateValuePair[] {
  return metricDTO.slice(2).map<DateValuePair>((value, index) => {
    // The date range is hardcoded to 30 days in the backend
    // The last index is the current day - 1

    const date = new Date(dayjs().tz(timezone).format(DATE_FORMAT));

    date.setDate(date.getDate() - METRIC_PREVIOUS_AMOUNT_OF_DAYS + index);
    const formattedDate = date.toISOString().split('T')[0];
    return {
      date: formattedDate,
      value: value,
    };
  });
}

export interface MetricsDTO extends Record<CommonMetricField, MetricDTO> {
  comparison_missing: boolean;
}

export interface ProductMetricsDTO extends MetricsDTO {
  seller_metrics: Record<SellerMetricField, MetricDTO>;
}

export interface DateValuePair {
  date: string;
  value: number;
}
export interface MetricsRequest {
  filters: FilterDTO[];
}
