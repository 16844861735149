export enum CommonMetricField {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  SAME_SKU_ORDERS = 'sso',
  SAME_SKU_SALES = 'sss',
  ACOS = 'acos',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  UNITS = 'units',
}

export enum SellerMetricField {
  SELLER_ACOS = 'seller_acos', // Average Cost Of Total Sales - Total Advertising Spend / Total Sales
  SELLER_ASP = 'seller_asp', // Total average selling price - Total sales / total units
  SELLER_CLICKS = 'seller_clicks',
  SELLER_CVR = 'seller_cvr', // Total conversion rate - Total orders / Total clicks (sessions)
  SELLER_ORDERS = 'seller_orders',
  SELLER_ORG_SALES = 'seller_org_sales',
  SELLER_SALES = 'seller_sales',
  SELLER_UNIT_SESS = 'seller_unit_sess', // Units per session (UPS) - total units / total clicks (sessions)
  SELLER_UNITS = 'seller_units',
  SELLER_VIEWS = 'seller_views', // Detail Page Views
}

export type MetricField = CommonMetricField | SellerMetricField;

export type MetricAggregates = Record<MetricField, { current: number }>;

export function stringToSellerMetricFieldString(key: string): string {
  return 'seller_' + key;
}
