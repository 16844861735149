import { getSeparatedValuesFromText, truncateString } from '@/modules/application/utils';
import { Autocomplete, AutocompleteInputChangeReason, Chip, TextField, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { FunctionComponent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';

interface FilterStringExactMatchProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterStringExactMatch: FunctionComponent<FilterStringExactMatchProps> = ({ filter }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const filterKey = filter.key;

  const [tags, setTags] = useState<string[]>(
    filter.conditions && Array.isArray(filter.conditions[0].values) ? filter.conditions[0].values.map(String) : [],
  );
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const inputValueTrimmed = inputValue.trim();
      if (isValueValid(inputValueTrimmed)) {
        setTags([...tags, inputValueTrimmed]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toast.error(value + ' is already in the list');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (indexToDelete: number) => {
    setTags((prevTags) => prevTags.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    filter.conditions = [
      {
        values: tags,
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }, [tags]);

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const newTags = getSeparatedValuesFromText(event.clipboardData.getData('Text'), tags);

    setTags((prevTags) => [...new Set([...prevTags, ...newTags])]); // Combine new and existing tags, removing duplicates
    setInputValue(''); // Clear the input field after processing the paste
  };

  return (
    <div className="flex flex-row gap-2">
      <div>
        <Autocomplete
          renderInput={(params) => (
            <TextField
              label={'Exact match'}
              {...params}
              placeholder="Type exact phrase and press [ENTER] to add it to the list"
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
          id={'input-' + filterKey}
          clearIcon={false}
          options={[]}
          freeSolo
          multiple
          value={tags} // Setting value to the existing tags
          className="w-100"
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          clearOnBlur
          renderTags={(value) =>
            value.map((tag, index) => (
              <Tooltip key={index} title={tag}>
                <Chip className="m-0.5" size="small" label={truncateString(tag)} onDelete={() => handleDelete(index)} />
              </Tooltip>
            ))
          }
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onPaste={handlePaste}
        />
      </div>
    </div>
  );
};
