import MetricsContainer from '@/components/metrics/MetricsContainer';
import { createProductsMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { CommonMetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { Environment } from '@/config/Environment';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useProductsContext } from '../contexts/ProductsContext';

const ProductsMetrics: FunctionComponent = () => {
  const { filters, visibleMetrics, setVisibleMetrics, selectedMetrics, setSelectedMetrics, selectedMetricColors, setSelectedMetricColors } =
    useProductsContext();

  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricValues,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: createProductsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getProductsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values\n' + JSON.stringify(result));
      }
    },

    retry: 1,
    enabled: !isEmpty(filters),
  });

  const availableMetrics = [
    ...Object.values(CommonMetricField),
    ...(Environment.isDev() && (activeProfile?.isSeller || activeProfile?.isVendor) ? Object.values(SellerMetricField) : []),
  ];
  return (
    <MetricsContainer
      metricValues={metricValues}
      isLoading={isLoading}
      isError={isError}
      error={error}
      visibleMetrics={visibleMetrics}
      setVisibleMetrics={setVisibleMetrics}
      selectedMetrics={selectedMetrics}
      setSelectedMetrics={setSelectedMetrics}
      selectedMetricColors={selectedMetricColors}
      setSelectedMetricColors={setSelectedMetricColors}
      availableMetrics={availableMetrics}
    />
  );
};

export default ProductsMetrics;
