import { DeleteSweep } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, DialogActions } from '@mui/material';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { FilterRow } from './FilterRow';
import { PendingFiltersContext } from './context/PendingFiltersContext';
import { AlFilterModel, getUpdatedFiltersValue } from './models/AlFilterModel';
import { FilterKey } from './types/FilterKey';

interface FilterBuilderProps {
  onSearch: (filters: AlFilterModel[]) => void;
  filtersDefaultValue: AlFilterModel[];
  availableFilters: AlFilterModel[];
  onCancel: () => void;
}

export const FilterBuilder: FunctionComponent<FilterBuilderProps> = ({ onSearch, filtersDefaultValue, availableFilters, onCancel }) => {
  // cloneDeep required so pending filter condition changing doesn't change the original global filter conditions
  const [pendingFilters, setPendingFilters] = useState<AlFilterModel[]>(cloneDeep(filtersDefaultValue));

  const updateFilterByFilterKey = (oldKey: FilterKey, filter: AlFilterModel | null) => {
    setPendingFilters((previousValue) => {
      // Delete filter
      if (filter == null) {
        const newFilters = previousValue.filter((filterItem) => filterItem.key !== oldKey);
        return newFilters;
      }
      // Update filter
      const index = previousValue.findIndex((filterItem) => filterItem.key === oldKey);
      // Transfer conditions from old filter to new filter
      // Do not transfer when the old filter has options as it usually doesn't make sense (e.g. selected campaigns to campaign state)
      if (previousValue[index].type === filter.type && isNil(previousValue[index].options)) {
        filter.conditions = cloneDeep(previousValue[index].conditions)?.map((c, ix) => {
          if (filter.isInverseFilter != previousValue[index].isInverseFilter) {
            c.operator = filter.defaultConditionOperators[ix];
          }

          return c;
        });
      }
      previousValue[index] = filter;
      return [...previousValue];
    });
  };

  const setPendingFilterValue = (filter: AlFilterModel) => {
    setPendingFilters((previousValue) => {
      return getUpdatedFiltersValue(previousValue, filter);
    });
  };

  const usedFilterKeys = pendingFilters.map((filter) => filter.key);
  const unUsedFilters = availableFilters.filter((f) => !usedFilterKeys.includes(f.key));

  const onAddFilterClicked = () => {
    if (unUsedFilters.length === 0) {
      return;
    }

    const firstUnusedFilterKey = unUsedFilters[0].key;
    const newFilterToAdd = availableFilters.find((filter) => filter.key === firstUnusedFilterKey);

    if (newFilterToAdd) {
      setPendingFilters((prevFilters) => [...prevFilters, newFilterToAdd]);
    }
  };

  const onApplyFiltersClicked = () => {
    onSearch(pendingFilters);
  };

  const onDiscardAllClicked = () => {
    // Only keep the date filters (which aren't shown in the popover)
    const nonFilterBuilderFilters = pendingFilters.filter((f) => !f.isFilterBuilderFilter);
    setPendingFilters(nonFilterBuilderFilters);
    onSearch(nonFilterBuilderFilters);
  };

  const nonHiddenFilters = pendingFilters.filter((f) => f.isFilterBuilderFilter);

  return (
    <PendingFiltersContext.Provider value={{ filters: pendingFilters, setFilters: setPendingFilters, setFilterValue: setPendingFilterValue }}>
      <div className="flex flex-col gap-2">
        <div className="flex min-w-[28rem] flex-col ">
          {pendingFilters.map((filter, index) => (
            <FilterRow
              key={!isEmpty(filter.key) ? filter.key : index}
              filterDefaultValue={filter}
              unUsedFilters={unUsedFilters}
              updateFilterByFilterKey={updateFilterByFilterKey}
            />
          ))}
        </div>
        <div className="flex flex-row justify-between">
          {unUsedFilters.length > 0 && (
            <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onAddFilterClicked}>
              Add New Filter
            </Button>
          )}
          {nonHiddenFilters.length > 0 && (
            <Button variant="text" startIcon={<DeleteSweep />} onClick={onDiscardAllClicked}>
              Discard All Filters
            </Button>
          )}
        </div>

        <DialogActions>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          <Button onClick={onApplyFiltersClicked}>
            {nonHiddenFilters.length === 0
              ? 'Apply No Filters'
              : nonHiddenFilters.length === 1
                ? 'Apply 1 Filter'
                : `Apply ${nonHiddenFilters.length} Filters`}
          </Button>
        </DialogActions>
      </div>
    </PendingFiltersContext.Provider>
  );
};
