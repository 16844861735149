import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { ADVERTISED_PRODUCT_CONTEXT_KEY } from '@/modules/advertised-products/contexts/AdvertisedProductsContext';
import { CAMPAIGN_GROUPS_CONTEXT_KEY } from '@/modules/optimizer/contexts/CampaignGroupsContext';
import { OPTIMIZER_CONTEXT_KEY } from '@/modules/optimizer/contexts/OptimizerContext';
import { PLACEMENTS_CONTEXT_KEY } from '@/modules/placements/contexts/PlacementsContext';
import { PRODUCTS_CONTEXT_KEY } from '@/modules/products/contexts/ProductsContext';
import { SEARCH_TERMS_CONTEXT_KEY } from '@/modules/search-terms/contexts/SearchTermsContext';
import { TARGETING_CONTEXT_KEY } from '@/modules/targeting/contexts/TargetingContext';
import { AlFilterModel } from '../../filter-builder/models/AlFilterModel';
import { MetricModel } from '../models/MetricModel';
import { MetricsDTO, MetricsRequest, ProductMetricsDTO } from './metrics-contracts';

const METRICS_QUERY_KEY = 'metrics'; // don't expose this and only use key creators
export function createOptimizerMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, OPTIMIZER_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createCampaignGroupsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, CAMPAIGN_GROUPS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createTargetingMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, TARGETING_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createPlacementsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, PLACEMENTS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createSearchTermsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, SEARCH_TERMS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createProductsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, PRODUCTS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createAdvertisedProductsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, ADVERTISED_PRODUCT_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export class MetricService {
  async fetchMetrics(filters: AlFilterModel[], endpoint: string): Promise<ApiResponse<MetricModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>(endpoint, requestData);

      return applicationResponse.processPayload(MetricModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCampaignMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/campaigns/metrics');
  }

  async getCampaignGroupsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/campaigns/groups/metrics');
  }

  async getTargetMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/targets/metrics');
  }

  async getPlacementsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/campaigns/bid-adjustments-metrics');
  }

  async getSearchTermsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/search-terms/metrics');
  }

  async getProductsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<ProductMetricsDTO>('/products/metrics', requestData);

      return applicationResponse.processPayload(MetricModel.fromProductDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAdvertisedProductsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricModel[]>> {
    return this.fetchMetrics(filters, '/products/advertised/metrics');
  }
}

export const metricsService = new MetricService();
