import { AmazonBrandsBidOptimization, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { SEARCH_TERMS_CONTEXT_KEY } from '@/modules/search-terms/contexts/SearchTermsContext';
import dayjs from 'dayjs';
import { isNumber } from 'lodash-es';
import { DATE_FORMAT } from '../FiltersConfig';
import { FilterConditionDTO, FilterDTO } from '../api/filters-contracts';
import { FilterKey } from '../types/FilterKey';
import {
  AcosFilterModel,
  AcotsFilterModel,
  AdGroupFilterModel,
  AdGroupNameFilterModel,
  AdGroupNameNotFilterModel,
  AlFilterModel,
  AovFilterModel,
  AspFilterModel,
  BidAdjustmentTypeFilterModel,
  BidOptimizationFilterModel,
  BiddingMethodFilterModel,
  BooleanType,
  BudgetFilterModel,
  CampaignAdTypeFilterModel,
  CampaignDataGroupItemFilterModel,
  CampaignFilterModel,
  CampaignGroupAdTypeFilterModel,
  CampaignGroupFilterModel,
  CampaignGroupNameFilterModel,
  CampaignGroupNameNotFilterModel,
  CampaignNameFilterModel,
  CampaignNameNotFilterModel,
  CampaignStateFilterModel,
  ClicksFilterModel,
  ComparisonDateFilterModel,
  CostTypeFilterModel,
  CpaFilterModel,
  CpcFilterModel,
  CpmFilterModel,
  CreativeTypeFilterModel,
  CtrFilterModel,
  CvrFilterModel,
  DateFilterModel,
  DeltaFilterModel,
  DestinationAdGroupNameFilterModel,
  DestinationAdGroupNameNotFilterModel,
  DestinationCampaignAdTypeFilterModel,
  DestinationCampaignNameFilterModel,
  DestinationCampaignNameNotFilterModel,
  DetailPageViewsFilterModel,
  EmptyFilterModel,
  EntityTypeFilterModel,
  ImpressionsFilterModel,
  LastOptimizedDateModel,
  LogicalOperatorType,
  MatchTypeFilterModel,
  MultiAdGroupsEnabledFilterModel,
  NegativeKeywordMatchTypeFilterModel,
  NegativeTargetLevelFilterModel,
  NegativeTargetingExactMatchFilterModel,
  NegativeTargetingFilterModel,
  NegativeTargetingNotFilterModel,
  OptimizationReasonFilterModel,
  OrdersFilterModel,
  OrganicSalesFilterModel,
  PlacementTypeFilterModel,
  PortfolioFilterModel,
  ProductAsinFilterModel,
  ProductBrandFilterModel,
  ProductCategoryFilterModel,
  ProductDataGroupItemFilterModel,
  ProductSkuFilterModel,
  ProductTitleFilterModel,
  ProductTitleNotFilterModel,
  ReportedAtFilterModel,
  RoasFilterModel,
  RpcFilterModel,
  SalesFilterModel,
  SearchTermDataGroupItemFilterModel,
  SearchTermExactMatchFilterModel,
  SearchTermFilterModel,
  SearchTermHarvestedFilterModel,
  SearchTermNegatedFilterModel,
  SearchTermNotFilterModel,
  SourceAdGroupNameFilterModel,
  SourceAdGroupNameNotFilterModel,
  SourceCampaignAdTypeFilterModel,
  SourceCampaignNameFilterModel,
  SourceCampaignNameNotFilterModel,
  SpendFilterModel,
  TargetDataGroupItemFilterModel,
  TargetStateFilterModel,
  TargetTypeFilterModel,
  TargetingExactMatchFilterModel,
  TargetingFilterModel,
  TargetingNotFilterModel,
  TargetingTypeFilterModel,
  TotalClicksFilterModel,
  TotalCvrFilterModel,
  TotalOrdersFilterModel,
  TotalSalesFilterModel,
  TotalUnitsFilterModel,
  UnitsFilterModel,
  UspFilterModel,
} from './AlFilterModel';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';

interface FilterFactory {
  createFilterModelFromDTO(dto: FilterDTO, options?: { contextKey?: string; teamProfiles?: TeamProfile[] }): AlFilterModel;
}

// TODO: Use record utility type to avoid missing filter keys
class FilterFactoryImp implements FilterFactory {
  createFilterModelFromDTO(dto: FilterDTO, options?: { contextKey?: string; teamProfiles?: TeamProfile[] }): AlFilterModel {
    switch (dto.key as FilterKey) {
      case FilterKey.DATE:
        return new DateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions.map((cond) => {
            return {
              values: cond.values.map((value) => dayjs(value).format(DATE_FORMAT)),
              operator: cond.operator,
            };
          }),
        });

      case FilterKey.COMPARE_DATE:
        return new ComparisonDateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions.map((cond) => {
            return {
              values: cond.values.map((value) => dayjs(value).format(DATE_FORMAT)),
              operator: cond.operator,
            };
          }),
        });

      case FilterKey.TARGET_STATE:
        return new TargetStateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PORTFOLIO_ID:
        return new PortfolioFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.BIDDING_METHOD:
        return new BiddingMethodFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_AD_TYPE: {
        const excludeDisplay = options?.contextKey == SEARCH_TERMS_CONTEXT_KEY;

        return new CampaignAdTypeFilterModel(excludeDisplay, {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });
      }

      case FilterKey.CAMPAIGN_ID:
        return new CampaignFilterModel({
          campaigns: [],
          args: {
            logicalOperator: dto.logical_operator as LogicalOperatorType,
            conditions: dto.conditions,
          },
          teamProfiles: options?.teamProfiles,
        });

      case FilterKey.CAMPAIGN_NAME:
        return new CampaignNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SOURCE_CAMPAIGN_NAME:
        return new SourceCampaignNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DESTINATION_CAMPAIGN_NAME:
        return new DestinationCampaignNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_NAME_NOT:
        return new CampaignNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SOURCE_CAMPAIGN_NAME_NOT:
        return new SourceCampaignNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DESTINATION_CAMPAIGN_NAME_NOT:
        return new DestinationCampaignNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM:
        return new SearchTermFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_NOT:
        return new SearchTermNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_EXACT_MATCH:
        return new SearchTermExactMatchFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_NEGATED:
        return new SearchTermNegatedFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_HARVESTED:
        return new SearchTermHarvestedFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_GROUP_NAME:
        return new CampaignGroupNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_GROUP_NAME_NOT:
        return new CampaignGroupNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_GROUP_AD_TYPE:
        return new CampaignGroupAdTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_GROUP: {
        return new CampaignGroupFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,

          // condition values are saved as strings in local storage as the use the  toDTO() function
          conditions: dto.conditions.map((condition) => {
            return {
              ...condition,
              values: condition.values.map((value) => (isNumber(value) ? value : parseInt(value))),
            };
          }),
        });
      }

      case FilterKey.MULTI_AD_GROUPS_ENABLED:
        return new MultiAdGroupsEnabledFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.AD_GROUP_ID:
        return new AdGroupFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.AD_GROUP_NAME:
        return new AdGroupNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SOURCE_AD_GROUP_NAME:
        return new SourceAdGroupNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DESTINATION_AD_GROUP_NAME:
        return new DestinationAdGroupNameFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.AD_GROUP_NAME_NOT:
        return new AdGroupNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SOURCE_AD_GROUP_NAME_NOT:
        return new SourceAdGroupNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DESTINATION_AD_GROUP_NAME_NOT:
        return new DestinationAdGroupNameNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SOURCE_CAMPAIGN_AD_TYPE:
        return new SourceCampaignAdTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DESTINATION_CAMPAIGN_AD_TYPE:
        return new DestinationCampaignAdTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_STATE:
        return new CampaignStateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGETING_TYPE:
        return new TargetingTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });
      case FilterKey.TARGET_TYPE:
        return new TargetTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.LAST_OPTIMIZED_DATE:
        // TODO IS changed into BETWEEN
        return new LastOptimizedDateModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.BUDGET:
        return new BudgetFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CREATIVE_TYPE:
        return new CreativeTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.COST_TYPE:
        return new CostTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PLACEMENT_TYPE: {
        // See also PlacementTypeFilterModel
        const conditions = dto.conditions.map((cond) => {
          return {
            operator: cond.operator,
            values: cond.values.filter((v) => v != PlacementType.OTHER && v != PlacementType.DETAIL_PAGE),
          } as FilterConditionDTO;
        });

        return new PlacementTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions,
        });
      }

      case FilterKey.BID_ADJUSTMENT:
        return new BidAdjustmentTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.BID_OPTIMIZATION:
        // custom conditions
        dto.conditions.forEach((cond) => {
          if (cond.values[0] == BooleanType.TRUE) {
            cond.values[0] = AmazonBrandsBidOptimization.AUTO;
          } else if (cond.values[0] == BooleanType.FALSE) {
            cond.values[0] = AmazonBrandsBidOptimization.MANUAL;
          }
        });

        return new BidOptimizationFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.IMPRESSIONS:
        return new ImpressionsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CLICKS:
        return new ClicksFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ORDERS:
        return new OrdersFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.UNITS:
        return new UnitsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CTR:
        return new CtrFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: conditionValuesToPercentage(dto.conditions),
        });

      case FilterKey.CVR:
        return new CvrFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: conditionValuesToPercentage(dto.conditions),
        });

      case FilterKey.CPC:
        return new CpcFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SPEND:
        return new SpendFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SALES:
        return new SalesFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ACOS:
        return new AcosFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: conditionValuesToPercentage(dto.conditions),
        });

      case FilterKey.ROAS:
        return new RoasFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.RPC:
        return new RpcFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CPA:
        return new CpaFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.AOV:
        return new AovFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CPM:
        return new CpmFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.DELTA:
        return new DeltaFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.REASON:
        return new OptimizationReasonFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ENTITY_TYPE:
        return new EntityTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.MATCH_TYPE:
        return new MatchTypeFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGETING:
        return new TargetingFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGETING_NOT:
        return new TargetingNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGETING_EXACT_MATCH:
        return new TargetingExactMatchFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.NEGATIVE_TARGETING:
        return new NegativeTargetingFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.NEGATIVE_TARGETING_NOT:
        return new NegativeTargetingNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.NEGATIVE_TARGETING_EXACT_MATCH:
        return new NegativeTargetingExactMatchFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CAMPAIGN_DATA_GROUP_ITEM:
        return new CampaignDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGET_DATA_GROUP_ITEM:
        return new TargetDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_DATA_GROUP_ITEM:
        return new SearchTermDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.REPORTED_AT:
        return new ReportedAtFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.NEGATIVE_KEYWORD_MATCH_TYPE:
        return new NegativeKeywordMatchTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.NEGATIVE_TARGETING_LEVEL:
        return new NegativeTargetLevelFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_TITLE:
        return new ProductTitleFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_TITLE_NOT:
        return new ProductTitleNotFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_ASIN:
        return new ProductAsinFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_SKU:
        return new ProductSkuFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_CATEGORY:
        return new ProductCategoryFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_BRAND:
        return new ProductBrandFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_DATA_GROUP_ITEM:
        return new ProductDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ACOTS:
        return new AcotsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ASP:
        return new AspFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ORGANIC_SALES:
        return new OrganicSalesFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TOTAL_VIEWS:
        return new DetailPageViewsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TOTAL_UNITS:
        return new TotalUnitsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.USP:
        return new UspFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TCVR:
        return new TotalCvrFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TOTAL_CLICKS:
        return new TotalClicksFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TOTAL_ORDERS:
        return new TotalOrdersFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TOTAL_SALES:
        return new TotalSalesFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      default:
        return new EmptyFilterModel();
    }
  }
}

// Singleton pattern
export const filterFactory = new FilterFactoryImp();

function conditionValuesToPercentage(conditions: FilterConditionDTO[]) {
  return conditions.map((condition) => {
    return {
      ...condition,
      values: condition.values.map((value) => ratioToPercentage(value)),
    };
  });
}

function ratioToPercentage(value: number | string): string {
  if (isNumber(value)) {
    return (value * 100).toString();
  }
  return (parseFloat(value) * 100).toString();
}
