import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { LineGraphWidgetConfiguration } from './LineGraphWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';

export const LINE_GRAPH_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<LineGraphWidgetConfiguration> = {
  title: 'Line Graph',
  description: 'Shows a Line Graph of up to 4 Metrics.',
  icon: <ShowChartRoundedIcon />,
  iconColor: 'green',
  id: v4(),
  type: DashboardWidgetType.LineGraphWidget,
  configuration: new LineGraphWidgetConfiguration(),
  layout: {
    h: 4,
    w: 6,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 16,
    maxW: 16,
    minH: 4,
    minW: 4,
  },
};
