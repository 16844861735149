import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function profilesStatsTableDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.TEAM_NAME, hide: false, pinned: false },
    { colId: ColumnId.PROFILE_NAME, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
    { colId: ColumnId.ACOTS, hide: true, pinned: false },
    { colId: ColumnId.ASP, hide: true, pinned: false },
    { colId: ColumnId.ORGANIC_SALES, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_VIEWS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_UNITS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_SALES, hide: true, pinned: false },
    { colId: ColumnId.USP, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CVR, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CLICKS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_ORDERS, hide: true, pinned: false },
  ];
}
