import { FunctionComponent, PropsWithChildren, useContext, useCallback } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import LoadingBlock from '@/components/feedback/LoadingBlock';

interface DashboardWidgetContentProps extends PropsWithChildren {
  onMouseChange: (isMouseOnWidget: boolean) => void;
}

const DashboardWidgetContent: FunctionComponent<DashboardWidgetContentProps> = ({ children, onMouseChange }) => {
  const { isLoading } = useContext(DashboardWidgetContext);

  const handleMouseEnter = useCallback(() => {
    onMouseChange(true);
  }, [onMouseChange]);

  const handleMouseLeave = useCallback(() => {
    onMouseChange(false);
  }, [onMouseChange]);

  if (isLoading) {
    return <LoadingBlock />;
  }

  return (
    <div
      className="relative flex h-full w-full flex-col"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onLostPointerCapture={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default DashboardWidgetContent;
