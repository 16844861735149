import { FunctionComponent, memo, useMemo, useRef, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { LineGraphWidgetConfiguration } from './LineGraphWidgetConfiguration';

import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import LineGraphWidgetConfigurationForm from './LineGraphWidgetConfigurationForm';

import { ReactECharts } from '@/components/chart/ReactEcharts';
import { METRIC_COLORS, getConfigForMetric } from '@/components/metrics/MetricsConfig';
import { CommonMetricField, MetricField } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import { LineGraphWidgetDataDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import dayjs from 'dayjs';
import {
  BarSeriesOption,
  EChartsOption,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { isArray } from 'lodash-es';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByTime } from '../../forms/GroupByTimeSelect';

interface LineGraphWidgetProps extends IDashboardWidget<LineGraphWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
}

const LineGraphWidget: FunctionComponent<LineGraphWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget }) => {
  const hoveredSeries = useRef<string | undefined>();
  // const hoveredPoint = useRef<number | undefined>();

  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);

  const { getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();

  function getEchartsOptions(dashboardData: LineGraphWidgetDataDTO, widgetConfiguration: LineGraphWidgetConfiguration): EChartsOption {
    const series: (LineSeriesOption | BarSeriesOption)[] = [];
    const yAxis: YAXisComponentOption[] = [];

    const xAxisHasDates = configuration.groupByTime === GroupByTime.DAY;

    const xAxisData = dashboardData.xAxis;

    // For the y-axis create a map of the selected metrics and their data
    const yAxisData = widgetConfiguration.selectedMetrics.map<{ key: MetricField; values: number[] }>((metric) => {
      return {
        key: metric,
        values: dashboardData.yAxis[metric],
      };
    });

    yAxisData.forEach((dataItem, index: number) => {
      const metricConfig = getConfigForMetric(dataItem.key);
      if (!metricConfig) {
        return;
      }

      const color = METRIC_COLORS[index];

      if (metricConfig.chartSeriesType === 'bar') {
        const barSeries: BarSeriesOption = {
          id: dataItem.key,
          name: metricConfig.title,
          type: 'bar',
          yAxisIndex: index,
          data: dataItem.values,
          color: color,
          emphasis: {
            focus: 'none',
          },
        };
        series.push(barSeries);
      } else if (metricConfig.chartSeriesType === 'line') {
        const lineSeries: LineSeriesOption = {
          id: dataItem.key,
          name: metricConfig.title,
          type: 'line',
          yAxisIndex: index,
          data: dataItem.values,
          color: color,
          symbol: 'emptyCircle',
          symbolSize: 6,
          emphasis: {
            focus: 'none',
          },
          showSymbol: true,
        };
        series.push(lineSeries);
      }

      yAxis.push({
        name: metricConfig?.title,
        type: 'value',
        position: index === 0 ? 'left' : 'right',
        offset: index === 0 ? 0 : yAxisData.length === 2 ? 0 : 60 * (index - 1), // Conditional offset based on number of Y-axes
        nameTextStyle: {
          fontWeight: 'bold', // Make axis name bold
          fontFamily: 'Roboto',
        },
        axisLabel: {
          fontWeight: 'bold', // Make axis labels bold
          fontFamily: 'Roboto',
          formatter: (value: number) =>
            getShortFormatterForMetricField(dataItem.key)(value, {
              customCurrencyCode: dashboardCurrency,
            }),
        },
        axisPointer: {
          // Configure hover formatting for the Y-axis
          label: {
            show: true,
            formatter: (params) =>
              getShortFormatterForMetricField(dataItem.key)(params.value as number, {
                customCurrencyCode: dashboardCurrency,
              }),
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: color,
          },
        },
      });
    });

    const options: EChartsOption = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
        },
        axisPointer: {
          label: {
            show: xAxisHasDates,
          },
        },
        axisLabel: {
          formatter: function (value: string) {
            if (xAxisHasDates) {
              const date = dayjs(value);
              return date.format('MMM DD');
            }
            return value;
          },
          fontWeight: 'bolder',
          fontFamily: 'Roboto',
        },
        splitLine: {
          show: false, // Hide vertical lines
        },
      },
      yAxis: yAxis,
      series: series,
      grid: {
        left: 65,
        right: 55 * yAxisData.length,
        top: 45,
        bottom: 45,
      },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          if (isArray(params)) {
            let result = `<b>${params[0].name}</b>` + '<br/>'; // Add the x-axis series name (date)
            params.forEach((param) => {
              const formattedValue = getLongFormatterForMetricField(param.seriesId as CommonMetricField)(param.value as number, {
                customCurrencyCode: dashboardCurrency,
              });
              if (param.seriesName === hoveredSeries.current) {
                result += `<b>${param.marker}${param.seriesName}: ${formattedValue}</b><br/>`;
              } else {
                result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
              }
            });
            return result;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };

    return options;
  }

  const echartsOptions = useMemo(() => {
    if (data) {
      return getEchartsOptions(data as LineGraphWidgetDataDTO, configuration);
    }
    return {};
  }, [data, configuration.groupByTime, configuration.selectedMetrics]);

  const hasFilters =
    (configuration.campaignFilters.length > 0 &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (configuration.targetsFilters.length > 0 && configuration.entityType === EntityType.KEYWORD);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar>
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>

        <div className="h-full w-full flex-1 flex  ">
          {data && (
            <ReactECharts
              settings={{
                notMerge: true,
              }}
              option={echartsOptions}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <LineGraphWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(LineGraphWidget, isBaseWidgetConfigurationEqual);
