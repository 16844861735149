import { SortByUnit } from '@/components/buttons/SortByToggleButton';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultAdvertisedProductsFilters, getUpdatedFiltersValue } from '@/components/filter-builder/models/AlFilterModel';
import { ComparisonUnit } from '@/components/grid/types';
import {
  DEFAULT_SELECTED_METRICS,
  DEFAULT_SELECTED_METRIC_COLORS,
  DEFAULT_VISIBLE_METRICS,
  MetricColor,
  getUpdatedSelectionColors,
} from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/types/MetricField';
import { useUserSetting } from '@/hooks/useUserSetting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { FiltersPropsWithChildren, MetricsGraphTablePageContext, defaultMetricsGraphTablePageContext } from '@/types/context-shared';
import { cloneDeep, isEqual } from 'lodash-es';
import { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

export const ADVERTISED_PRODUCT_CONTEXT_KEY = 'advertised-products-context';

export const AdvertisedProductsContext = createContext<MetricsGraphTablePageContext>({
  ...defaultMetricsGraphTablePageContext,
});

export const AdvertisedProductsProvider: FunctionComponent<FiltersPropsWithChildren> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();
  const { handleSettingStateChange: updateSelectedMetricsSetting, settingState: selectedMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.SELECTED_METRICS,
  );

  const { handleSettingStateChange: updateSelectedMetricColorsSetting, settingState: selectedMetricColorsSetting } = useUserSetting<
    MetricColor[]
  >(UserSettingKey.SELECTED_METRIC_COLORS);

  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.VISIBLE_METRICS,
  );

  const [selectedMetrics, setSelectedMetrics] = useState<MetricField[]>(selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS);
  const [visibleMetrics, setVisibleMetrics] = useState<MetricField[]>(visibleMetricsSetting ?? DEFAULT_VISIBLE_METRICS);
  const [selectedMetricColors, setSelectedMetricColors] = useState<MetricColor[]>(
    getUpdatedSelectionColors(
      selectedMetricColorsSetting ?? DEFAULT_SELECTED_METRIC_COLORS,
      selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS,
    ),
  );

  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('percent');
  const [sortByUnit, setSortByUnit] = useState<SortByUnit>(SortByUnit.Count);
  const [isMounted, setIsMounted] = useState(false);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMetricColorsSetting(selectedMetricColors);
  }, [selectedMetricColors]);

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMetricsSetting(selectedMetrics);
  }, [selectedMetrics]);

  useEffect(() => {
    if (!isMounted) return;
    updateVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);

  //TODO: review
  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(ADVERTISED_PRODUCT_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        ADVERTISED_PRODUCT_CONTEXT_KEY,
        activeProfile?.id,
        getDefaultAdvertisedProductsFilters(),
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <AdvertisedProductsContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        visibleMetrics,
        setVisibleMetrics,
        selectedMetrics,
        setSelectedMetrics,
        selectedMetricColors,
        setSelectedMetricColors,
        comparisonUnit,
        setComparisonUnit,
        sortByUnit,
        setSortByUnit,
      }}
    >
      {children}
    </AdvertisedProductsContext.Provider>
  );
};

export const useAdvertisedProductsContext = (): MetricsGraphTablePageContext => {
  const context = useContext(AdvertisedProductsContext);
  if (!context) {
    throw new Error('useAdvertisedProductsContext must be used within a AdvertisedProductsProvider');
  }
  return context;
};
