import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export class MetricWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'ACOS';
  metricField = CommonMetricField.ACOS;
  filters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
