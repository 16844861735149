import { useState, useCallback } from 'react';
import { Routes } from '@/router/router-paths';
import { PaywallModal } from '../components/PaywallModal';
import { useNavigate } from 'react-router-dom';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { VideoUrl } from '@/config/urls';

// Custom hook definition
export function usePaywall({ message, videoUrl = VideoUrl.PAYWALL_DEFAULT }: { message?: string; videoUrl?: VideoUrl } = {}): {
  paywallModalElement: React.ReactNode;
  showPaywallWithDelay: (delayInSeconds: number) => void;
} {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { activeTeam } = useActiveTeamContext();
  const { isAdminModeActive } = useUserContext();

  const isPastDueAndGracePeriodHasPassed = activeTeam?.isPastDueAndGracePeriodHasPassed;

  // Function to open the modal
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  // Function to close the modal
  const closeModal = useCallback(() => {
    navigate(Routes.OPTIMIZER);
    setIsOpen(false);
  }, []);

  // Function to start the timer
  const showPaywallWithDelay = useCallback(
    (delayInSeconds: number) => {
      // Start
      if (isAdminModeActive) {
        return;
      }

      if (isPastDueAndGracePeriodHasPassed) {
        navigate(Routes.PROFILES);
        return;
      }
      const timer = setTimeout(() => {
        openModal();
      }, delayInSeconds * 1000);

      return () => clearTimeout(timer); // Cleanup function to clear the timer
    },
    [openModal, isPastDueAndGracePeriodHasPassed],
  );

  // Render the PaywallModal component with necessary props
  const paywallModalElement = (
    <PaywallModal isOpen={isOpen} onClose={closeModal} returnURLPath={Routes.PROFILES} videoUrl={videoUrl}>
      {message}
    </PaywallModal>
  );

  return { paywallModalElement, showPaywallWithDelay };
}
