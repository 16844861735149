import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import MetricSelect from '@/components/form/MetricSelect';
import { METRICS } from '@/components/metrics/MetricsConfig';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useDashboardQueries } from '@/modules/dashboards/hooks/useDashboardQueries';
import { TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';

interface MetricWidgetConfigurationFormProps {
  configuration: MetricWidgetConfiguration;
  id: string;
}

const MetricWidgetConfigurationForm: FunctionComponent<MetricWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { refetchForWidgetWithId } = useDashboardQueries();

  const onMetricChange = async (metricField: '' | CommonMetricField) => {
    if (metricField !== '') {
      const configuredMetric = METRICS[metricField];
      await updateWidgetConfiguration<MetricWidgetConfiguration>(
        id,
        {
          title: configuredMetric?.title || configuration.title,
          metricField: metricField,
        },
        true,
      );

      refetchForWidgetWithId(id);
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateWidgetConfiguration<MetricWidgetConfiguration>(
      id,
      {
        title: e.currentTarget.value,
      },
      true,
    );
  };

  const onFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<MetricWidgetConfiguration>(
      id,
      {
        filters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  return (
    <>
      <TextField label="Name" value={configuration.title} onChange={onTitleChange} />

      <MetricSelect value={configuration.metricField} onChange={onMetricChange} helperText={'Select a Metric to display.'} />

      <div className="mt-4">
        <Typography variant="body1">Filters</Typography>
      </div>

      <CampaignFilterListEditor
        teamProfiles={dashboardProfiles ?? []}
        filters={
          configuration.filters
            ? configuration.filters.map((filter) =>
                filterFactory.createFilterModelFromDTO(filter, {
                  teamProfiles: dashboardProfiles,
                }),
              )
            : []
        }
        onFiltersChange={onFiltersChanged}
      />
    </>
  );
};

export default MetricWidgetConfigurationForm;
