import { getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import { MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { BidStrategyType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { getColorForText } from '@/types/colors.enum';
import { ICellRendererParams, IHeaderParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { AlGridContext } from '../AlGrid';
import ChangePercentageCellRenderer from '../cells/ChangePercentageCellRenderer';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '../cells/ChipArrayCellRenderer';
import SparklineCellRenderer, { IAlSparklineCellRendererParams } from '../cells/SparklineCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import ChangePercentageHeaderRenderer, { IChangePercentageHeaderRendererProps } from '../headers/ChangePercentageHeaderRenderer';
import { StringToCount } from '../helpers';
import useColDefsFunctions from './useColDefsFunctions';

const useColumnTypes = () => {
  const { getLongFormatterForMetricField, formatPercent, formatCurrency, formatDateStringTimeNoSeconds } = useFormatting();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();
  const { t } = useTranslation();

  const getChangePercentageHeaderRendererParams = (
    params: IHeaderParams,
    formattingParams?: FormattingParams,
  ): IChangePercentageHeaderRendererProps => {
    const colId = params.column.getColId() as ColumnId;
    const metricField = getMetricConfigByColId(colId)?.key;

    let current: number | undefined;
    if (metricField !== undefined) {
      current = params.context?.metricColumnAggregates?.[metricField]?.current;
    }

    const formattedAggValue =
      metricField !== undefined && current !== undefined
        ? getLongFormatterForMetricField(metricField)(current, formattingParams)
        : (current?.toString() ?? '0');

    return {
      ...params,
      currentValue: formattedAggValue,
    };
  };

  const metricFieldWithChangePercentage = {
    autoHeight: true,
    width: 125,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: (params: IHeaderParams<unknown, AlGridContext>) =>
      getChangePercentageHeaderRendererParams(params, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
    comparator: metricsDataComparator,
    // TODO: check if function works
    aggFunc: 'metricsDataAggFunc',
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, unknown, AlGridContext>) =>
      getMetricFieldChangePercentageCellRendererParams(params, {
        customCurrencyCode: params.context?.activeProfileCurrencyCode,
      }),
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  const optGroupNameColumnType = {
    headerName: 'Opt Group',
    width: 125,
    enableRowGroup: true,
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      const groupId = params.data?.groupId;
      const contextCampaignGroupIdToCampaignGroupMap = params.context?.campaignGroupIdToCampaignGroupMap;
      const group = groupId && contextCampaignGroupIdToCampaignGroupMap ? contextCampaignGroupIdToCampaignGroupMap[groupId] : null;

      const tooltip = group ? (
        <>
          Target ACOS: {!isNil(group?.tacos) ? formatPercent(group.tacos) : 'Not Set'}
          <br />
          Prioritization: {t(`optimizer_page.optimization_presets.${group.preset}`)}
          <br />
          Campaigns In Group: {group.totalCampaigns}
        </>
      ) : null;

      return {
        textLabel: params.value,
        tooltip: tooltip,
        noneLabel: '(Unassigned)',
      };
    },
  };

  const dataGroupColumnType = {
    width: 100,
    hide: true,
    enableRowGroup: true,
    cellRendererSelector: (params: ICellRendererParams) => {
      if (params.value) {
        return { component: ChipArrayCellRenderer };
      }
      return { component: undefined }; // undefined means default renderer
    },
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      return {
        chipArrayChips: [{ color: getColorForText(params.value), value: params.value }],
      };
    },
  };

  const checkboxColumnType = {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    resizable: false,
    width: 50,
    minWidth: 30,
    maxWidth: 50,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    lockVisible: true,
    headerClass: 'checkbox-column-header',
    cellClass: 'checkbox-column-cell',
  };

  const matchLongColumnType = {
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: ChipArrayCellRenderer,
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;
      if (params.value == '' && !isAutoGroupColumn) return { chipArrayChips: [] };

      if (params.node.group && !isAutoGroupColumn) {
        // Expecting stringToCountAggFunc output
        const matchToCountRecord = params.value as StringToCount;

        const chipArrayChips: ChipArrayChip[] = [];
        for (const [match, count] of Object.entries(matchToCountRecord).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) {
          chipArrayChips.push({
            value: `${t(`optimizer_page.match_type_long.${match}`)}: ${count}`,
            color: getColorForText(match ?? 'matchType'),
          });
        }

        return { chipArrayChips };
      }
      return {
        chipArrayChips: [
          {
            value: t(`optimizer_page.match_type_long.${params.value}`),
            color: getColorForText(params.value ?? 'matchType'),
          },
        ],
      };
    },
  };

  const bidStrategyColumnType = {
    width: 135,
    hide: true,
    enableRowGroup: true,
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      return {
        textColor: params.value == BidStrategyType.AUTO_FOR_SALES ? 'orange' : undefined,
        tooltip:
          params.value == BidStrategyType.AUTO_FOR_SALES
            ? 'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results.'
            : undefined,

        valueToString: (key: string) => t(`optimizer_page.bidStrategy.${key}`),
        valueToColor: (key: string) =>
          BidStrategyType[key as keyof typeof BidStrategyType] == BidStrategyType.AUTO_FOR_SALES ? 'orange' : undefined,
        valueToTooltip: (key: string) => {
          const type = BidStrategyType[key as keyof typeof BidStrategyType];
          switch (type) {
            case BidStrategyType.AUTO_FOR_SALES:
              return 'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results';
            default:
              return undefined;
          }
        },
      };
    },
    valueFormatter: (params: ValueFormatterParams) => (params.value ? t(`optimizer_page.bidStrategy.${params.value}`) : ''),
    comparator: (valueA: BidStrategyType, valueB: BidStrategyType) => {
      // Use translated values for comparison, not the enum values
      const translatedA = t(`optimizer_page.bidStrategy.${valueA}`);
      const translatedB = t(`optimizer_page.bidStrategy.${valueB}`);
      return translatedA.localeCompare(translatedB);
    },
  };

  const salesPreviousDaysType = {
    headerName: 'Last 30d Sales',
    aggFunc: 'metricDataWithPreviousDaysAggFunc',
    cellRenderer: SparklineCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, unknown, AlGridContext>): IAlSparklineCellRendererParams => {
      return {
        valueFormatter: (value: number) => formatCurrency(value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      };
    },
    valueFormatter: (params: ValueFormatterParams) => `${params.value}`,
    comparator: (valueA: MetricDataWithPreviousDays, valueB: MetricDataWithPreviousDays) => {
      const sumA = valueA ? valueA.slice(2).reduce((sum, current) => sum + current, 0) : 0;
      const sumB = valueB ? valueB.slice(2).reduce((sum, current) => sum + current, 0) : 0;
      return sumA - sumB;
    },
  };

  const spendPreviousDaysType = {
    headerName: 'Last 30d Spend',
    aggFunc: 'metricDataWithPreviousDaysAggFunc',
    cellRenderer: SparklineCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, unknown, AlGridContext>): IAlSparklineCellRendererParams => {
      return {
        color: 'amber',
        valueFormatter: (value: number) => formatCurrency(value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      };
    },
    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
    comparator: (valueA: MetricDataWithPreviousDays, valueB: MetricDataWithPreviousDays) => {
      const sumA = valueA ? valueA.slice(2).reduce((sum, current) => sum + current, 0) : 0;
      const sumB = valueB ? valueB.slice(2).reduce((sum, current) => sum + current, 0) : 0;
      return sumA - sumB;
    },
  };

  const lastOptimizedType = {
    headerName: 'Last Optimized',
    width: 130,
    aggFunc: 'lastOptimizedAggFunc',
    enableRowGroup: true,
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      return {
        noneLabel: 'Never',
        valueToString: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
        valueToTooltip: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
      };
    },
  };
  return {
    metricFieldWithChangePercentage,
    optGroupNameColumnType,
    checkboxColumnType,
    dataGroupColumnType,
    matchLongColumnType,
    bidStrategyColumnType,
    getChangePercentageHeaderRendererParams,
    salesPreviousDaysType,
    spendPreviousDaysType,
    lastOptimizedType,
  };
};

export default useColumnTypes;
