import { Environment } from '@/config/Environment';
import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { DataGroupModel } from '@/modules/data-groups/models/DataGroup';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { NegativeKeywordMatchType, NegativeTargetingLevel } from '@/modules/negative-targets/api/negative-targets-contracts';
import {
  AmazonBrandsBidOptimization,
  CampaignAdType,
  CostType,
  CreativeType,
  EnabledPausedArchivedState,
  IdNameDTO,
  MultiAdGroupsEnabledType,
  PlacementType,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { CampaignGroupModel, emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { productsService } from '@/modules/products/api/products-service';
import { ProfileWithMetricsModel } from '@/modules/profiles/types/ProfileWithMetricsModel';
import { SearchTermHarvestedType, SearchTermNegatedType } from '@/modules/search-terms/api/search-terms-contracts';
import { KeywordHarvestingPreviewDataRow } from '@/modules/search-terms/components/keyword-harvesting/models/KeywordHarvestingPreviewDataRow';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import dayjs from 'dayjs';
import { isEmpty, isNil, isNumber } from 'lodash-es';
import { MatchType, OptimizationReason } from '../../../modules/optimizer/components/optimization/api/optimization-contracts';
import { BiddingEntity, isPercentEntity } from '../../../modules/optimizer/components/optimization/models/OptimizationModel';
import { PreviewDataRow } from '../../../modules/optimizer/components/optimization/models/PreviewDataRow';
import { DATE_FORMAT } from '../FiltersConfig';
import { FilterConditionDTO, FilterDTO } from '../api/filters-contracts';
import { FilterKey, FilterType } from '../types/FilterKey';
import { AlMultiSelectOptionModel } from './AlMultiSelectOptionModel';

export const FILTERS_VERSION = 6;

export enum UnitType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  NONE = 'NONE',
}

export enum BooleanType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum OperatorType {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  IS_NULL = 'IS_NULL',
}

export const OperatorDisplayMap: Record<OperatorType, string> = {
  [OperatorType.EQUAL]: '=',
  [OperatorType.NOT_EQUAL]: '≠',
  [OperatorType.LESS_THAN]: '<',
  [OperatorType.LESS_THAN_OR_EQUAL]: '≤',
  [OperatorType.GREATER_THAN]: '>',
  [OperatorType.GREATER_THAN_OR_EQUAL]: '≥',
  [OperatorType.LIKE]: 'CONTAINS',
  [OperatorType.NOT_LIKE]: "DOESN'T CONTAIN",
  [OperatorType.IN]: 'IN',
  [OperatorType.NOT_IN]: 'NOT IN',
  [OperatorType.IS_NULL]: 'NEVER',
};

export enum LogicalOperatorType {
  AND = 'AND',
  OR = 'OR',
}

const DEFAULT_CONDITION_OPERATORS: Record<FilterType, OperatorType[]> = {
  [FilterType.BETWEEN]: [OperatorType.GREATER_THAN_OR_EQUAL, OperatorType.LESS_THAN_OR_EQUAL],
  [FilterType.DATE_SELECT]: [OperatorType.GREATER_THAN_OR_EQUAL, OperatorType.LESS_THAN_OR_EQUAL],
  [FilterType.SELECT]: [OperatorType.EQUAL],
  [FilterType.MULTI_SELECT]: [OperatorType.IN],
  [FilterType.STRING_COMPARISON]: [OperatorType.LIKE],
  [FilterType.STRING_EXACT_MATCH]: [OperatorType.IN],
};

export abstract class AlFilterModel {
  protected _key?: FilterKey;
  protected _name?: string;
  protected _longName?: string;
  protected _type?: FilterType;
  teamProfiles?: TeamProfile[];
  unitType?: UnitType;
  isOperatorDisabled?: boolean;
  logicalOperator?: LogicalOperatorType;
  conditions?: FilterCondition[];
  options?: AlMultiSelectOptionModel[];
  isFilterBuilderFilter = true;
  isInverseFilter = false;

  constructor(args?: FilterArguments) {
    if (args) {
      this.logicalOperator = args.logicalOperator;
      this.conditions = args.conditions;
    }
  }

  get key(): FilterKey {
    return this._key ?? ('' as FilterKey);
  }

  get name(): string {
    return this._name ?? '';
  }

  get longName(): string {
    return this._longName && !isEmpty(this._longName) ? this._longName : (this._name ?? '');
  }

  get type(): FilterType {
    return this._type ?? ('' as FilterType);
  }

  get defaultConditionOperators(): OperatorType[] {
    if (this.type === FilterType.STRING_COMPARISON && this.isInverseFilter) {
      return [OperatorType.NOT_LIKE];
    }

    return DEFAULT_CONDITION_OPERATORS[this.type];
  }

  async purifyOptions(): Promise<void> {}
  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([]);
  }

  getOptionsTranslationKey(): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  doesFilterPass(
    _: PreviewDataRow | KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation | ProfileWithMetricsModel,
  ): boolean {
    return true;
  }

  reset(): void {
    this.logicalOperator = undefined;
    this.conditions = undefined;
  }

  doesBetweenFilterPass(currentValue: number): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = Number.parseFloat(condition.values[0] as string);

        switch (operator) {
          case OperatorType.LESS_THAN: {
            if (currentValue >= conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.LESS_THAN_OR_EQUAL: {
            if (currentValue > conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.EQUAL: {
            if (currentValue != conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.GREATER_THAN: {
            if (currentValue <= conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.GREATER_THAN_OR_EQUAL: {
            if (currentValue < conditionValue) {
              return false;
            }
            break;
          }
        }
      }
    }
    return true;
  }

  doesDateBetweenFilterPass(currentValue: string | null): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = dayjs(condition.values[0]);
        const currentValueDate = currentValue ? dayjs(currentValue) : undefined;

        if (operator === OperatorType.IS_NULL) {
          if (currentValueDate) {
            return false;
          }
        } else if (operator === OperatorType.LESS_THAN_OR_EQUAL) {
          if (!currentValueDate || currentValueDate > conditionValue) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN_OR_EQUAL) {
          if (!currentValueDate || currentValueDate < conditionValue) {
            return false;
          }
        } else if (operator === OperatorType.LESS_THAN) {
          if (!currentValueDate || currentValueDate >= conditionValue.startOf('day')) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN) {
          if (!currentValueDate || currentValueDate <= conditionValue.endOf('day')) {
            return false;
          }
        } else if (operator === OperatorType.EQUAL) {
          if (!currentValueDate || currentValueDate <= conditionValue.startOf('day') || currentValueDate >= conditionValue.endOf('day')) {
            return false;
          }
        }
      }
    }
    return true;
  }
  doesExactMatchFilterPass(currentValue: string): boolean {
    const currentValueLowerCase = currentValue.toLowerCase();

    if (this.conditions && this.conditions.length > 0) {
      for (const conditionValue of this.conditions[0].values.map((value) => value.toString().toLowerCase())) {
        if (currentValueLowerCase == conditionValue) {
          return true;
        }
      }
    }

    return false;
  }

  doesStringComparisonFilterPass(currentValue: string): boolean {
    const currentValueLowerCase = currentValue.toLowerCase();
    if (this.conditions && this.conditions.length > 0) {
      switch (this.logicalOperator) {
        case LogicalOperatorType.AND:
          // All conditions must be true
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              }
            }
          }
          break;
        case LogicalOperatorType.OR: {
          // At least one condition must be true
          let isConditionTrue = false;
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map<string>((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              }
            }
          }
          return isConditionTrue;
        }
      }
    }
    return true;
  }

  mapConditionsToDTOs(): FilterConditionDTO[] {
    if (isNil(this.conditions) || this.conditions.length === 0) {
      return [];
    }

    return this.conditions.map((condition) => this.mapConditionToDTO(condition));
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => value.toString()),
      operator: condition.operator,
    };
  }

  percentageValueToRatio(value: number | string): string {
    if (isNumber(value)) {
      return (value / 100).toString();
    }
    return (parseFloat(value) / 100).toString();
  }

  toDTO(): FilterDTO {
    return {
      key: this.key,
      logical_operator: this.logicalOperator?.toString(),
      conditions: this.mapConditionsToDTOs(),
    };
  }

  toQueryKey(): string {
    return this.key + JSON.stringify([this.conditions, this.logicalOperator]);
  }
}

export interface FilterArguments {
  conditions: FilterCondition[];
  logicalOperator?: LogicalOperatorType;
}

export interface FilterCondition {
  values: string[] | number[];
  operator: OperatorType;
}

export class EmptyFilterModel extends AlFilterModel {
  _key = '' as FilterKey;
}
export class AcosFilterModel extends AlFilterModel {
  _key = FilterKey.ACOS;
  _name = 'ACOS';
  _longName = 'ACOS (Ad Cost of Sales)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.PERCENTAGE;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.acos[0] * 100);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class AdGroupFilterModel extends AlFilterModel {
  _key = FilterKey.AD_GROUP_ID;
  _name = 'Ad Group(s)';
  _longName = 'Select Ad Groups';
  _type = FilterType.MULTI_SELECT;

  constructor(adGroups: IdNameDTO[], args?: FilterArguments) {
    super(args);
    this.options = adGroups.map((adGroup) => {
      return new AlMultiSelectOptionModel(adGroup.name, adGroup.id);
    });

    this.purifyOptions();
  }

  async purifyOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id.toString()); // Available Option IDs (string) from API

    const currentConditionValues = this.conditions[0].values.map((v) => v as string);
    const purifiedConditionValues: string[] = [];

    // Loop over saved condition values aka options in local store
    for (let index = 0; index < currentConditionValues.length; index++) {
      const id = currentConditionValues[index];
      if (availableOptionIDs?.includes(id)) {
        purifiedConditionValues.push(id);
      }
    }

    this.conditions[0].values = purifiedConditionValues;
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // If existing filters are loaded via factory we need to load options
    const res = await campaignService.getAdGroups();
    if (res.isSuccess) {
      const options = res.payload.map((adGroup) => {
        return new AlMultiSelectOptionModel(adGroup.name, adGroup.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.adGroup);
  }
}

export class AdGroupNameFilterModel extends AlFilterModel {
  _key = FilterKey.AD_GROUP_NAME;
  _name = 'Ad Group Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.adGroup);
  }
}

export class SourceAdGroupNameFilterModel extends AlFilterModel {
  _key = FilterKey.SOURCE_AD_GROUP_NAME;
  _name = 'Source Ad Group Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceAdGroupName ?? '');
  }
}

export class DestinationAdGroupNameFilterModel extends AlFilterModel {
  _key = FilterKey.DESTINATION_AD_GROUP_NAME;
  _name = 'Destination Ad Group Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationAdGroupName ?? '');
  }
}

export class AdGroupNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.AD_GROUP_NAME_NOT;
  _name = "Ad Group Name (doesn't contain)";
  _longName = "Ad Group Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.adGroup);
  }
}

export class SourceAdGroupNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.SOURCE_AD_GROUP_NAME_NOT;
  _name = "Source Ad Group Name (doesn't contain)";
  _longName = "Source Ad Group Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceAdGroupName ?? '');
  }
}

export class DestinationAdGroupNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.DESTINATION_AD_GROUP_NAME_NOT;
  _name = "Destination Ad Group Name (doesn't contain)";
  _longName = "Destination Ad Group Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationAdGroupName ?? '');
  }
}

export class SourceCampaignAdTypeFilterModel extends AlFilterModel {
  _key = FilterKey.SOURCE_CAMPAIGN_AD_TYPE;
  _name = 'Source Ad Type';
  _longName = 'Source Ad Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
      new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY),
    ];

    return Promise.resolve(options);
  }
  doesFilterPass(row: KeywordHarvestingPreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }
    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(row.sourceCampaignAdType);
  }
}

export class DestinationCampaignAdTypeFilterModel extends AlFilterModel {
  _key = FilterKey.DESTINATION_CAMPAIGN_AD_TYPE;
  _name = 'Destination Ad Type';
  _longName = 'Destination Ad Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
      new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY),
    ];

    return Promise.resolve(options);
  }
  doesFilterPass(row: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    if (!row.destinationCampaignAdType || !Object.values(CampaignAdType).includes(row.destinationCampaignAdType as CampaignAdType)) {
      return false;
    }

    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }
    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(row.destinationCampaignAdType as CampaignAdType);
  }
}

export class AovFilterModel extends AlFilterModel {
  _key = FilterKey.AOV;
  _name = 'AOV';
  _longName = 'AOV (Average Order Value)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.aov[0]);
  }

  // No need to use percentageValueToRatio
}

export class BudgetFilterModel extends AlFilterModel {
  _key = FilterKey.BUDGET;
  _name = 'Budget';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;
}

export class CampaignFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_ID;
  _name = 'Campaign(s)';
  _longName = 'Select Campaigns';
  _type = FilterType.MULTI_SELECT;
  _teamProfiles: TeamProfile[] = [];

  constructor({ campaigns, args, teamProfiles }: { campaigns?: IdNameDTO[]; args?: FilterArguments; teamProfiles?: TeamProfile[] } = {}) {
    super(args);
    this.options = campaigns?.map((campaign) => {
      return new AlMultiSelectOptionModel(campaign.name, campaign.id);
    });

    this._teamProfiles = teamProfiles ?? [];

    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // go over team profiles and get campaigns
    if (this._teamProfiles.length > 0) {
      const options: AlMultiSelectOptionModel[] = [];
      for (const teamProfile of this._teamProfiles) {
        const res = await campaignService.getNonArchivedCampaignIdsByTeamAndProfile(teamProfile.teamId, teamProfile.profileId);
        if (res.isSuccess) {
          options.push(
            ...res.payload.map((campaign) => {
              return new AlMultiSelectOptionModel(campaign.name, campaign.id);
            }),
          );
        }
      }

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    const res = await campaignService.getNonArchivedCampaignIds();
    if (res.isSuccess) {
      const options = res.payload.map((campaign) => {
        return new AlMultiSelectOptionModel(campaign.name, campaign.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.campaignId);
  }
}

export class CampaignNameFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_NAME;
  _name = 'Campaign Name (contains)';
  _longName = 'Campaign Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class SourceCampaignNameFilterModel extends AlFilterModel {
  _key = FilterKey.SOURCE_CAMPAIGN_NAME;
  _name = 'Source Campaign Name (contains)';
  _longName = 'Source Campaign Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceCampaignName ?? '');
  }
}

export class DestinationCampaignNameFilterModel extends AlFilterModel {
  _key = FilterKey.DESTINATION_CAMPAIGN_NAME;
  _name = 'Destination Campaign Name (contains)';
  _longName = 'Destination Campaign Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationCampaignName ?? '');
  }
}

export class CampaignNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_NAME_NOT;
  _name = "Campaign Name (doesn't contain)";
  _longName = "Campaign Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class SourceCampaignNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.SOURCE_CAMPAIGN_NAME_NOT;
  _name = "Source Campaign Name (doesn't contain)";
  _longName = "Source Campaign Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceCampaignName ?? '');
  }
}

export class DestinationCampaignNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.DESTINATION_CAMPAIGN_NAME_NOT;
  _name = "Destination Campaign Name (doesn't contain)";
  _longName = "Destination Campaign Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationCampaignName ?? '');
  }
}

export class CampaignGroupNameFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_GROUP_NAME;
  _name = 'Group Name (contains)';
  _longName = 'Group Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignGroup);
  }
}

export class CampaignGroupNameNotFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_GROUP_NAME_NOT;
  _name = "Group Name (doesn't contain)";
  _longName = "Group Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignGroup);
  }
}

export class CampaignGroupFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_GROUP;
  _name = 'Opt. Group(s)';
  _longName = 'Optimization Groups';
  _type = FilterType.MULTI_SELECT;

  constructor(campaignGroups: CampaignGroupModel[], args?: FilterArguments) {
    super(args);
    this.options = campaignGroups
      .map((campaignGroup) => {
        return new AlMultiSelectOptionModel(campaignGroup.name, campaignGroup.id);
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.purifyOptions();
  }

  async purifyOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id); // Available Options IDs (numbers) from API

    const currentConditionValues = this.conditions[0].values.map((v) => parseInt(v as string));
    const purifiedConditionValues: number[] = [];

    // Loop over saved condition values aka options in local store
    for (let index = 0; index < currentConditionValues.length; index++) {
      const id = currentConditionValues[index];
      if (availableOptionIDs?.includes(id)) {
        purifiedConditionValues.push(id);
      }
    }

    this.conditions[0].values = purifiedConditionValues;
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }
    const res = await campaignService.getGroups();
    if (res.isSuccess) {
      res.payload.unshift(emptyUnAssignedCampaignGroup);
      const options = res.payload
        .map((campaign) => {
          return new AlMultiSelectOptionModel(campaign.name, campaign.id);
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      this.options = options;
      return options;
    }

    return [];
  }

  getGroupNames(): Array<string> {
    // Can't be set in constructor because conditions change
    if (!this.options || !this.conditions || this.conditions.length === 0) {
      return [];
    }

    const selectedOptionIds = new Set(this.conditions[0].values.map(String));
    const groupNames = this.options.filter((option) => selectedOptionIds.has(String(option.id))).map((option) => option.name);

    return groupNames;
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    const groupNames = this.getGroupNames();
    // Only have names to compare with
    if (groupNames.includes(emptyUnAssignedCampaignGroup.name) && value.campaignGroup == '') {
      return true;
    }

    return groupNames.includes(value.campaignGroup);
  }
}

export class CampaignGroupAdTypeFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_GROUP_AD_TYPE;
  _name = 'Ad Type';
  _longName = 'Ad Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
      new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY),
    ];

    return Promise.resolve(options);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(value.campaignAdType);
  }
}

export class CampaignStateFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_STATE;
  _name = 'Campaign State';
  _longName = 'Campaign State';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Paused', EnabledPausedArchivedState.PAUSED),
      new AlMultiSelectOptionModel('Enabled', EnabledPausedArchivedState.ENABLED),
      new AlMultiSelectOptionModel('Archived', EnabledPausedArchivedState.ARCHIVED),
    ]);
  }
}

export class CampaignAdTypeFilterModel extends AlFilterModel {
  _key = FilterKey.CAMPAIGN_AD_TYPE;
  _name = 'Ad Type';
  _longName = 'Campaign Ad Type';
  _type = FilterType.MULTI_SELECT;
  excludeDisplay: boolean | undefined;

  constructor(excludeDisplay?: boolean, args?: FilterArguments) {
    super(args);
    this.excludeDisplay = excludeDisplay;
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
    ];

    if (!this.excludeDisplay) {
      options.push(new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY));
    }

    return Promise.resolve(options);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(value.campaignAdType);
  }
}

export class CreativeTypeFilterModel extends AlFilterModel {
  _key = FilterKey.CREATIVE_TYPE;
  _name = 'Creative Type';
  _longName = 'Creative Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Product Collection', CreativeType.PRODUCT_COLLECTION),
      new AlMultiSelectOptionModel('Store Spotlight', CreativeType.STORE_SPOTLIGHT),
      new AlMultiSelectOptionModel('Video', CreativeType.VIDEO),
      new AlMultiSelectOptionModel('Brand Video', CreativeType.BRAND_VIDEO),
    ]);
  }
}

export class CostTypeFilterModel extends AlFilterModel {
  _key = FilterKey.COST_TYPE;
  _name = 'Cost Type';
  _longName = 'Cost Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([new AlMultiSelectOptionModel('CPC', CostType.CPC), new AlMultiSelectOptionModel('VCPM', CostType.VCPM)]);
  }
}

export class PlacementTypeFilterModel extends AlFilterModel {
  _key = FilterKey.PLACEMENT_TYPE;
  _name = 'Placement Type';
  _longName = 'Placement Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Top of Search', PlacementType.PLACEMENT_TOP),
      new AlMultiSelectOptionModel('Rest of Search', PlacementType.PLACEMENT_REST_OF_SEARCH), // Includes OTHER
      new AlMultiSelectOptionModel('Product Pages', PlacementType.PLACEMENT_PRODUCT_PAGE), // Includes DETAIL_PAGE
    ]);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    const conditionValues: string[] = [];
    const placementTypes = new Set();
    condition.values.forEach((value) => {
      conditionValues.push(value.toString());
      placementTypes.add(value);

      if (value == PlacementType.PLACEMENT_REST_OF_SEARCH && !placementTypes.has(PlacementType.OTHER)) {
        conditionValues.push(PlacementType.OTHER);
      }

      if (value == PlacementType.PLACEMENT_PRODUCT_PAGE && !placementTypes.has(PlacementType.DETAIL_PAGE)) {
        conditionValues.push(PlacementType.DETAIL_PAGE);
      }
    });

    return {
      values: conditionValues,
      operator: condition.operator,
    };
  }
}

export class BidAdjustmentTypeFilterModel extends AlFilterModel {
  _key = FilterKey.BID_ADJUSTMENT;
  _name = 'Bid Adjustment';
  _longName = 'Bid Adjustment';
  _type = FilterType.BETWEEN;
  unitType = UnitType.PERCENTAGE;
}

export class BidOptimizationFilterModel extends AlFilterModel {
  _key = FilterKey.BID_OPTIMIZATION;
  _name = 'Bid Optimization';
  _longName = 'Bid Optimization';
  _type = FilterType.SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return await Promise.resolve([
      new AlMultiSelectOptionModel('Auto', AmazonBrandsBidOptimization.AUTO),
      new AlMultiSelectOptionModel('Manual', AmazonBrandsBidOptimization.MANUAL),
    ]);
  }

  getOptionsTranslationKey(): string {
    return 'optimizer_page.bid_optimization';
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values[0] === AmazonBrandsBidOptimization.AUTO ? [BooleanType.TRUE] : [BooleanType.FALSE],
      operator: condition.operator,
    };
  }
}

export class ClicksFilterModel extends AlFilterModel {
  _key = FilterKey.CLICKS;
  _name = 'Clicks';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.clicks[0]);
  }
}

export class ComparisonDateFilterModel extends AlFilterModel {
  _key = FilterKey.COMPARE_DATE;
  _name = 'Comparison Date';
  _type = FilterType.DATE_SELECT;
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;

  // As profile stats are downloaded per whole day in profile's timezone (E.g., America/Los_Angeles) not in UTC
  // then comparison date range must be always in profile's timezone (no need to send timezone info to API)
  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => dayjs(value).format(DATE_FORMAT)),
      operator: condition.operator,
    };
  }
}

export class CpaFilterModel extends AlFilterModel {
  _key = FilterKey.CPA;
  _name = 'CPA';
  _longName = 'CPA (Cost Per Acquisition)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpc[0]);
  }

  // No need to use percentageValueToRatio
}

export class CpcFilterModel extends AlFilterModel {
  _key = FilterKey.CPC;
  _name = 'CPC';
  _longName = 'CPC (Cost Per Click)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpc[0]);
  }

  // No need to use percentageValueToRatio()
}
export class CpmFilterModel extends AlFilterModel {
  _key = FilterKey.CPM;
  _name = 'CPM';
  _longName = 'CPM (Cost Per Thousand Impressions)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpm[0]);
  }

  // No need to use percentageValueToRatio()
}

export class CtrFilterModel extends AlFilterModel {
  _key = FilterKey.CTR;
  _name = 'CTR';
  _longName = 'CTR (Click-Through Rate)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.PERCENTAGE;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.ctr[0] * 100);
  }
}

export class CvrFilterModel extends AlFilterModel {
  _key = FilterKey.CVR;
  _name = 'CVR';
  _longName = 'CVR (Conversion Rate)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.PERCENTAGE;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cvr[0] * 100);
  }
}

export class DateFilterModel extends AlFilterModel {
  _key = FilterKey.DATE;
  _name = 'Date';
  _type = FilterType.DATE_SELECT;
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;

  // As profile stats are downloaded per whole day in the profile's timezone (E.g., America/Los_Angeles) not the in UTC
  // then date range must be always in profile's timezone (no need to send timezone info to API)
  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => dayjs(value).format(DATE_FORMAT)),
      operator: condition.operator,
    };
  }
}

export class LastOptimizedDateModel extends AlFilterModel {
  _key = FilterKey.LAST_OPTIMIZED_DATE;
  _name = 'Last Optimized';
  _type = FilterType.DATE_SELECT;
  isOperatorDisabled = false;
  isFilterBuilderFilter = true;

  mapConditionsToDTOs(): FilterConditionDTO[] {
    if (isNil(this.conditions) || this.conditions.length === 0) {
      return [];
    }

    if (this.conditions[0].operator === OperatorType.EQUAL) {
      return [
        {
          values: [dayjs(this.conditions[0].values[0]).startOf('day').toString()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [dayjs(this.conditions[0].values[0]).endOf('day').toString()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ];
    } else {
      return this.conditions.map((condition) => this.mapConditionToDTO(condition));
    }
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => {
        if (condition.operator === OperatorType.IS_NULL) {
          return '';
        } else {
          return dayjs(value).toISOString();
        }
      }),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.lastOptimizedAt);
  }
}

export class OldValueFilterModel extends AlFilterModel {
  _key = FilterKey.OLD_VALUE;
  _name = 'Current Value';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.oldValue * 100 : value.oldValue);
  }
}

export class NewValueFilterModel extends AlFilterModel {
  _key = FilterKey.NEW_VALUE;
  _name = 'New Value';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.newValue * 100 : value.newValue);
  }
}

export class DeltaFilterModel extends AlFilterModel {
  _key = FilterKey.DELTA;
  _name = 'Delta';
  _type = FilterType.BETWEEN;
  unitType = UnitType.PERCENTAGE;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.delta * 100);
  }
}

export class EntityTypeFilterModel extends AlFilterModel {
  _key = FilterKey.ENTITY_TYPE;
  _name = 'Entities';
  _longName = 'Bidding Entity';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const SP_CATEGORY = 'SP Placements';
    const SB_CATEGORY = 'SB Placements';
    const TARGETING_CATEGORY = 'Targeting';

    return Promise.resolve([
      new AlMultiSelectOptionModel('Keyword', BiddingEntity.KEYWORD, TARGETING_CATEGORY),
      new AlMultiSelectOptionModel('Product Target & Audience', BiddingEntity.PRODUCT_TARGET, TARGETING_CATEGORY),

      new AlMultiSelectOptionModel('Top of Search', BiddingEntity.PLACEMENT_TOP, SP_CATEGORY),
      new AlMultiSelectOptionModel('Rest of Search', BiddingEntity.PLACEMENT_REST_OF_SEARCH, SP_CATEGORY),
      new AlMultiSelectOptionModel('Product Pages', BiddingEntity.PLACEMENT_PRODUCT_PAGE, SP_CATEGORY),

      new AlMultiSelectOptionModel('Top of Search', BiddingEntity.BRANDS_PLACEMENT_TOP, SB_CATEGORY),
      new AlMultiSelectOptionModel('Rest of Search', BiddingEntity.OTHER, SB_CATEGORY),
      new AlMultiSelectOptionModel('Product Pages', BiddingEntity.DETAIL_PAGE, SB_CATEGORY),
      new AlMultiSelectOptionModel('Home', BiddingEntity.HOME, SB_CATEGORY),
    ]);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    const biddingEntities = this.conditions[0].values.map((v) => v as BiddingEntity);
    return biddingEntities.includes(value.biddingEntity);
  }
}

export class ImpressionsFilterModel extends AlFilterModel {
  _key = FilterKey.IMPRESSIONS;
  _name = 'Impressions';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.impressions[0]);
  }
}

export class MatchTypeFilterModel extends AlFilterModel {
  _key = FilterKey.MATCH_TYPE;
  _name = 'Match Type';
  _type = FilterType.MULTI_SELECT;
  includedTypes: MatchType[] = [];

  constructor(includedTypes?: MatchType[], args?: FilterArguments) {
    super(args);
    this.includedTypes = includedTypes ?? [];
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    type categoryType = 'Keywords' | 'Product Targets';
    const KW_CATEGORY: categoryType = 'Keywords';
    const PT_CATEGORY: categoryType = 'Product Targets';

    const typeEnumToLabel: Record<MatchType, string> = {
      [MatchType.NONE]: '',
      [MatchType.EXACT]: 'Exact',
      [MatchType.PHRASE]: 'Phrase',
      [MatchType.BROAD]: 'Broad',
      [MatchType.THEME]: 'Theme',
      [MatchType.AUTO]: 'Auto',
      [MatchType.INDIVIDUAL]: 'Individual',
      [MatchType.EXPANDED]: 'Expanded',
      [MatchType.CATEGORY]: 'Category',
    };

    const typeEnumToCategory: Record<MatchType, categoryType> = {
      [MatchType.NONE]: KW_CATEGORY,
      [MatchType.EXACT]: KW_CATEGORY,
      [MatchType.PHRASE]: KW_CATEGORY,
      [MatchType.BROAD]: KW_CATEGORY,
      [MatchType.THEME]: KW_CATEGORY,
      [MatchType.AUTO]: PT_CATEGORY,
      [MatchType.INDIVIDUAL]: PT_CATEGORY,
      [MatchType.EXPANDED]: PT_CATEGORY,
      [MatchType.CATEGORY]: PT_CATEGORY,
    };

    let typesToUse = this.includedTypes;
    // If none given, include all
    if (this.includedTypes.length == 0) {
      // Default
      typesToUse = [
        MatchType.EXACT,
        MatchType.PHRASE,
        MatchType.BROAD,
        MatchType.THEME,
        MatchType.AUTO,
        MatchType.INDIVIDUAL,
        MatchType.EXPANDED,
        MatchType.CATEGORY,
      ];
    }

    const options = [];
    for (const t of typesToUse) {
      options.push(new AlMultiSelectOptionModel(typeEnumToLabel[t], t, typeEnumToCategory[t]));
    }

    return Promise.resolve(options);
  }

  doesFilterPass(row: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as MatchType).includes(row.match);
  }
}

export class NegativeKeywordMatchTypeFilterModel extends AlFilterModel {
  _key = FilterKey.NEGATIVE_KEYWORD_MATCH_TYPE;
  _name = 'Neg. keyword Match Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Negative exact', NegativeKeywordMatchType.NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Negative phrase', NegativeKeywordMatchType.NEGATIVE_PHRASE),
    ]);
  }
}

export class NegativeTargetLevelFilterModel extends AlFilterModel {
  _key = FilterKey.NEGATIVE_TARGETING_LEVEL;
  _name = 'Neg. Targeting Level';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Campaign', NegativeTargetingLevel.CAMPAIGN),
      new AlMultiSelectOptionModel('Ad Group', NegativeTargetingLevel.AD_GROUP),
    ]);
  }
}

export class BiddingMethodFilterModel extends AlFilterModel {
  _key = FilterKey.BIDDING_METHOD;
  _name = 'Bidding Method';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('AdLabs', BiddingMethod.ADLABS),
      new AlMultiSelectOptionModel('CPC plus', BiddingMethod.CPC_PLUS),
      new AlMultiSelectOptionModel('CPC minus', BiddingMethod.CPC_MINUS),
      new AlMultiSelectOptionModel('CPC times', BiddingMethod.CPC_TIMES),
      new AlMultiSelectOptionModel('Custom', BiddingMethod.CUSTOM),
    ]);
  }

  doesFilterPass(row: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    if (!row.biddingMethod || !Object.values(BiddingMethod).includes(row.biddingMethod as BiddingMethod)) {
      return false;
    }

    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as BiddingMethod).includes(row.biddingMethod as BiddingMethod);
  }
}

export class OrdersFilterModel extends AlFilterModel {
  _key = FilterKey.ORDERS;
  _name = 'Orders';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.orders[0]);
  }
}

export class UnitsFilterModel extends AlFilterModel {
  _key = FilterKey.UNITS;
  _name = 'Units';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.units[0]);
  }
}

export class PortfolioFilterModel extends AlFilterModel {
  _key = FilterKey.PORTFOLIO_ID;
  _name = 'Portfolios(s)';
  _longName = 'Select Portfolios';
  _type = FilterType.MULTI_SELECT;

  constructor(args?: FilterArguments) {
    super(args);
    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await campaignService.getNonArchivedPortfolioIds();
    if (res.isSuccess) {
      const options = res.payload.map((portfolio) => {
        return new AlMultiSelectOptionModel(portfolio.name, portfolio.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.campaignId);
  }
}

export class OptimizationReasonFilterModel extends AlFilterModel {
  _key = FilterKey.REASON;
  _name = 'Change Reason';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const keywordsGroupName = 'Keyword Condition';
    const placementsGroupName = 'Placements';
    const bidGroupName = 'Bid Limits';

    const KEYWORD_REASONS_1: AlMultiSelectOptionModel[] = [
      new AlMultiSelectOptionModel('High ACOS', OptimizationReason.HIGH_ACOS, keywordsGroupName),
      new AlMultiSelectOptionModel('High Spend', OptimizationReason.HIGH_SPEND, keywordsGroupName),
      new AlMultiSelectOptionModel('Low Visibility', OptimizationReason.LOW_VISIBILITY, keywordsGroupName),
      new AlMultiSelectOptionModel('Low ACOS', OptimizationReason.LOW_ACOS, keywordsGroupName),
    ];

    const KEYWORD_REASONS_2: AlMultiSelectOptionModel[] = [
      // No such filter right now new FilterSelectOptionModel('User bid floor', OptimizationReason.MAX_BID_DECREASE, keywordsGroupName),
      new AlMultiSelectOptionModel('User Bid Ceiling', OptimizationReason.USER_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Keyword Bid Ceiling', OptimizationReason.KEYWORD_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Ad Group Bid Ceiling', OptimizationReason.AD_GROUP_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Campaign Bid Ceiling', OptimizationReason.CAMPAIGN_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Campaign Group Bid Ceiling', OptimizationReason.CAMPAIGN_GROUP_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Profile Bid Ceiling', OptimizationReason.PROFILE_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Lowest Possible Bid', OptimizationReason.LOWEST_POSSIBLE_BID, bidGroupName),
      new AlMultiSelectOptionModel('Smallest Possible Increase', OptimizationReason.SMALLEST_POSSIBLE_INCREASE, bidGroupName),
    ];

    const PLACEMENTS_REASONS_1: AlMultiSelectOptionModel[] = [
      new AlMultiSelectOptionModel('Campaign Performance', OptimizationReason.CAMPAIGN_PERFORMANCE, placementsGroupName),
      new AlMultiSelectOptionModel('Campaign Group Performance', OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE, placementsGroupName),
      new AlMultiSelectOptionModel('Profile Performance', OptimizationReason.PROFILE_PERFORMANCE, placementsGroupName),
    ];

    const PLACEMENTS_REASONS_2: AlMultiSelectOptionModel[] = [
      new AlMultiSelectOptionModel('Max One Time Increase', OptimizationReason.MAX_ONE_TIME_CHANGE, placementsGroupName),
      new AlMultiSelectOptionModel('Lowest Possible Adjustment (if 0%)', OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT, placementsGroupName),
      new AlMultiSelectOptionModel(
        'Highest Possible Adjustment (if 900%)',
        OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT,
        placementsGroupName,
      ),
    ];

    return Promise.resolve([...KEYWORD_REASONS_1, ...KEYWORD_REASONS_2, ...PLACEMENTS_REASONS_1, ...PLACEMENTS_REASONS_2]);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values
      .map((v) => v as OptimizationReason)
      .some((v) => {
        if (!value.reasons) {
          return false;
        }

        console.log({ v, reasons: value.reasons });
        return value.reasons.some((r) => r === v);
      });
  }
}

export class RoasFilterModel extends AlFilterModel {
  _key = FilterKey.ROAS;
  _name = 'ROAS';
  _longName = 'ROAS (Return on Ad Spend)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.roas[0] * 100);
  }

  // No need to use percentageValueToRatio
}

export class RpcFilterModel extends AlFilterModel {
  _key = FilterKey.RPC;
  _name = 'RPC';
  _longName = 'RPC (Revenue Per Click)';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.rpc[0]);
  }

  // No need to use percentageValueToRatio
}

export class SalesFilterModel extends AlFilterModel {
  _key = FilterKey.SALES;
  _name = 'Sales';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.sales[0]);
  }
}

export class SpendFilterModel extends AlFilterModel {
  _key = FilterKey.SPEND;
  _name = 'Spend';
  _type = FilterType.BETWEEN;
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.spend[0]);
  }
}

export class TargetingFilterModel extends AlFilterModel {
  _key = FilterKey.TARGETING;
  _name = 'Target (contains)';
  _longName = 'Target (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class TargetingNotFilterModel extends AlFilterModel {
  _key = FilterKey.TARGETING_NOT;
  _name = "Target (doesn't contain)";
  _longName = "Target (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class TargetingExactMatchFilterModel extends AlFilterModel {
  _key = FilterKey.TARGETING_EXACT_MATCH;
  _name = 'Target (matches)';
  _longName = 'Target (matches)';
  _type = FilterType.STRING_EXACT_MATCH;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesExactMatchFilterPass(value.targeting);
  }
}

// Following models were created only to rename filter to neg. target
export class NegativeTargetingFilterModel extends AlFilterModel {
  _key = FilterKey.NEGATIVE_TARGETING; // This models must use same filter key as TargetingFilterModel
  _name = 'Neg. Target (contains)';
  _longName = 'Neg. Target (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class NegativeTargetingNotFilterModel extends AlFilterModel {
  _key = FilterKey.NEGATIVE_TARGETING_NOT; // This models must use same filter key as TargetingNotFilterModel
  _name = "Neg. Target (doesn't contain)";
  _longName = "Neg. Target (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class NegativeTargetingExactMatchFilterModel extends AlFilterModel {
  _key = FilterKey.NEGATIVE_TARGETING_EXACT_MATCH;
  _name = 'Neg. Target (matches)';
  _longName = 'Neg. Target (matches)';
  _type = FilterType.STRING_EXACT_MATCH;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesExactMatchFilterPass(value.targeting);
  }
}

export class TargetingTypeFilterModel extends AlFilterModel {
  _key = FilterKey.TARGETING_TYPE;
  _name = 'Targeting type';
  _type = FilterType.MULTI_SELECT;
}

export class TargetTypeFilterModel extends AlFilterModel {
  _key = FilterKey.TARGET_TYPE;
  _name = 'Target Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Keyword', TargetEntityType.KEYWORD),
      new AlMultiSelectOptionModel('Product Target & Audience', TargetEntityType.PRODUCT_TARGET),
    ]);
  }
}

export class TargetStateFilterModel extends AlFilterModel {
  _key = FilterKey.TARGET_STATE;
  _name = 'Target State';
  _longName = 'Target State';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Paused', EnabledPausedArchivedState.PAUSED),
      new AlMultiSelectOptionModel('Enabled', EnabledPausedArchivedState.ENABLED),
      new AlMultiSelectOptionModel('Archived', EnabledPausedArchivedState.ARCHIVED),
    ]);
  }
}

export class MultiAdGroupsEnabledFilterModel extends AlFilterModel {
  _key = FilterKey.MULTI_AD_GROUPS_ENABLED;
  _name = 'SB Version';
  _longName = 'SB Campaign Version';
  _type = FilterType.SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return await Promise.resolve([
      new AlMultiSelectOptionModel('Multi Ad Group', MultiAdGroupsEnabledType.TRUE),
      new AlMultiSelectOptionModel('Legacy', MultiAdGroupsEnabledType.FALSE),
    ]);
  }

  getOptionsTranslationKey(): string {
    return 'optimizer_page.multi_ad_groups_enabled';
  }
}

abstract class DataGroupItemFilterModel extends AlFilterModel {
  _type = FilterType.MULTI_SELECT;
  options?: AlMultiSelectOptionModel[];
  dataGroupType = DataGroupType.TARGET;

  constructor(dataGroupType: DataGroupType, dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(args);
    this.dataGroupType = dataGroupType;
    this.options = dataGroups
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((dataGroup) => {
        return dataGroup.items
          .map((item) => new AlMultiSelectOptionModel(item.name, item.id, dataGroup.name))
          .sort((a, b) => a.name.localeCompare(b.name));
      })
      .flat();

    this.purifyOptions();
  }

  async purifyOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id); // Available Options IDs (numbers) from API

    const currentConditionValues = this.conditions[0].values.map((v) => parseInt(v as string));
    const purifiedConditionValues: number[] = [];

    // Loop over saved condition values aka options in local store
    for (let index = 0; index < currentConditionValues.length; index++) {
      const id = currentConditionValues[index];
      if (availableOptionIDs?.includes(id)) {
        purifiedConditionValues.push(id);
      }
    }

    this.conditions[0].values = purifiedConditionValues;
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // If existing filters are loaded via factory we need to load options
    const res = await dataGroupsService.getAllGroups();
    if (res.isSuccess) {
      const options = res.payload
        .filter((dataGroup) => dataGroup.type === this.dataGroupType)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dataGroup) => {
          return dataGroup.items
            .map((item) => new AlMultiSelectOptionModel(item.name, item.id, dataGroup.name))
            .sort((a, b) => a.name.localeCompare(b.name));
        })
        .flat();

      this.options = options;
      return this.options;
    }

    return [];
  }
}

export class CampaignDataGroupItemFilterModel extends DataGroupItemFilterModel {
  _key = FilterKey.CAMPAIGN_DATA_GROUP_ITEM;
  _name = 'Data Group (Campaigns)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.CAMPAIGN, dataGroups, args);
  }
}

export class TargetDataGroupItemFilterModel extends DataGroupItemFilterModel {
  _key = FilterKey.TARGET_DATA_GROUP_ITEM;
  _name = 'Data Group (Targets)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.TARGET, dataGroups, args);
  }
}

export class SearchTermDataGroupItemFilterModel extends DataGroupItemFilterModel {
  _key = FilterKey.SEARCH_TERM_DATA_GROUP_ITEM;
  _name = 'Data Group (Search Terms)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.SEARCHTERM, dataGroups, args);
  }
}

export class SearchTermFilterModel extends AlFilterModel {
  _key = FilterKey.SEARCH_TERM;
  _name = 'Search Term (contains)';
  _longName = 'Search Term (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.searchTerm);
  }
}

export class SearchTermNotFilterModel extends AlFilterModel {
  _key = FilterKey.SEARCH_TERM_NOT;
  _name = "Search Term (doesn't contain)";
  _longName = "Search Term (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.searchTerm);
  }
}

export class SearchTermExactMatchFilterModel extends AlFilterModel {
  _key = FilterKey.SEARCH_TERM_EXACT_MATCH;
  _name = 'Search Term (matches)';
  _longName = 'Search Term (matches)';
  _type = FilterType.STRING_EXACT_MATCH;
}

export class SearchTermNegatedFilterModel extends AlFilterModel {
  _key = FilterKey.SEARCH_TERM_NEGATED;
  _name = 'Search Term Negated';
  _longName = 'Search Term Negated';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Negated', SearchTermNegatedType.NEGATED),
      new AlMultiSelectOptionModel('Not Negated', SearchTermNegatedType.NOT_NEGATED),
    ]);
  }
}

export class SearchTermHarvestedFilterModel extends AlFilterModel {
  _key = FilterKey.SEARCH_TERM_HARVESTED;
  _name = 'Search Term Harvested';
  _longName = 'Search Term Harvested';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Harvested', SearchTermHarvestedType.HARVESTED),
      new AlMultiSelectOptionModel('Not Harvested', SearchTermHarvestedType.NOT_HARVESTED),
    ]);
  }
}

export class ReportedAtFilterModel extends AlFilterModel {
  _key = FilterKey.REPORTED_AT;
  _name = 'Reported at';
  _longName = 'Reported at';
  _type = FilterType.DATE_SELECT;
}

export class CreatedAtFilterModel extends AlFilterModel {
  _key = FilterKey.CREATED_AT;
  _name = 'Created at';
  _longName = 'Created at';
  _type = FilterType.DATE_SELECT;
}

export class BidFilterModel extends AlFilterModel {
  _key = FilterKey.BID;
  _name = 'Bid';
  _longName = 'Bid';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.bid);
  }
}

export class ProductTitleFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_TITLE;
  _name = 'Product Title (contains)';
  _longName = 'Product Title (contains)';
  _type = FilterType.STRING_COMPARISON;
}

export class ProductTitleNotFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_TITLE_NOT;
  _name = "Product Title (doesn't contain)";
  _longName = "Product Title (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
}

export class ProductAsinFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_ASIN;
  _name = 'Product ASIN';
  _longName = 'Product ASIN';
  _type = FilterType.MULTI_SELECT;

  constructor(args?: FilterArguments) {
    super(args);
    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await productsService.getASINs();
    if (res.isSuccess) {
      const options = res.payload.map((category) => {
        return new AlMultiSelectOptionModel(category, category);
      });
      this.options = options.sort((a, b) => a.name.localeCompare(b.name));

      return this.options;
    }

    return [];
  }
}

export class ProductSkuFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_SKU;
  _name = 'Product SKU';
  _longName = 'Product SKU';
  _type = FilterType.MULTI_SELECT;

  constructor(args?: FilterArguments) {
    super(args);
    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await productsService.getSKUs();
    if (res.isSuccess) {
      const options = res.payload.map((category) => {
        return new AlMultiSelectOptionModel(category, category);
      });
      this.options = options.sort((a, b) => a.name.localeCompare(b.name));

      return this.options;
    }

    return [];
  }
}

export class ProductBrandFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_BRAND;
  _name = 'Product Brand(s)';
  _longName = 'Select Product Brand';
  _type = FilterType.MULTI_SELECT;

  constructor(args?: FilterArguments) {
    super(args);
    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await productsService.getBrands();
    if (res.isSuccess) {
      const options = res.payload.map((brand) => {
        return new AlMultiSelectOptionModel(brand, brand);
      });

      this.options = options
        .sort((a, b) => {
          // Move empty to the end
          if (a.name === '') return 1;
          if (b.name === '') return -1;
          // Otherwise, sort alphabetically
          return a.name.localeCompare(b.name);
        })
        .map((o) => {
          if (o.name === '') {
            o.name = '< No Brands >';
          }
          return o;
        });

      return this.options;
    }

    return [];
  }
}

export class ProductCategoryFilterModel extends AlFilterModel {
  _key = FilterKey.PRODUCT_CATEGORY;
  _name = 'Product Category';
  _longName = 'Select Product Category';
  _type = FilterType.MULTI_SELECT;

  constructor(args?: FilterArguments) {
    super(args);
    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await productsService.getCategories();
    if (res.isSuccess) {
      const options = res.payload.map((category) => {
        return new AlMultiSelectOptionModel(category, category);
      });
      this.options = options
        .sort((a, b) => {
          // Move empty to the end
          if (a.name === '') return 1;
          if (b.name === '') return -1;
          // Otherwise, sort alphabetically
          return a.name.localeCompare(b.name);
        })
        .map((o) => {
          if (o.name === '') {
            o.name = '< No Category >';
          }
          return o;
        });

      return this.options;
    }

    return [];
  }
}

export class ProductDataGroupItemFilterModel extends DataGroupItemFilterModel {
  _key = FilterKey.PRODUCT_DATA_GROUP_ITEM;
  _name = 'Data Group (Products)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.PRODUCT, dataGroups, args);
  }
}

export class AcotsFilterModel extends AlFilterModel {
  _key = FilterKey.ACOTS;
  _name = 'ACOTS';
  _longName = 'Ad Cost of Total Sales (ACOTS)';
  _type = FilterType.BETWEEN;
}

export class AspFilterModel extends AlFilterModel {
  _key = FilterKey.ASP;
  _name = 'ASP';
  _longName = 'Average Sales Price (ASP)';
  _type = FilterType.BETWEEN;
}

export class OrganicSalesFilterModel extends AlFilterModel {
  _key = FilterKey.ORGANIC_SALES;
  _name = 'Organic Sales';
  _longName = 'Organic Sales';
  _type = FilterType.BETWEEN;
}

export class DetailPageViewsFilterModel extends AlFilterModel {
  _key = FilterKey.TOTAL_VIEWS;
  _name = 'Detail Page Views';
  _longName = 'Detail Page Views';
  _type = FilterType.BETWEEN;
}

export class TotalUnitsFilterModel extends AlFilterModel {
  _key = FilterKey.TOTAL_UNITS;
  _name = 'Total Units';
  _longName = 'Total Units';
  _type = FilterType.BETWEEN;
}

export class UspFilterModel extends AlFilterModel {
  _key = FilterKey.USP;
  _name = 'USP';
  _longName = 'Unit Session Percentage';
  _type = FilterType.BETWEEN;
}

export class TotalCvrFilterModel extends AlFilterModel {
  _key = FilterKey.TCVR;
  _name = 'Total CVR';
  _longName = 'Total CVR';
  _type = FilterType.BETWEEN;
}

export class TotalClicksFilterModel extends AlFilterModel {
  _key = FilterKey.TOTAL_CLICKS;
  _name = 'Total Clicks';
  _longName = 'Total Clicks';
  _type = FilterType.BETWEEN;
}

export class TotalOrdersFilterModel extends AlFilterModel {
  _key = FilterKey.TOTAL_ORDERS;
  _name = 'Total Orders';
  _longName = 'Total Orders';
  _type = FilterType.BETWEEN;
}

export class TotalSalesFilterModel extends AlFilterModel {
  _key = FilterKey.TOTAL_SALES;
  _name = 'Total Sales';
  _longName = 'Total Sales';
  _type = FilterType.BETWEEN;
}

export class TeamFilterModel extends AlFilterModel {
  _key = FilterKey.TEAM;
  _name = 'Team';
  _longName = 'Team';
  _type = FilterType.MULTI_SELECT;

  constructor(teamIdNames: IdNameDTO[], args?: FilterArguments) {
    super(args);
    this.options = teamIdNames
      .map((teamIdName) => {
        return new AlMultiSelectOptionModel(teamIdName.name, teamIdName.id.toString());
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.purifyOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    return Promise.resolve([]);
  }
  async purifyOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id);

    const currentConditionValues = this.conditions[0].values.map((v) => v.toString());
    const purifiedConditionValues: string[] = [];

    // Loop over saved condition values aka options in local store
    for (let index = 0; index < currentConditionValues.length; index++) {
      const id = currentConditionValues[index];
      if (availableOptionIDs?.includes(id)) {
        purifiedConditionValues.push(id);
      }
    }

    this.conditions[0].values = purifiedConditionValues;
  }

  doesFilterPass(value: ProfileWithMetricsModel): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.teamId.toString());
  }
}

export function createCampaignFilters(campaignGroups: CampaignGroupModel[], campaignDataGroups: DataGroupModel[]): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignNameNotFilterModel(),
    new PortfolioFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    ...createMetricFilters(),
  ];
}
export function createCampaignFiltersForMultipleProfiles(
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  teamProfiles: TeamProfile[],
): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignFilterModel({
      teamProfiles,
    }),
    new CampaignNameFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignNameNotFilterModel(),
    new PortfolioFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createOptimizerFilters(adGroups: IdNameDTO[], campaignGroups: CampaignGroupModel[], campaigns: IdNameDTO[]): AlFilterModel[] {
  return [
    new EntityTypeFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignFilterModel({ campaigns }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new OldValueFilterModel(),
    new NewValueFilterModel(),
    new DeltaFilterModel(),
    new OptimizationReasonFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createTargetingFilters(
  adGroups: IdNameDTO[],
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
): AlFilterModel[] {
  return [
    new CampaignAdTypeFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    ...createMetricFilters(),
  ];
}

export function createTargetingFiltersForMultipleProfiles(
  adGroups: IdNameDTO[],
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
  teamProfiles: TeamProfile[],
): AlFilterModel[] {
  return [
    new CampaignAdTypeFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new CampaignFilterModel({ teamProfiles }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    ...createMetricFilters(),
  ];
}

export function createPlacementsFilters(campaignGroups: CampaignGroupModel[], campaignDataGroups: DataGroupModel[]): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new PlacementTypeFilterModel(),
    new BidAdjustmentTypeFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignNameNotFilterModel(),
    new PortfolioFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createSearchTermsFilters(
  campaignGroups: CampaignGroupModel[],
  adGroups: IdNameDTO[],
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
  searchTermDataGroups: DataGroupModel[],
): AlFilterModel[] {
  return [
    new SearchTermFilterModel(),
    new SearchTermNotFilterModel(),
    new SearchTermExactMatchFilterModel(),
    new SearchTermNegatedFilterModel(),
    new SearchTermHarvestedFilterModel(),
    new CampaignAdTypeFilterModel(true),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new LastOptimizedDateModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    new SearchTermDataGroupItemFilterModel(searchTermDataGroups),
    ...createMetricFilters(),
  ];
}

export function createNegativeTargetingFilters() {
  return [
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new CampaignAdTypeFilterModel(),
    new AdGroupNameFilterModel(),
    new AdGroupNameNotFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new NegativeTargetLevelFilterModel(),
    new NegativeKeywordMatchTypeFilterModel(),
    new NegativeTargetingFilterModel(),
    new NegativeTargetingNotFilterModel(),
    new NegativeTargetingExactMatchFilterModel(),
    new CreatedAtFilterModel(),
  ];
}

export function createCampaignGroupsFilters(): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignGroupAdTypeFilterModel(),
    new CampaignGroupNameFilterModel(),
    new CampaignGroupNameNotFilterModel(),
  ];
}

export function createKeywordHarvestingFilters(): AlFilterModel[] {
  return [
    new SearchTermFilterModel(),
    new SearchTermNotFilterModel(),
    new MatchTypeFilterModel([MatchType.EXACT, MatchType.PHRASE, MatchType.BROAD, MatchType.INDIVIDUAL, MatchType.EXPANDED]),
    new BiddingMethodFilterModel(),
    new BidFilterModel(),
    new SourceCampaignNameFilterModel(),
    new SourceCampaignNameNotFilterModel(),
    new DestinationCampaignNameFilterModel(),
    new DestinationCampaignNameNotFilterModel(),
    new SourceAdGroupNameFilterModel(),
    new SourceAdGroupNameNotFilterModel(),
    new DestinationAdGroupNameFilterModel(),
    new DestinationAdGroupNameNotFilterModel(),
    new EntityTypeFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createCampaignMappingFilters(): AlFilterModel[] {
  return [
    new SourceCampaignNameFilterModel(),
    new SourceCampaignNameNotFilterModel(),
    new SourceAdGroupNameFilterModel(),
    new SourceAdGroupNameNotFilterModel(),
    // new SourceCampaignAdTypeFilterModel(),
    new DestinationCampaignNameFilterModel(),
    new DestinationCampaignNameNotFilterModel(),
    new DestinationAdGroupNameFilterModel(),
    new DestinationAdGroupNameNotFilterModel(),
    new DestinationCampaignAdTypeFilterModel(),
    new BiddingMethodFilterModel(),
  ];
}

export function createProductFilters(productDataGroups: DataGroupModel[], isSeller: boolean, isVendor: boolean): AlFilterModel[] {
  const filters = [
    new ProductTitleFilterModel(),
    new ProductTitleNotFilterModel(),
    new ProductAsinFilterModel(),
    new ProductSkuFilterModel(),
    new ProductCategoryFilterModel(),
    new ProductBrandFilterModel(),
    new ProductDataGroupItemFilterModel(productDataGroups),
    ...createMetricFilters(),
  ];

  if (Environment.isDev()) {
    if (isSeller || isVendor) {
      filters.push(
        new AcotsFilterModel(),
        new AspFilterModel(),
        new OrganicSalesFilterModel(),
        new DetailPageViewsFilterModel(),
        new TotalUnitsFilterModel(),
        new TotalSalesFilterModel(),
      );
    }

    // Seller and vendor are not exclusive
    if (isSeller) {
      filters.push(new UspFilterModel(), new TotalCvrFilterModel(), new TotalClicksFilterModel(), new TotalOrdersFilterModel());
    }
  }

  return filters;
}

export function createAdvertisedProductFilters(
  adGroups: IdNameDTO[],
  productDataGroups: DataGroupModel[],
  campaigns: IdNameDTO[],
): AlFilterModel[] {
  const filters = [
    new ProductTitleFilterModel(),
    new ProductTitleNotFilterModel(),
    new ProductAsinFilterModel(),
    new ProductSkuFilterModel(),
    new ProductDataGroupItemFilterModel(productDataGroups),
    new CampaignFilterModel({ campaigns }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new TotalUnitsFilterModel(),
    ...createMetricFilters(),
  ];

  return filters;
}

export function createProfileStatsFilters(teamIdName: IdNameDTO[]): AlFilterModel[] {
  // TODO: add other filters
  return [new TeamFilterModel(teamIdName)];
}

function createMetricFilters(): AlFilterModel[] {
  return [
    new ImpressionsFilterModel(),
    new ClicksFilterModel(),
    new OrdersFilterModel(),
    new UnitsFilterModel(),
    new SpendFilterModel(),
    new SalesFilterModel(),
    new CtrFilterModel(),
    new CvrFilterModel(),
    new CpcFilterModel(),
    new AcosFilterModel(),
    new RoasFilterModel(),
    new RpcFilterModel(),
    new CpaFilterModel(),
    new AovFilterModel(),
    new CpmFilterModel(),
  ];
}

export const getUpdatedFiltersValue = (previousValue: AlFilterModel[], updatedFilter: AlFilterModel) => {
  const updatedFilters = [...previousValue];
  const existingFilterIndex = updatedFilters.findIndex((filterItem) => filterItem.key === updatedFilter.key);

  if (existingFilterIndex !== -1) {
    updatedFilters[existingFilterIndex] = updatedFilter;
  } else {
    updatedFilters.push(updatedFilter);
  }

  return updatedFilters;
};

export function getDefaultDateRange(): AlFilterModel[] {
  const today = dayjs();
  const periodLengthInDays = 14;
  const startDate = today.subtract(periodLengthInDays, 'day');
  const endDate = today.subtract(1, 'day');

  const defaultDateRange: AlFilterModel[] = [
    new ComparisonDateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.subtract(periodLengthInDays, 'day').format(DATE_FORMAT)],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.subtract(periodLengthInDays, 'day').format(DATE_FORMAT)],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
    new DateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.format(DATE_FORMAT)],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.format(DATE_FORMAT)],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
  ];

  return defaultDateRange;
}

function getGreaterThanZeroImpressionsFilter(): AlFilterModel {
  return new ImpressionsFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: ['0'],
        operator: OperatorType.GREATER_THAN,
      },
    ],
  });
}

export function getDefaultCampaignFilters(): AlFilterModel[] {
  const campaignStateFilter: AlFilterModel = new CampaignStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultDateRange(), campaignStateFilter];
}

export function getDefaultTargetFilters(): AlFilterModel[] {
  const campaignStateFilter: AlFilterModel = new CampaignStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  const targetStateFilter: AlFilterModel = new TargetStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultDateRange(), campaignStateFilter, targetStateFilter, getGreaterThanZeroImpressionsFilter()];
}

export function getDefaultPlacementsFilters(): AlFilterModel[] {
  return getDefaultCampaignFilters();
}

export function getDefaultSearchTermsFilters(): AlFilterModel[] {
  const targetStateFilter: AlFilterModel = new TargetStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultCampaignFilters(), targetStateFilter];
}

export function getDefaultNegativeTargetingFilters(): AlFilterModel[] {
  return getDefaultCampaignFilters();
}

export function getDefaultCampaignGroupsFilters(): AlFilterModel[] {
  return getDefaultDateRange();
}

export function getDefaultCampaignMappingFilters(): AlFilterModel[] {
  return [];
}

export function getDefaultProductsFilters(): AlFilterModel[] {
  return getDefaultDateRange();
}

export function getDefaultAdvertisedProductsFilters(): AlFilterModel[] {
  return getDefaultDateRange();
}

export function uniqueFiltersByKeys(filters: AlFilterModel[]): AlFilterModel[] {
  const uniqueFilters = new Map<string, AlFilterModel>();

  filters.forEach((filter) => {
    uniqueFilters.set(filter.key, filter); // If a key is duplicated, it will overwrite the previous one
  });

  return Array.from(uniqueFilters.values()); // Convert to an array of FilterModel
}
