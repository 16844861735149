import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField, MetricField } from '@/components/metrics/types/MetricField';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByTime } from '../../forms/GroupByTimeSelect';

export class LineGraphWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Line Graph';
  selectedMetrics: MetricField[] = [CommonMetricField.SALES, CommonMetricField.ACOS, CommonMetricField.SPEND, CommonMetricField.ROAS];
  groupByTime = GroupByTime.DAY;
  entityType = EntityType.PROFILE;
  campaignFilters: FilterDTO[] = [];
  targetsFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
