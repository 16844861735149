import { getMetricConfigByColId, getSentimentByMetric } from '@/components/metrics/MetricsConfig';
import { MetricData } from '@/components/metrics/types/MetricData';
import { CommonMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { CampaignAdType, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { SentimentDirection, getCampaignAdTypeColor, getPlacementTypeColor } from '@/types/colors.enum';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ITextCellRendererParams } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import {
  calculateMetricDataACOS,
  calculateMetricDataACOTS,
  calculateMetricDataAOV,
  calculateMetricDataASP,
  calculateMetricDataCPA,
  calculateMetricDataCPC,
  calculateMetricDataCPM,
  calculateMetricDataCTR,
  calculateMetricDataCVR,
  calculateMetricDataROAS,
  calculateMetricDataRPC,
  calculateMetricDataTCVR,
  calculateMetricDataUSP,
} from '../helpers';

const useColDefsFunctions = () => {
  const { formatWithThousandsSeparator, getLongFormatterForMetricField } = useFormatting();
  const { t } = useTranslation();

  const metricsDataComparator = (valueA: MetricData, valueB: MetricData): number => {
    if (valueA[0] > valueB[0]) {
      return 1;
    } else if (valueA[0] < valueB[0]) {
      return -1;
    } else {
      return 0;
    }
  };

  const metricsDataDeltaComparator = (valueA: MetricData, valueB: MetricData): number => {
    // [0] = current value, [1] = previous value. Compare the delta between the two
    const deltaA = valueA[0] - valueA[1];
    const deltaB = valueB[0] - valueB[1];

    if (deltaA > deltaB) {
      return 1;
    } else if (deltaA < deltaB) {
      return -1;
    } else {
      return 0;
    }
  };

  const metricsDataDeltaPercentageComparator = (valueA: MetricData, valueB: MetricData): number => {
    // [0] = current value, [1] = previous value. Compare the delta in the percentage of change between the two
    const deltaA = valueA[0] / valueA[1];
    const deltaB = valueB[0] / valueB[1];

    if (deltaB === Infinity) {
      return 1;
    } else if (deltaA === Infinity) {
      return -1;
    }

    if (deltaA > deltaB) {
      return 1;
    } else if (deltaA < deltaB) {
      return -1;
    } else {
      return 0;
    }
  };

  function getCalculatedMetricAggData(metricField: MetricField, aggData: Record<string, MetricData>): MetricData {
    switch (metricField) {
      case CommonMetricField.CTR:
        return calculateMetricDataCTR(aggData);
      case CommonMetricField.CVR:
        return calculateMetricDataCVR(aggData);
      case CommonMetricField.CPC:
        return calculateMetricDataCPC(aggData);
      case CommonMetricField.ACOS:
        return calculateMetricDataACOS(aggData);
      case CommonMetricField.ROAS:
        return calculateMetricDataROAS(aggData);
      case CommonMetricField.RPC:
        return calculateMetricDataRPC(aggData);
      case CommonMetricField.CPA:
        return calculateMetricDataCPA(aggData);
      case CommonMetricField.AOV:
        return calculateMetricDataAOV(aggData);
      case CommonMetricField.CPM:
        return calculateMetricDataCPM(aggData);
      // Seller metrics
      case SellerMetricField.SELLER_ACOS:
        return calculateMetricDataACOTS(aggData);
      case SellerMetricField.SELLER_ASP:
        return calculateMetricDataASP(aggData);
      case SellerMetricField.SELLER_CVR:
        return calculateMetricDataTCVR(aggData);
      case SellerMetricField.SELLER_UNIT_SESS:
        return calculateMetricDataUSP(aggData);

      default:
        return [0, 0];
    }
  }

  const getMetricFieldChangePercentageCellRendererParams = (params: ICellRendererParams, formattingParams?: FormattingParams) => {
    const columnId = params.colDef?.colId as ColumnId;
    const metricField = columnId ? (getMetricConfigByColId(columnId)?.key ?? CommonMetricField.IMPRESSIONS) : CommonMetricField.IMPRESSIONS;

    return {
      currentValueFormatter: params.colDef
        ? (number: number | null | undefined) => {
            return getLongFormatterForMetricField(metricField)(number, formattingParams);
          }
        : formatWithThousandsSeparator,
      sentimentDirection: params.colDef ? getSentimentByMetric(metricField) : SentimentDirection.SYNCED,
      value: params.value,
      comparisonUnit: params.context?.comparisonUnit,
      isComparisonDataMissing: params.context?.isComparisonDataMissing,
    };
  };

  const getCampaignAdTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`optimizer_page.ad_type.${key}`) : ''),
      valueToColor: (key: string) => getCampaignAdTypeColor(CampaignAdType[key as keyof typeof CampaignAdType]),
      valueToTooltip: (key: string) => {
        const type = CampaignAdType[key as keyof typeof CampaignAdType];
        switch (type) {
          case CampaignAdType.PRODUCTS:
            return t(`optimizer_page.ad_type_long.${type}`);
          case CampaignAdType.BRANDS:
            return t(`optimizer_page.ad_type_long.${type}`);
          case CampaignAdType.DISPLAY:
            return t(`optimizer_page.ad_type_long.${type}`);
          default:
            return '';
        }
      },
    };
  };

  const getPlacementTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`optimizer_page.bidding_entity.${key}`) : ''),
      valueToColor: (key: string) => getPlacementTypeColor(PlacementType[key as keyof typeof PlacementType]),
      // valueToTooltip: (key: string) => {
      //   const type = PlacementType[key as keyof typeof PlacementType];
      //   switch (type) {
      //     case PlacementType.PLACEMENT_PRODUCT_PAGE:
      //       return 'Product Page';
      //     case PlacementType.PLACEMENT_REST_OF_SEARCH:
      //       return 'Rest of Search';
      //     case PlacementType.PLACEMENT_TOP:
      //       return 'Top of Search';
      //     default:
      //       return '';
      //   }
      // Missing Home etc
      // },
    };
  };

  return {
    metricsDataComparator,
    metricsDataDeltaComparator,
    metricsDataDeltaPercentageComparator,
    getMetricFieldChangePercentageCellRendererParams,
    getCampaignAdTypeCellRendererParams,
    getPlacementTypeCellRendererParams,
    getCalculatedMetricAggData,
  };
};

export default useColDefsFunctions;
