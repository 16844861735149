import { ColumnId } from '@/components/grid/columns/columns.enum';
import { SentimentDirection } from '@/types/colors.enum';
import { UnitType } from '../filter-builder/models/AlFilterModel';
import { CommonMetricField, MetricField, SellerMetricField } from './types/MetricField';

export const METRIC_COLORS: string[] = ['#3fb1e3', '#6be6c1', '#626c91', '#ffb980'];
export const MAX_METRICS_CAN_SELECT = METRIC_COLORS.length;

export interface MetricColor {
  key: MetricField;
  color: string;
}

export interface MetricCurrentPrevious {
  key: CommonMetricField;
  current: number;
  previous: number;
}

export function getUpdatedSelectionColors(currentSelectedMetricColors: MetricColor[], selectedMetrics: MetricField[]) {
  // Clean the selectedMetricColors of objects with keys not in selectedMetrics anymore
  const filteredSelectedMetricColors = currentSelectedMetricColors.filter((color) => selectedMetrics.includes(color.key));
  const newSelectedMetricColors: MetricColor[] = [];

  selectedMetrics.forEach((metricKey) => {
    const existingColor = filteredSelectedMetricColors.find((color) => color.key === metricKey);

    if (existingColor) {
      newSelectedMetricColors.push(existingColor);
    } else {
      const availableColors = METRIC_COLORS.filter(
        (color) => !newSelectedMetricColors.some((selectedColor) => selectedColor.color === color),
      );

      if (availableColors.length > 0) {
        const newColor = availableColors[0]; // When no colors are left, picking the first one
        newSelectedMetricColors.push({ key: metricKey, color: newColor });
      }
    }
  });

  return newSelectedMetricColors;
}

export enum ReducerType {
  SUM,
  AVG,
}

export interface MetricConfiguration {
  key: MetricField;
  title: string;
  sentimentDirection: SentimentDirection;
  reducerType: ReducerType;
  chartSeriesType: 'bar' | 'line';
  colId: ColumnId;
  isCalculatedMetric: boolean;
  unitType: UnitType;
}

export function getConfigForMetric(metricKey: MetricField): MetricConfiguration {
  return METRICS[metricKey];
}

export function getMetricReducer(metricKey: CommonMetricField): ReducerType {
  return METRICS[metricKey].reducerType;
}

export function getSentimentByMetric(metricKey: MetricField): SentimentDirection {
  return METRICS[metricKey].sentimentDirection;
}

export function getMetricConfigByColId(colId: ColumnId): MetricConfiguration | undefined {
  return Object.values(METRICS).find((metric) => metric.colId === colId);
}

export function getMetricColumns() {
  return Object.values(METRICS).map((metric) => metric.colId);
}

// This type ensures that every metric is used in METRICS map
type CompleteMetricConfigMap = { [K in MetricField]: MetricConfiguration };

export const METRICS: CompleteMetricConfigMap = {
  [CommonMetricField.IMPRESSIONS]: {
    key: CommonMetricField.IMPRESSIONS,
    title: 'Impressions',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.IMPRESSIONS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.CLICKS]: {
    key: CommonMetricField.CLICKS,
    title: 'Clicks',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.CLICKS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.ORDERS]: {
    key: CommonMetricField.ORDERS,
    title: 'Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.UNITS]: {
    key: CommonMetricField.UNITS,
    title: 'Units',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.UNITS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.CTR]: {
    key: CommonMetricField.CTR,
    title: 'CTR',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.CTR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.CVR]: {
    key: CommonMetricField.CVR,
    title: 'CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.CVR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.CPC]: {
    key: CommonMetricField.CPC,
    title: 'CPC',
    sentimentDirection: SentimentDirection.NEUTRAL,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.CPC,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SPEND]: {
    key: CommonMetricField.SPEND,
    title: 'Spend',
    sentimentDirection: SentimentDirection.NEUTRAL,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.SPEND,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SALES]: {
    key: CommonMetricField.SALES,
    title: 'Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SAME_SKU_ORDERS]: {
    key: CommonMetricField.SAME_SKU_ORDERS,
    title: 'sSKU Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.SAME_SKU_SALES]: {
    key: CommonMetricField.SAME_SKU_SALES,
    title: 'sSKU Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.ACOS]: {
    key: CommonMetricField.ACOS,
    title: 'ACOS',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.ACOS,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.ROAS]: {
    key: CommonMetricField.ROAS,
    title: 'ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.ROAS,
    isCalculatedMetric: true,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.RPC]: {
    key: CommonMetricField.RPC,
    title: 'RPC',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.RPC,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.CPA]: {
    key: CommonMetricField.CPA,
    title: 'CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.CPA,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.AOV]: {
    key: CommonMetricField.AOV,
    title: 'AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.AOV,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.CPM]: {
    key: CommonMetricField.CPM,
    title: 'CPM',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
    colId: ColumnId.CPM,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },

  [SellerMetricField.SELLER_ACOS]: {
    key: SellerMetricField.SELLER_ACOS,
    title: 'ACOTS',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.ACOTS,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_ASP]: {
    key: SellerMetricField.SELLER_ASP,
    title: 'ASP',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.ASP,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_CLICKS]: {
    key: SellerMetricField.SELLER_CLICKS,
    title: 'Total Clicks',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_CLICKS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_ORDERS]: {
    key: SellerMetricField.SELLER_ORDERS,
    title: 'Total Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_ORG_SALES]: {
    key: SellerMetricField.SELLER_ORG_SALES,
    title: 'Organic Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.ORGANIC_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_SALES]: {
    key: SellerMetricField.SELLER_SALES,
    title: 'Total Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_CVR]: {
    key: SellerMetricField.SELLER_CVR,
    title: 'Total CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_CVR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_UNIT_SESS]: {
    key: SellerMetricField.SELLER_UNIT_SESS,
    title: 'Units Per Session',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
    colId: ColumnId.USP,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_UNITS]: {
    key: SellerMetricField.SELLER_UNITS,
    title: 'Total Units',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_UNITS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_VIEWS]: {
    key: SellerMetricField.SELLER_VIEWS,
    title: 'Total Views',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_VIEWS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
};

export const DEFAULT_SELECTED_METRICS: CommonMetricField[] = [CommonMetricField.ACOS, CommonMetricField.SALES];
export const DEFAULT_SELECTED_METRIC_COLORS: MetricColor[] = [
  { key: CommonMetricField.ACOS, color: METRIC_COLORS[0] },
  { key: CommonMetricField.SALES, color: METRIC_COLORS[1] },
];
export const DEFAULT_VISIBLE_METRICS: CommonMetricField[] = [
  CommonMetricField.IMPRESSIONS,
  CommonMetricField.CLICKS,
  CommonMetricField.ORDERS,
  CommonMetricField.SPEND,
  CommonMetricField.SALES,
  CommonMetricField.ACOS,
  CommonMetricField.CTR,
  CommonMetricField.CVR,
];
