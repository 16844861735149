import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import MetricsCheckboxList from '@/components/metrics/MetricsCheckboxList';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import TargetingFilterListEditor from '@/modules/campaigns/components/TargetingFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useDashboardQueries } from '@/modules/dashboards/hooks/useDashboardQueries';
import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupByTimeSelect, { GroupByTimeSelectOption } from '../../forms/GroupByTimeSelect';
import { LineGraphWidgetConfiguration } from './LineGraphWidgetConfiguration';
import { MetricField } from '@/components/metrics/types/MetricField';

interface LineGraphWidgetConfigurationFormProps {
  configuration: LineGraphWidgetConfiguration;
  id: string;
}

const LineGraphWidgetConfigurationForm: FunctionComponent<LineGraphWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { refetchForWidgetWithId } = useDashboardQueries();
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateWidgetConfiguration<LineGraphWidgetConfiguration>(id, {
      title: e.currentTarget.value,
    });

    refetchForWidgetWithId(id);
  };

  const handleGroupByTimeChange = async (groupByTime: GroupByTimeSelectOption) => {
    await updateWidgetConfiguration<LineGraphWidgetConfiguration>(
      id,
      {
        groupByTime: groupByTime.value,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleSelectedMetricsChange = async (selectedMetrics: MetricField[]) => {
    await updateWidgetConfiguration<LineGraphWidgetConfiguration>(
      id,
      {
        selectedMetrics: selectedMetrics,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const onCampaignFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<LineGraphWidgetConfiguration>(
      id,
      {
        campaignFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const onTargetingFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<LineGraphWidgetConfiguration>(
      id,
      {
        targetsFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleEntityTypeChange = async (entityType: EntityType | '') => {
    if (entityType === '') return;
    await updateWidgetConfiguration<LineGraphWidgetConfiguration>(
      id,
      {
        entityType: entityType,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  return (
    <>
      <TextField label="Name" value={configuration.title} onChange={handleTitleChange} />

      <EntityTypeSelect
        value={configuration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
      />

      <div>
        <div className="text-sm">Time Grouping</div>
        <Typography variant="caption">How the data will be grouped on the x-axis</Typography>
        <div className="mt-1">
          <GroupByTimeSelect value={configuration.groupByTime} onChange={handleGroupByTimeChange} />
        </div>
      </div>

      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select the metrics to display</Typography>

        <MetricsCheckboxList value={configuration.selectedMetrics} onChange={handleSelectedMetricsChange} maxSelectedItems={4} />
      </div>

      {(configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE) && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <CampaignFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.campaignFilters
                ? configuration.campaignFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onCampaignFiltersChanged}
          />
        </>
      )}

      {configuration.entityType === EntityType.KEYWORD && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <TargetingFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.targetsFilters
                ? configuration.targetsFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onTargetingFiltersChanged}
          />
        </>
      )}
    </>
  );
};

export default LineGraphWidgetConfigurationForm;
