import { SortByUnit } from '@/components/buttons/SortByToggleButton';
import { MetricData } from '@/components/metrics/types/MetricData';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect, useMemo } from 'react';
import { ColumnId } from '../columns/columns.enum';
import { ComparisonUnit } from '../types';

interface UseComparatorParams {
  metricsDataComparator: (valueA: MetricData, valueB: MetricData) => number;
  metricsDataDeltaComparator: (valueA: MetricData, valueB: MetricData) => number;
  metricsDataDeltaPercentageComparator: (valueA: MetricData, valueB: MetricData) => number;
}

export const useComparator = (
  gridApiRef: React.MutableRefObject<GridApi | undefined>,
  contextSortByUnit: SortByUnit,
  contextComparisonUnit: ComparisonUnit,
  { metricsDataComparator, metricsDataDeltaComparator, metricsDataDeltaPercentageComparator }: UseComparatorParams,
) => {
  // Memoize the comparator selection logic to optimize performance
  const comparator = useMemo(() => {
    // If SortByUnit is Count, use the metricsDataComparator
    if (contextSortByUnit === SortByUnit.Count) {
      return metricsDataComparator;
    }

    // If the comparison unit is percent, use the delta percentage comparator
    if (contextComparisonUnit === 'percent') {
      return metricsDataDeltaPercentageComparator;
    }

    // Otherwise, default to the delta comparator
    return metricsDataDeltaComparator;
  }, [contextSortByUnit, contextComparisonUnit, metricsDataComparator, metricsDataDeltaComparator, metricsDataDeltaPercentageComparator]);

  // Update the grid comparators whenever the comparator changes
  useEffect(() => {
    if (!gridApiRef.current) return;

    const columnsToUpdate = [
      ColumnId.IMPRESSIONS,
      ColumnId.CLICKS,
      ColumnId.ORDERS,
      ColumnId.UNITS,
      ColumnId.CTR,
      ColumnId.CVR,
      ColumnId.CPC,
      ColumnId.SPEND,
      ColumnId.SALES,
      ColumnId.SAME_SKU_ORDERS,
      ColumnId.SAME_SKU_SALES,
      ColumnId.ACOS,
      ColumnId.ROAS,
      ColumnId.RPC,
      ColumnId.CPA,
      ColumnId.AOV,
      ColumnId.CPM,
    ];

    columnsToUpdate.forEach((colId) => {
      const column = gridApiRef.current!.getColumn(colId);
      if (column) {
        const colDef = column.getColDef();
        colDef.comparator = comparator;
      }
    });

    // Refresh the grid sorting to apply the new comparator
    gridApiRef.current.refreshClientSideRowModel('sort');
  }, [comparator, gridApiRef]);

  return comparator;
};
