import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import BooleanCellRenderer, { IBooleanCellRendererParams } from '@/components/grid/cells/BooleanCellRenderer';
import EnabledPausedArchivedStateCellRenderer from '@/components/grid/cells/EnabledPausedArchivedStateCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import { useComparator } from '@/components/grid/hooks/useComparators';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight from '@/components/grid/hooks/useDynamicHeight';
import { AlColDef, ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { TailwindColorVariant } from '@/config/theme/color.type';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import useBidLimits from '@/modules/optimizer/components/optimization/bid-limits';
import { TargetEntityExtendedType } from '@/modules/targeting/api/targets-contracts';
import { UserSettingKey } from '@/modules/users';
import { TargetEntityTypeColors } from '@/types/colors.enum';
import { Card } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  SelectionChangedEvent,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import useComparisonUnitColumnData from '../../../../components/grid/hooks/useComparisonUnitColumnData';
import { MetricAggregates } from '../../../../components/metrics/types/MetricField';
import { CreateNegativeModel } from '../../../negative-targets/models/CreateNegativeModel';
import { NegativeTypeShort, SearchTermsWithTimeline } from '../../api/search-terms-contracts';
import { useSearchTermsContext } from '../../contexts/SearchTermsContext';
import useSearchTermsMetricColumnAggregates from '../../hooks/useSearchTermsMetricColumnAggregates';
import { SearchTermModel, SelectedSearchTerm } from '../../models/SearchTermModel';
import { generateSearchTermsTableColumnState } from './search-terms-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 60;

interface SearchTermsTableProps {
  withTimeline: SearchTermsWithTimeline | undefined;
  isLoading: boolean;
  selectedSearchTerms: SelectedSearchTerm[];
  setSelectedSearchTerms: (selectedSearchTerms: SelectedSearchTerm[]) => void;
  searchTermsLoadingErrorMessage: string;
  isSearchTermsLoadingError: boolean;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
}

export interface SearchTermsGridContext extends ExpandedGridContext {
  metricColumnAggregates: MetricAggregates | undefined;
  comparisonUnit: ComparisonUnit;
}
const SearchTermsTable: FunctionComponent<SearchTermsTableProps> = ({
  withTimeline,
  isLoading,
  selectedSearchTerms,
  setSelectedSearchTerms,
  searchTermsLoadingErrorMessage,
  isSearchTermsLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.SEARCH_TERMS_TABLE_COLUMN_STATE,
    generateSearchTermsTableColumnState(),
  );

  const rowData = withTimeline?.searchTerms ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<SearchTermModel>>();
  const gridContextRef = useRef<SearchTermsGridContext>();
  const pendingBidUpdateCellLocationsRef = useRef<Record<string, IRowNode>>({});

  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({
    VISIBLE_ABOVE_PX_ON_SCROLL_DOWN,
  });
  const { t } = useTranslation();
  const { checkboxColumnType, matchLongColumnType, salesPreviousDaysType, spendPreviousDaysType } = useColumnTypes();
  const { metricFieldWithChangePercentage, dataGroupColumnType } = useColumnTypes();

  const { toastWarnWithSetMessages } = useHelperComponents();

  const { getCampaignAdTypeCellRendererParams, metricsDataComparator, metricsDataDeltaComparator, metricsDataDeltaPercentageComparator } =
    useColDefsFunctions();
  const { getNewBidValue_byCurrentProfileMarketplaceLimits } = useBidLimits();

  // Additional data sources
  const { metricColumnAggregates, isMetricsLoading, onGridReadyForMetricColumnAggregates } = useSearchTermsMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
  });

  const { comparisonUnit: contextComparisonUnit, sortByUnit: contextSortByUnit } = useSearchTermsContext();
  const { onGridReadyForComparisonMissing } = useComparisonMissing({
    gridApiRef,
    gridContextRef,
    isComparisonDataMissing,
  });
  const { comparisonUnit } = useComparisonUnitColumnData<SearchTermModel>({
    gridApiRef,
    gridContextRef,
    comparisonUnit: contextComparisonUnit,
  });

  // Formatters
  const { formatCurrency } = useFormatting();

  const {
    enabledPausedArchivedStateAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    targetsAggFunc,
    campaignNameAggFunc,
    adGroupsAggFunc,
    groupNameAggFunc,
    booleanAggFunc,
    metricsDataAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([
    DataGroupType.SEARCHTERM,
    DataGroupType.CAMPAIGN,
    DataGroupType.TARGET,
  ]);

  const activeMetricComperator = useComparator(gridApiRef, contextSortByUnit, contextComparisonUnit, {
    metricsDataComparator,
    metricsDataDeltaComparator,
    metricsDataDeltaPercentageComparator,
  });

  const columnDefs: ColDef<SearchTermModel>[] = useMemo(() => {
    if (isDataGroupsLoading) {
      return [];
    }

    const colDefs: AlColDef<SearchTermModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      // Don't show ID column as search term id is composite and meaningless to user
      {
        colId: ColumnId.SEARCH_TERM,
        headerName: 'Search Term',
        field: 'searchTerm',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.HARVESTED,
        headerName: 'Harvested',
        field: 'isHarvested',
        enableRowGroup: true,
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: BooleanCellRenderer,
        aggFunc: 'booleanAggFunc',
      },
      {
        colId: ColumnId.NEGATIVES,
        headerName: 'Negated',
        field: 'hasNegatives',
        aggFunc: 'booleanAggFunc',
        enableRowGroup: true,
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: BooleanCellRenderer,
        cellRendererParams: (params: ICellRendererParams<SearchTermModel>): IBooleanCellRendererParams => {
          const value: null | NegativeTypeShort[] | undefined = params.data?.negatives;

          const trueTooltip =
            value && Array.isArray(value) ? (
              <>
                {value.map((type, index) => (
                  <div key={index}>
                    {t(`search_terms_page.negative_type_short.${type}`)}
                    <br />
                  </div>
                ))}
              </>
            ) : (
              ''
            );

          return {
            ...params,
            trueTooltip: trueTooltip,
            falseTooltip: 'No negatives added for this search term',
          };
        },
      },
      {
        colId: ColumnId.WORD_COUNT,
        headerName: 'Word Count',
        field: 'searchTermWordCount',
        cellClass: 'flex flex-row items-center justify-end',
        type: 'numericColumn',
        hide: true,
        enableRowGroup: true,
      },
      {
        colId: ColumnId.TARGETING,
        headerName: 'Target',
        field: 'targeting',
        hide: false,
        enableRowGroup: true,
        aggFunc: 'targetsAggFunc',
        cellRenderer: TextCellRenderer,
      },
      {
        colId: ColumnId.ENTITY_TYPE,
        headerName: 'Target Type',
        field: 'entityType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`targeting_page.bidding_entity.${key}`),
            valueToColor: (key: string) => TargetEntityTypeColors[key as TargetEntityExtendedType] ?? TailwindColorVariant.GREEN,
            valueToTooltip: (key: string) => t(`optimizer_page.bidding_entity.${key}`),
          };
        },
      },
      {
        colId: ColumnId.ENTITY_ID,
        headerName: 'Entity ID',
        field: 'entityId',
        hide: true,
      },
      {
        colId: ColumnId.BID,
        headerName: 'Bid',
        field: 'bid',
        type: 'numericColumn',
        cellClass: 'flex flex-row items-center justify-end',
        hide: false,
        editable: true,
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
        valueParser: (params: ValueParserParams<SearchTermModel>) => {
          // Allow only numeric input with decimals
          const newValue = params.newValue;
          if (!newValue || /^\d*\.?\d*$/.test(newValue)) {
            const newValueFloat = parseFloat(newValue);

            const warnings = new Set<string>();
            const clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
              newValueFloat,
              params.data?.campaignAdType,
              params.data?.campaignIsVideo,
              warnings,
            );
            if (warnings.size > 0) {
              toastWarnWithSetMessages(warnings);
              return clampedValue;
            }

            return newValueFloat;
          }

          toast.error('Invalid new bid value');
          return params.oldValue; // Reject the change
        },
      },
      {
        colId: ColumnId.MATCH,
        headerName: 'Match Type',
        field: 'matchType',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        hide: true,
        width: 130,
        type: 'matchLongColumnType',
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroupName',
        enableRowGroup: true,
        aggFunc: 'adGroupsAggFunc',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        resizable: true,
        enableRowGroup: true,
        aggFunc: 'campaignNameAggFunc',
        cellRenderer: TextCellRenderer,
        width: 150,
        pinned: 'left',
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'campaignAdType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_STATE,
        headerName: 'Campaign State',
        field: 'campaignState',
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: EnabledPausedArchivedStateCellRenderer,
        aggFunc: 'enabledPausedArchivedStateAggFunc',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.STATE,
        headerName: 'Target State',
        field: 'targetState',
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: EnabledPausedArchivedStateCellRenderer,
        aggFunc: 'enabledPausedArchivedStateAggFunc',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.COST_TYPE,
        headerName: 'Cost Type',
        field: 'campaignCostType',
        width: 100,
        hide: true,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`cost_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Opt Group',
        field: 'groupName',
        width: 125,
        enableRowGroup: true,
        aggFunc: 'groupNameAggFunc',
        cellRenderer: TextCellRenderer,
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SAME_SKU_ORDERS,
        headerName: 'Same SKU Orders',
        field: 'sso',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SAME_SKU_SALES,
        headerName: 'Same SKU Sales',
        field: 'sss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
    ];

    dataGroups
      .filter((group) => group.type === DataGroupType.CAMPAIGN)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'bg-blue-400 bg-opacity-10 data-group-campaign data-group-base',
          toolPanelClass: 'data-group-base data-group-campaign',
          headerTooltip: t('campaign_data_group'),
          valueGetter: (params: ValueGetterParams<SearchTermModel>) => {
            const itemId = params.data?.campaignDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    dataGroups
      .filter((group) => group.type === DataGroupType.TARGET)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'bg-blue-400 bg-opacity-10 data-group-targeting data-group-base',
          toolPanelClass: 'data-group-base data-group-targeting',
          headerTooltip: t('target_data_group'),
          valueGetter: (params: ValueGetterParams<SearchTermModel>) => {
            const itemId = params.data?.targetingDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    dataGroups
      .filter((group) => group.type === DataGroupType.SEARCHTERM)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'bg-blue-400 bg-opacity-10 data-group-searchterm data-group-base',
          toolPanelClass: 'data-group-base data-group-searchterm',
          headerTooltip: t('searchterm_data_group'),
          valueGetter: (params: ValueGetterParams<SearchTermModel>) => {
            const itemId = params.data?.searchTermDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  // Needed to keep the selection up to date when data groups change on the searchTerm and data is refetched
  useEffect(() => {
    handlePendingUpdates();

    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedSearchTerms.length == 0) return;
    updateSelectedSearchTerms(gridApiRef.current);
  }, [rowData]);

  const updateSelectedSearchTerms = (api: GridApi<SearchTermModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[SearchTermsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedSearchTermsDTO = selectedRows.map(
        (row): SelectedSearchTerm => ({
          id: row.id,
          searchTerm: row.searchTerm,
          entityType: CreateNegativeModel.getEntityTypeByTargetingAndSearchTerm(row.searchTerm),
          campaignName: row.campaignName,
          adGroupName: row.adGroupName,
          campaignAdType: row.campaignAdType,
          dataGroupType: DataGroupType.SEARCHTERM,
          dataItemIds: row.searchTermDataItemIds,
          targeting: row.targeting,
          campaignId: row.campaignId,
          adGroupId: row.adGroupId,
          targetingType: row.targetingType,
        }),
      );
      setSelectedSearchTerms(selectedSearchTermsDTO);
    } catch (error) {
      console.error('Error updating selected search terms:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<SearchTermModel>) => {
    updateSelectedSearchTerms(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<SearchTermModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  function handlePendingUpdates() {
    if (!gridApiRef.current) {
      return;
    }

    // Collect unique row nodes directly since values are already IRowNode instances
    const uniqueRowNodes = Object.values(pendingBidUpdateCellLocationsRef.current);

    // Refresh cells for the collected row nodes and specified column
    gridApiRef.current.refreshCells({
      rowNodes: uniqueRowNodes,
      columns: [ColumnId.BID],
      force: true,
    });

    // Optionally clear the ref after processing if these updates are meant to be processed once
    pendingBidUpdateCellLocationsRef.current = {};
  }

  const customGridOptions: GridOptions<SearchTermModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultSearchTermsGridContext: SearchTermsGridContext = {
      metricColumnAggregates: metricColumnAggregates,
      comparisonUnit: comparisonUnit,
      isComparisonDataMissing,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultSearchTermsGridContext,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      //groupAllowUnbalanced: true, // Allows some rows to be ungrouped instead of being in auto group Blanks
      rowGroupPanelShow: 'always', // The place you can drag headers for row grouping
      groupDefaultExpanded: 0,
      autoGroupColumnDef: {
        pinned: 'left',
        cellRendererParams: {
          suppressCount: true, // Suppress the default (count)
        },
      },
      groupSelectsChildren: true,
      suppressAggFuncInHeader: true, // without this aggregated Impressions header would be func(Impressions)
      columnTypes: {
        metricFieldWithChangePercentage,
        dataGroupColumnType,
        checkboxColumnType,
        matchLongColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
      },
      getRowStyle: (params) => {
        // Add custom style to group rows
        if (params.node.group) {
          return {
            background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
          };
        }
      },
      // Defining all aggFuncs here not in colDefs (where using string) so to prevent "stateItem.aggFunc must be a string" warning (col state is stored as a simple JSON)
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        adGroupsAggFunc,
        groupNameAggFunc,
        booleanAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`SearchTermsTable: gridContextRef is undefined though it was just set`, 'info');
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isSearchTermsLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={searchTermsLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading || isMetricsLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default SearchTermsTable;
