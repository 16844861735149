import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import MetricsRadioList from '@/components/metrics/MetricsRadioList';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import TargetingFilterListEditor from '@/modules/campaigns/components/TargetingFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useDashboardQueries } from '@/modules/dashboards/hooks/useDashboardQueries';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import { PieGraphWidgetConfiguration } from './PieGraphWidgetConfiguration';
import { MetricField } from '@/components/metrics/types/MetricField';

interface PieGraphWidgetConfigurationFormProps {
  configuration: PieGraphWidgetConfiguration;
  id: string;
}

const PieGraphWidgetConfigurationForm: FunctionComponent<PieGraphWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { refetchForWidgetWithId } = useDashboardQueries();
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const { updateWidgetConfiguration } = useDashboardContextValue((context) => ({
    updateWidgetConfiguration: context.updateWidgetConfiguration,
  }));

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateWidgetConfiguration<PieGraphWidgetConfiguration>(id, {
      title: e.currentTarget.value,
    });
  };

  const handleEntityTypeChange = async (entityType: EntityType | '') => {
    if (entityType === '') return;
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        entityType: entityType,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleCampaignGroupByChange = async (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        campaignGroupByValue: groupByOption.value,
        campaignGroupByCategory: groupByOption.category,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleTargetsGroupByChange = async (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        targetsGroupByValue: groupByOption.value,
        targetsGroupByCategory: groupByOption.category,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleSelectedMetricChange = async (selectedMetric: MetricField) => {
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        selectedMetric: selectedMetric,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const onCampaignFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        campaignFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const onTargetingFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<PieGraphWidgetConfiguration>(
      id,
      {
        targetsFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  return (
    <>
      <TextField label="Name" value={configuration.title} onChange={handleTitleChange} />
      <EntityTypeSelect
        value={configuration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
      />

      {configuration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          value={configuration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {configuration.entityType === EntityType.KEYWORD && (
        <GroupBySelect value={configuration.targetsGroupByValue} onChange={handleTargetsGroupByChange} dataGroupType={DataGroupType.TARGET} />
      )}
      <div>
        <div className="text-sm">Metric</div>
        <Typography variant="caption"> Select the metric to display</Typography>
        <MetricsRadioList value={configuration.selectedMetric} onChange={handleSelectedMetricChange} />
      </div>

      {(configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE) && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <CampaignFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.campaignFilters
                ? configuration.campaignFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onCampaignFiltersChanged}
          />
        </>
      )}

      {configuration.entityType === EntityType.KEYWORD && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <TargetingFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.targetsFilters
                ? configuration.targetsFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onTargetingFiltersChanged}
          />
        </>
      )}
    </>
  );
};

export default PieGraphWidgetConfigurationForm;
