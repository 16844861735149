import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import RGL, { Layout, WidthProvider, ItemCallback } from 'react-grid-layout';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidget } from '../../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import DashboardWidget from '../dashboard-widget/DashboardWidget';
import { v4 } from 'uuid';
import { WIDGET_WIDTH_IN_PX, WIDGET_HEIGHT_IN_PX, DASHBOARD_COLUMN_COUNT, DASHBOARD_ROW_COUNT } from '../../configuration/configuration';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';

const ReactGridLayout = WidthProvider(RGL);

interface DashboardProps extends RGL.ReactGridLayoutProps, RGL.WidthProviderProps {}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const { widgets, updateWidgetLayout, widgetPlaceholderHeight, widgetPlaceholderWidth, isEditingDashboard, addWidget, dashboardVersion } =
    useDashboardContextValue((context) => ({
      widgets: context.widgets,
      updateWidgetLayout: context.updateWidgetLayout,
      widgetPlaceholderHeight: context.widgetPlaceholderHeight,
      widgetPlaceholderWidth: context.widgetPlaceholderWidth,
      isEditingDashboard: context.isEditingDashboard,
      setDragginWidgetId: context.setDragginWidgetId,
      addWidget: context.addWidget,
      dates: context.dates,
      comparisonDates: context.comparisonDates,
      dashboardVersion: context.dashboardVersion,
    }));

  const { refetchForWidgetWithId, refetchDashboardData } = useDashboardQueries();
  const [draggedItem, setDraggedItem] = useState<Layout | null>(null);

  const widgetElements = useMemo(() => {
    return widgets.map((x) => {
      return <DashboardWidget key={x.id} widgetid={x.id} data-grid={x.layout} />;
    });
  }, [widgets]);

  const [dragClass, setDragClass] = useState('');
  const onLayoutChange = useCallback<ItemCallback>(
    (_, oldItem, newItem) => {
      const widgetToUpdateLayout = widgets.find((widget) => widget.layout.i === oldItem.i || widget.id === oldItem.i);
      if (!widgetToUpdateLayout) {
        return;
      }
      updateWidgetLayout(widgetToUpdateLayout.id, newItem, true);
    },
    [widgets],
  );

  const onDrop = useCallback(
    async (_: Layout[], item: Layout, e: DragEvent) => {
      const raw = e.dataTransfer?.getData('droppableWidget');

      if (!raw) {
        return;
      }

      const droppableWidget = JSON.parse(raw) as IDashboardWidget<IDashboardWidgetBaseConfiguration>;

      droppableWidget.layout.x = item.x;
      droppableWidget.layout.y = item.y;
      droppableWidget.layout.isDraggable = undefined;
      droppableWidget.id = v4();
      droppableWidget.layout.i = v4();

      await addWidget(droppableWidget, true);
      refetchForWidgetWithId(droppableWidget.id);
    },
    [addWidget, refetchForWidgetWithId, widgets],
  );

  // Based on the widget, determine dashboard column and row count, always add 2 to the max position of the most down and right positioned widget
  let maxColumn = widgets.reduce((acc, widget) => {
    return Math.max(acc, widget.layout.x + widget.layout.w + 3);
  }, DASHBOARD_COLUMN_COUNT);

  if (draggedItem) {
    maxColumn = Math.max(maxColumn, draggedItem.x + draggedItem.w + 3);
  }

  let maxRow = widgets.reduce((acc, widget) => {
    return Math.max(acc, widget.layout.y + widget.layout.h + 3);
  }, DASHBOARD_ROW_COUNT);

  if (draggedItem) {
    maxRow = Math.max(maxRow, draggedItem.y + draggedItem.h + 3);
  }

  // Memoize styles
  const gridStyle = useMemo(
    () => ({
      minWidth: maxColumn * WIDGET_WIDTH_IN_PX + maxColumn * 10,
      minHeight: maxRow * WIDGET_HEIGHT_IN_PX + maxRow * 10,
      width: maxColumn * WIDGET_WIDTH_IN_PX + maxColumn * 10,
      height: maxRow * WIDGET_HEIGHT_IN_PX + maxRow * 10,
    }),
    [maxRow, maxColumn],
  );

  useEffect(() => {
    if (dashboardVersion) {
      refetchDashboardData();
    }
  }, [dashboardVersion]);

  return (
    <div style={gridStyle}>
      <ReactGridLayout
        {...props}
        className={`dashboard-grid  ${dragClass} `}
        cols={maxColumn}
        rowHeight={WIDGET_HEIGHT_IN_PX}
        maxRows={maxRow}
        compactType={null}
        width={maxColumn * WIDGET_WIDTH_IN_PX}
        useCSSTransforms={true}
        style={gridStyle}
        measureBeforeMount={false}
        preventCollision={true}
        onDrop={onDrop}
        // Override draggable handle because toggling draggable on either the grid or the grid items, does not work
        draggableHandle=".draggable"
        onDrag={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent) => {
          setDraggedItem(newItem);
          if (event.movementX > 0 && dragClass !== 'dragging-right') {
            setDragClass('dragging-right');
          } else if (event.movementX < 0 && dragClass !== 'dragging-left') {
            setDragClass('dragging-left');
          }
        }}
        onDropDragOver={() => {
          return { h: widgetPlaceholderHeight, w: widgetPlaceholderWidth };
        }}
        onDragStop={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
          onLayoutChange(layout, oldItem, newItem, placeholder, event, element);
          setDraggedItem(null);
        }}
        onResizeStop={onLayoutChange}
        isDroppable
      >
        {widgetElements}
      </ReactGridLayout>
    </div>
  );
};

export default Dashboard;
