import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import EditableCellRenderer, { IEditableCellRendererParams } from '@/components/grid/cells/EditableCellRenderer';
import { TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useComparisonUnitColumnData from '@/components/grid/hooks/useComparisonUnitColumnData';
import { ExpandedGridContext } from '@/components/grid/types';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { campaignService, invalidateProfile_campaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { CampaignGroupMetricsModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupMetricsModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import useCampaignGroupMetricColumnAggregates from '@/modules/optimizer/hooks/useCampaignGroupMetricColumnAggregates';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import * as Sentry from '@sentry/react';
import { QueryObserverResult, RefetchOptions, useQueryClient } from '@tanstack/react-query';
import { CellValueChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isNumber, round } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { generateCampaignGroupDefaultColumnState } from '../../../profiles/configuration/campaign-group.default-column-state';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import { useCampaignGroupsContext } from '../../contexts/CampaignGroupsContext';

interface CampaignGroupsTableProps {
  withMetricsResponse: CampaignGroupsWithMetrics | undefined;
  isLoading: boolean;
  onGridReadyCallback: (params: GridReadyEvent) => void;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<CampaignGroupsWithMetrics | undefined, Error>>;
  noTopBorderRadius?: boolean;
}

const CampaignGroupsTable: FunctionComponent<CampaignGroupsTableProps> = ({
  withMetricsResponse,
  isLoading,
  refetch,
  noTopBorderRadius,
  onGridReadyCallback,
}) => {
  const NO_GROUPS_ASSIGNED_GROUP_ID = 0;

  const rowData = withMetricsResponse?.campaignGroups ?? [];
  const isComparisonDataMissing = withMetricsResponse?.isComparisonDataMissing ?? false;

  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const { checkboxColumnType, metricFieldWithChangePercentage } = useColumnTypes();
  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();
  const { filters, comparisonUnit: contextComparisonUnit, setSelectedCampaignGroups, gridApiRef } = useCampaignGroupsContext();

  const queryClient = useQueryClient();

  const { stringToCountAggFunc, metricsDataAggFunc } = useAggregators();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_GROUP_TABLE_COLUMN_STATE,
    generateCampaignGroupDefaultColumnState(),
  );

  const gridContextRef = useRef<ExpandedGridContext>();

  const { onGridReadyForComparisonMissing } = useComparisonMissing({
    gridApiRef,
    gridContextRef,
    isComparisonDataMissing: isComparisonDataMissing,
  });
  const { comparisonUnit } = useComparisonUnitColumnData<CampaignGroupModel>({
    gridApiRef,
    gridContextRef,
    comparisonUnit: contextComparisonUnit,
  });

  // METRIC AGGREGATES TO HEADERS
  const { metricColumnAggregates, onGridReadyForMetricColumnAggregates } = useCampaignGroupMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    filters,
  });

  // UPDATE GROUP
  const updateGroup = async (groups: CampaignGroupModel[]) => {
    const response = await campaignService.updateGroups(groups);

    if (response.isSuccess === false) {
      toast.error(response.message);
      return;
    }

    toast.success(`Campaign group '${groups[0].name}' updated successfully`);

    refetch();

    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    // No need to update campaign
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<CampaignGroupMetricsModel>) => {
    updateGroup([CampaignGroupModel.fromCampaignGroupMetricsModel(event.data)]);
  }, []);

  const columnDefs: ColDefOrGroup<CampaignGroupMetricsModel>[] = useMemo(() => {
    const colDefs: ColDefOrGroup<CampaignGroupMetricsModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Type',
        field: 'adType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Name',
        field: 'name',
        singleClickEdit: true,
        width: 200,
        editable: (params) => params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
        cellRenderer: EditableCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignGroupMetricsModel>): IEditableCellRendererParams => {
          return {
            isEditable: params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
            hideTooltip: params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID,
          };
        },
        valueParser: (params) => {
          const newName = params.newValue.trim();

          const currentRowId = params.data.id;
          let isNameUnique = true;

          // Check if any row has the same name, excluding the current row
          params.api.forEachNode((node) => {
            if (node.data && node.data.id !== currentRowId && node.data.name.toLowerCase() === newName.toLowerCase()) {
              isNameUnique = false;
            }
          });

          if (!isNameUnique) {
            toast.error('A group with that name already exists');
            return params.oldValue; // Reject the change
          }

          return newName; // Accept the change
        },
      },
      {
        colId: ColumnId.GROUP_TACOS,
        headerName: 'Target ACOS',
        field: 'tacos',
        editable: (params) => params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
        singleClickEdit: true,
        width: 130,
        cellClass: (params) => {
          if (params.value == 0 || !params.value || params.value == '') {
            return 'text-gray-400';
          } else {
            return '';
          }
        },
        cellRenderer: EditableCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignGroupMetricsModel>): IEditableCellRendererParams => {
          return {
            isEditable: params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
            hideTooltip: params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID,
          };
        },
        valueFormatter: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';
          if (params.value == null) return 'Not Set';
          // valueGetter already multiples by 100, so only need to show %
          return params.value ? params.value + '%' : 'Not Set';
        },
        // Get the value for editing, as a whole number percentage
        valueGetter: (params: ValueGetterParams<CampaignGroupMetricsModel>) => {
          // Under the hood the value is in fraction, but for display and edit display we want to show %
          return params.data && isNumber(params.data.tacos) ? round(params.data.tacos * 100) : '';
        },
        // Parse the edited percentage back to a fraction
        valueParser: (params) => {
          try {
            if (params.newValue == '') {
              return '';
            }

            const newValue = parseFloat(params.newValue) / 100;
            if (newValue > 0) {
              return newValue;
            }
          } catch (e) {
            console.log(e);
            // Ignore
          }

          toast.error('Invalid TACOS value entered');
          console.log('Invalid TACOS value entered: ', params.newValue);

          // Revert to old when invalid
          return params.oldValue;
        },
        cellEditorParams: {
          step: '1', // Increment values by 1 on up/down arrows
        },
      },
      {
        colId: ColumnId.GROUP_PRESET,
        headerName: 'Prioritization',
        field: 'preset',
        cellEditor: 'agSelectCellEditor',
        width: 145,
        editable: (params) => params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
        singleClickEdit: true,
        cellRenderer: EditableCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignGroupMetricsModel>): IEditableCellRendererParams => {
          return {
            isEditable: params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
            hideTooltip: params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID,
          };
        },
        cellClass: (params) => {
          if (params.value == OptimizationPreset.NOT_SET) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },
        cellEditorParams: {
          values: [
            OptimizationPreset.NOT_SET,
            OptimizationPreset.BALANCED,
            OptimizationPreset.INCREASE_SALES,
            OptimizationPreset.REDUCE_ACOS,
          ],
        },
        valueFormatter: (params) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';
          return t(`optimizer_page.optimization_presets.${params.value}`);
        },
      },
      {
        colId: ColumnId.CAMPAIGN_GROUP_COUNT,
        headerName: '# of Campaigns',
        field: 'totalCampaigns',
        type: 'numericColumn',
        width: 145,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
      },
    ];
    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const defaultCampaignGridContext: ExpandedGridContext = {
    metricColumnAggregates,
    comparisonUnit,
    isComparisonDataMissing,
  };

  const customGridOptions: GridOptions<CampaignGroupMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.id.toString(),
    context: defaultCampaignGridContext,
    isRowSelectable: (params) => params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
    onSelectionChanged: (params) => {
      if (params.api && !params.api.isDestroyed()) {
        setSelectedCampaignGroups(params.api.getSelectedRows());
      }
    },
    columnTypes: { checkboxColumnType, metricFieldWithChangePercentage },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    aggFuncs: {
      stringToCountAggFunc,
      metricsDataAggFunc,
    },
  };

  const onGridReady = (params: GridReadyEvent<CampaignGroupModel>) => {
    setColumnStateGridApi(params.api);

    gridApiRef.current = params.api;

    // Context
    gridContextRef.current = params.context;
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      onGridReadyCallback(params);
      gridApiRef.current.refreshHeader();
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`CampaignGroupsTable: gridContextRef is undefined though it was just set`, 'info');
    }
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isLoading}
        onGridReadyCallback={onGridReady}
        onCellValueChanged={onCellValueChanged}
        noTopBorderRadius={noTopBorderRadius}
      />
    </div>
  );
};

export default CampaignGroupsTable;
