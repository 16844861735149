import { MetricData } from '@/components/metrics/types/MetricData';
import useFormatting from '@/hooks/useFormatting';
import { SentimentDirection, getColorClassBySentimentCurrentPrevious } from '@/types/colors.enum';
import { Tooltip, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { ComparisonUnit } from '../types';

interface IChangePercentageCellRendererParams extends ICellRendererParams<unknown, MetricData> {
  currentValueFormatter?: (number: number) => string;
  sentimentDirection: SentimentDirection;
  comparisonUnit?: ComparisonUnit;
  isComparisonDataMissing?: boolean;
}

const ChangePercentageCellRenderer: FunctionComponent<IChangePercentageCellRendererParams> = ({
  value,
  currentValueFormatter,
  sentimentDirection,
  comparisonUnit = 'percent',
  isComparisonDataMissing = false,
}) => {
  if (isNil(value) || isNil(value[0]) || isNil(value[1])) return null;

  const [current, previous] = value;

  const formatValue = (value: number) => (currentValueFormatter ? currentValueFormatter(value) : value);

  const formattedCurrentValue = formatValue(current);
  if (comparisonUnit === 'hidden') {
    return (
      <div className="text-right">
        <Typography variant="body2">{formattedCurrentValue}</Typography>
      </div>
    );
  }

  // Not 'hidden' so either 'percent' or 'nominal'
  const isComparisonInPercent = comparisonUnit === 'percent';
  const formattedPreviousValue = formatValue(previous);

  const { formatChangeInUnsignedPercentWithArrow, getDirectionArrow } = useFormatting();
  const formattedChange = formatChangeInUnsignedPercentWithArrow(current, previous);
  const colorClass = getColorClassBySentimentCurrentPrevious(sentimentDirection, current, previous);

  const difference = current - previous;
  const formattedDifference = formatValue(difference) + ' ' + getDirectionArrow(difference);

  const comparisonPeriodTooltip = isComparisonDataMissing
    ? 'Comparison period data is incomplete and not shown'
    : `Comparison period: ${formattedPreviousValue} (${isComparisonInPercent ? formattedDifference : formattedChange})`;
  return (
    <div className="text-right">
      <Typography variant="body2">{formattedCurrentValue}</Typography>
      <Tooltip title={comparisonPeriodTooltip}>
        <Typography variant="body2" className={`${colorClass} text-xs`}>
          {isComparisonDataMissing ? '–' : isComparisonInPercent ? formattedChange : formattedDifference}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default ChangePercentageCellRenderer;
