/* eslint-disable react/prop-types */
import { Card } from '@mui/material';
import { forwardRef, FunctionComponent, PropsWithChildren } from 'react';
import { useDashboardContextValue, useWidgetById } from '../../contexts/DashboardContextProvider';
import { IDashboardWidget } from '../../types/IDashboardWidget';
import { DashboardWidgetType } from '../../types/DashboardWidgetType';
import LineGraphWidget from '../widgets/line-graph-widget/LineGraphWidget';
import MetricWidget from '../widgets/metric-widget/MetricWidget';
import { MetricWidgetConfiguration } from '../widgets/metric-widget/MetricWidgetConfiguration';
import { LineGraphWidgetConfiguration } from '../widgets/line-graph-widget/LineGraphWidgetConfiguration';
import TableWidget from '../widgets/table-widget/TableWidget';
import { TableWidgetConfiguration } from '../widgets/table-widget/TableWidgetConfiguration';
import BarGraphWidget from '../widgets/bar-graph-widget/BarGraphWidget';
import { BarGraphWidgetConfiguration } from '../widgets/bar-graph-widget/BarGraphWidgetConfiguration';
import PieGraphWidget from '../widgets/pie-graph-widget/PieGraphWidget';
import { PieGraphWidgetConfiguration } from '../widgets/pie-graph-widget/PieGraphWidgetConfiguration';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { MetricWidgetDataDTO } from '../../api/dashboard/data/dashboard-data.contracts';

interface DashboardWidgetProps extends PropsWithChildren {
  widgetid: string; //IDashboardWidget<IDashboardWidgetBaseConfiguration>;
}

const DashboardWidget: FunctionComponent<DashboardWidgetProps> = forwardRef<HTMLDivElement, DashboardWidgetProps>((props, ref) => {
  const { widgetid: widgetId } = props;

  const widget = useWidgetById(widgetId);
  const { getDataForWidgetWithId, widgetIdsRequestingData } = useDashboardQueries();
  const widgetData = getDataForWidgetWithId(widgetId);

  const isFetchingDataForWidget = widgetIdsRequestingData.has(widgetId);

  let widgetComponent;
  switch (widget?.type) {
    case DashboardWidgetType.LineGraphWidget:
      widgetComponent = (
        <LineGraphWidget
          {...(widget as IDashboardWidget<LineGraphWidgetConfiguration>)}
          data={widgetData}
          isFetchingDataForWidget={isFetchingDataForWidget}
          configurationId={widget.configuration.id}
        />
      );
      break;
    case DashboardWidgetType.MetricWidget:
      widgetComponent = (
        <MetricWidget
          {...(widget as IDashboardWidget<MetricWidgetConfiguration>)}
          data={widgetData as MetricWidgetDataDTO}
          isFetchingDataForWidget={isFetchingDataForWidget}
          configurationId={widget.configuration.id}
        />
      );
      break;
    case DashboardWidgetType.TableWidget:
      widgetComponent = (
        <TableWidget
          {...(widget as IDashboardWidget<TableWidgetConfiguration>)}
          data={widgetData}
          isFetchingDataForWidget={isFetchingDataForWidget}
          configurationId={widget.configuration.id}
        />
      );
      break;
    case DashboardWidgetType.BarGraphWidget:
      widgetComponent = (
        <BarGraphWidget
          {...(widget as IDashboardWidget<BarGraphWidgetConfiguration>)}
          data={widgetData}
          isFetchingDataForWidget={isFetchingDataForWidget}
          configurationId={widget.configuration.id}
        />
      );
      break;
    case DashboardWidgetType.PieGraphWidget:
      widgetComponent = (
        <PieGraphWidget
          {...(widget as IDashboardWidget<PieGraphWidgetConfiguration>)}
          data={widgetData}
          isFetchingDataForWidget={isFetchingDataForWidget}
          configurationId={widget.configuration.id}
        />
      );
      break;

    default:
      widgetComponent = <div>Unknown widget type</div>;
      break;
  }

  const { isEditingDashboard, editingWidgetId } = useDashboardContextValue((context) => ({
    isEditingDashboard: context.isEditingDashboard,
    editingWidgetId: context.editingWidgetId,
    dashboard: context.dashboard,
  }));

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div ref={ref} {...props}>
      <Card
        variant="outlined"
        className={`dashboard-widget-tile h-full w-full overflow-visible rounded-lg bg-white  dark:bg-gray-850 ${
          isEditingDashboard && editingWidgetId !== widgetId ? 'draggable ' : 'disabled'
        }
          ${editingWidgetId && editingWidgetId !== widgetId ? 'opacity-40' : ''}
          ${editingWidgetId && editingWidgetId === widgetId ? 'border border-primary-600' : ''}
        `}
      >
        {widgetData ? (
          widgetComponent
        ) : (
          <>
            <LoadingBlock />
          </>
        )}

        {/* Only add resizable component when editing dashboard, as dynamically toggling the isResizable prop is not working */}
        {isEditingDashboard && (
          <div
            className="z-20 w-full h-full absolute inset-0 cursor-move bg-gray-200 opacity-40 rounded-lg"
            // Disable all mouse events, so widgets won't get activated when clicking on the background
            onMouseEnter={stopPropagation}
            onMouseLeave={stopPropagation}
            onMouseUp={stopPropagation}
            onClick={stopPropagation}
          >
            {props.children}
          </div>
        )}
      </Card>
    </div>
  );
});

DashboardWidget.displayName = 'DashboardWidget';
export default DashboardWidget;
