import { FunctionComponent, useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly'; // Import the Calendly widget
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';
import { LoadingButton } from '@mui/lab';
import { useUserContext, userService } from '@/modules/users';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@/router/router-paths';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isEmpty, isNil } from 'lodash-es';
import { HookEvent } from '@/modules/users/api/users/users.service';
import { SpendType } from '../types/wizard_step_state.enum';

export const MIN_AVG_SPEND_LENGTH = 1;

const RegistrationStepBookCallPage: FunctionComponent = () => {
  const { state, teamId: registrationTeamID, userId: registrationUserID, averageAdSpend } = useRegistrationContext();
  const { user, setUser } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && registrationUserID) {
      fetchAndSetUser(registrationUserID);
    } else if (user && registrationTeamID) {
      setActiveTeam({ teamId: registrationTeamID });
    }
  }, [user, registrationTeamID]);

  async function fetchAndSetUser(userId: number) {
    const userResponse = await userService.getUserById(userId);
    if (userResponse.isSuccess) {
      setUser(userResponse.payload);
    }
  }

  const [isBookingCompleted, setIsBookingCompleted] = useState(false);

  const name = user?.name ?? '' + (user?.surname ? ' ' + user?.surname : '');
  const email = user?.email ?? state.user?.email ?? '';

  function onContinueClicked() {
    try {
      navigate(Routes.REGISTER_CONNECT_AMAZON);
      return;
    } catch (error) {
      console.log(error);
    }
  }

  function isCalendlyEvent(e: MessageEvent) {
    return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.event_scheduled') === 0;
  }

  function catchCalendlyEvent(e: MessageEvent) {
    if (isCalendlyEvent(e)) {
      userService.sendNewUserDataToHook({
        event: HookEvent.SUCCESSFULLY_BOOKED_MEETING,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        name,
        email,
      });

      setIsBookingCompleted(true);
    }
  }

  useEffect(() => {
    window.addEventListener('message', catchCalendlyEvent);
    return () => {
      window.removeEventListener('message', catchCalendlyEvent);
    };
  }, []);

  const isContinueButtonDisabled = !isBookingCompleted && !isNil(averageAdSpend) && !isEmpty(averageAdSpend);

  // const calendlyUrl = 'https://calendly.com/romet-adlabs'
  let calendlyUrl = 'https://calendly.com/stephen-adlabs/adlabs-onboarding';
  if (averageAdSpend == SpendType.TenToTwentyThousand) {
    calendlyUrl = 'https://calendly.com/adlabs-onboarding/30';
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center h-[85vh] w-[60vw]">
        {!isEmpty(name) && !isEmpty(email) && !isNil(averageAdSpend) && !isEmpty(averageAdSpend) && (
          <InlineWidget
            url={calendlyUrl}
            prefill={{
              name,
              email,
            }}
            styles={{ height: '100%', width: '100%' }} // Customize styles as needed
          />
        )}

        <LoadingButton
          variant="contained"
          size="large"
          color="primary"
          className="mt-6"
          onClick={onContinueClicked}
          disabled={isContinueButtonDisabled}
        >
          {isContinueButtonDisabled ? 'Book a Call Before You can Go to AdLabs' : 'Take Me to AdLabs'}
        </LoadingButton>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationStepBookCallPage;
