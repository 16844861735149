import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { CurrencyRatesModel } from '../models/CurrencyRatesModel';
import { CurrencyRatesDTO } from './currency.contracts';

const CURRENCY_QUERY_SHARED = 'currency';

export class CurrencyService {
  public basePath = 'currency';

  createConversionRatesQueryKey = (baseCurrencyCode: CurrencyCode) => [CURRENCY_QUERY_SHARED, baseCurrencyCode];
  async getConversionRates(baseCurrencyCode: CurrencyCode): Promise<ApiResponse<CurrencyRatesModel>> {
    const requestData = {
      base_currency: baseCurrencyCode,
    };
    const applicationResponse = await apiClient.get<CurrencyRatesDTO>(`${this.basePath}/conversion-rates`, requestData);
    return applicationResponse.processPayload(CurrencyRatesModel.fromResponse);
  }
}

export const currencyService = new CurrencyService();
