import { Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

export function DashboardWidgetConfigurationDrawerDeleteButton() {
  const { widgetId } = useContext(DashboardWidgetContext);

  const removeWidget = useDashboardContextValue((context) => context.removeWidget);
  const setEditingWidgetId = useDashboardContextValue((context) => context.setEditingWidgetId);

  function onDeleteWidgetClicked() {
    if (widgetId) {
      removeWidget(widgetId);
      setEditingWidgetId('');
    }
  }

  return (
    <Button color="error" className="w-full" size={'large'} startIcon={<Delete></Delete>} onClick={onDeleteWidgetClicked}>
      Delete Widget
    </Button>
  );
}
