import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Autocomplete, Box, FilterOptionsState, IconButton, TextField, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { FilterBetween } from './filters/FilterBetween';
import { FilterDateSelect } from './filters/FilterDateSelect';
import { FilterMultiSelect } from './filters/FilterMultiSelect';
import { FilterSelect } from './filters/FilterSelect';
import { FilterStringComparison } from './filters/FilterStringComparison';
import { FilterStringExactMatch } from './filters/FilterStringExactMatch';
import { AlFilterModel } from './models/AlFilterModel';
import { FilterKey, FilterType } from './types/FilterKey';

interface FilterRowProps {
  filterDefaultValue: AlFilterModel;
  unUsedFilters: AlFilterModel[];
  updateFilterByFilterKey: (oldKey: FilterKey, filter: AlFilterModel | null) => void;
}

export const FilterRow: FunctionComponent<FilterRowProps> = ({ filterDefaultValue, unUsedFilters, updateFilterByFilterKey }) => {
  const [filter, setFilter] = useState<AlFilterModel>(filterDefaultValue);

  const handleChange = (newValue: AlFilterModel) => {
    const newFilter = cloneDeep(newValue);
    updateFilterByFilterKey(filter.key, newFilter); // changes pending filters
    setFilter(newFilter); // changes local value
  };

  const onDeleteFilterClicked = () => {
    updateFilterByFilterKey(filter.key, null);
  };

  const customFilter = (options: AlFilterModel[], state: FilterOptionsState<AlFilterModel>): AlFilterModel[] => {
    return options.filter((option) => option.longName.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  return (
    <>
      {filter && filter.isFilterBuilderFilter && (
        <div className="flex flex-row  gap-2">
          <Autocomplete
            className="w-80"
            disableClearable
            onChange={(event: SyntheticEvent<Element, Event>, newValue: AlFilterModel) => {
              handleChange(newValue);
            }}
            options={[filterDefaultValue, ...unUsedFilters]}
            id="filter-select"
            getOptionLabel={(filter) => filter.name} // Do not truncate for accurate searching
            filterOptions={customFilter}
            renderOption={(props, filter) => (
              <Box component="li" {...props} key={filter.key} sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                {filter.longName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter"
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            )}
            defaultValue={filterDefaultValue}
            isOptionEqualToValue={(option, value) => option.name === value.name}
          />
          {filter.type == FilterType.DATE_SELECT && <FilterDateSelect filter={filter} isOperatorDisabled={filter.isOperatorDisabled} />}
          {filter.type == FilterType.BETWEEN && <FilterBetween filter={filter} isOperatorDisabled={filter.isOperatorDisabled} />}
          {filter.type == FilterType.SELECT && <FilterSelect filter={filter} label={filter.name} />}
          {filter.type == FilterType.MULTI_SELECT && <FilterMultiSelect filter={filter} label={filter.name} />}
          {filter.type == FilterType.STRING_COMPARISON && <FilterStringComparison filter={filter} label={filter.name} />}
          {filter.type == FilterType.STRING_EXACT_MATCH && <FilterStringExactMatch filter={filter} label={filter.name} />}
          <div className="flex-grow"></div>
          <div className="mt-2">
            <IconButton className="text-slate-500 " aria-label="delete" onClick={onDeleteFilterClicked}>
              <Tooltip title="Remove this filter">
                <HighlightOffRoundedIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
