import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultSearchTermsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { getErrorMessage } from '@/modules/application/utils';
import {
  invalidateProfile_negativeTargetingQueryKeys,
  negativeTargetingService,
} from '@/modules/negative-targets/api/negative-targets-service';
import { CreateNegativesParamsModal } from '@/modules/negative-targets/components/add-negatives/CreateNegativesParamsModal';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CreateNegativesPreviewModal } from '../../negative-targets/components/add-negatives/CreateNegativesPreviewModal';
import { CreateNegativeModel } from '../../negative-targets/models/CreateNegativeModel';
import {
  createSearchTermsWithTimelineQueryKey,
  invalidateProfile_searchTermsWithTimelineQueryKeys,
  searchTermsService,
} from '../api/search-terms-service';
import SearchTermsFilterBar from '../components/SearchTermsFilterBar';
import SearchTermsMetrics from '../components/SearchTermsMetrics';
import SearchTermsSelectionActionsBar from '../components/SearchTermsSelectionActionsBar';
import SearchTermsTimelineChart from '../components/SearchTermsTimelineChart';
import { keywordHarvestingService } from '../components/keyword-harvesting/api/keyword-harvesting-service';
import { KeywordHarvestingParamsModal } from '../components/keyword-harvesting/components/KeywordHarvestingParamsModal';
import { KeywordHarvestingPreviewModal } from '../components/keyword-harvesting/components/KeywordHarvestingPreviewModal';
import { HarvestingParams, KeywordHarvestingModel } from '../components/keyword-harvesting/models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../components/keyword-harvesting/models/KeywordHarvestingPreviewDataRow';
import SearchTermsTable from '../components/search-terms-table/SearchTermsTable';
import { SEARCH_TERMS_CONTEXT_KEY, SearchTermsProvider } from '../contexts/SearchTermsContext';
import { CreateNegativesParams, SelectedSearchTerm } from '../models/SearchTermModel';

let isPaywallDisplayedBefore = false;

const SearchTermsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({
    message: 'Upgrade to Pro to Access the Search Terms Tool Suite',
    videoUrl: VideoUrl.KW_HARVESTING,
  });
  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(SEARCH_TERMS_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultSearchTermsFilters());
  });

  const [selectedSearchTerms, setSelectedSearchTerms] = useState<SelectedSearchTerm[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const searchTermsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedSearchTerms([]);
    if (searchTermsTableGridApiRef.current && !searchTermsTableGridApiRef.current.isDestroyed()) {
      searchTermsTableGridApiRef.current.deselectAll();
    }
  }

  const searchTermsWithTimelineQueryKey = createSearchTermsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: searchTermsWithTimeline,
    isLoading: isSearchTermsRowDataLoading,
    isError: isSearchTermsLoadingError,
    error: searchTermsLoadingError,
  } = useQuery({
    queryKey: searchTermsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await searchTermsService.getSearchTermsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading search terms ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  function onSearchTermsTableGridReady(params: GridReadyEvent) {
    searchTermsTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseSearchTermsPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.SEARCH_TERMS_TUTORIAL_DISPLAY_COUNT,
  );

  // ADD NEGATIVES PARAMS MODAL
  const [isCreateNegativesParamsModalOpen, setIsCreateNegativesParamsModalOpen] = useState(false);

  const onCreateNegativesParamsClicked = () => {
    setIsCreateNegativesParamsModalOpen(true);
  };

  const onCreateNegativesParamsConfirm = (createNegativesParams: CreateNegativesParams) => {
    setIsCreateNegativesParamsModalOpen(false);

    setNegativeKeywordsToAdd(CreateNegativeModel.fromSelectedSearchTermArrayAndParams(selectedSearchTerms, createNegativesParams));

    setIsCreateNegativesPreviewModalOpen(true);
  };

  // ADD NEGATIVES MODAL
  const [isCreateNegativesPreviewModalOpen, setIsCreateNegativesPreviewModalOpen] = useState(false);
  const [negativeKeywordsToAdd, setNegativeKeywordsToAdd] = useState<CreateNegativeModel[]>([]);

  const [isSendingNegativeKeywords, setIsSendingNegativeKeywords] = useState(false);
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [applyResponse, setApplyResponse] = useState<UpdateResponseDTO>();

  async function onApplyAddingNegativeKeywords(negativeKeywords: CreateNegativeModel[]) {
    setIsSendingNegativeKeywords(true);
    try {
      const response = await negativeTargetingService.createNegativeKeywords(negativeKeywords);

      // TODO: similar logic is in many places. Refactor into one function?
      if (response.isSuccess) {
        setApplyResponse({ responseErrorMsg: null, payload: response.payload.created });

        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
        }
      } else {
        setApplyResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
      }
    } catch (error) {
      console.error('error');
      setApplyResponse({ responseErrorMsg: `Error adding negative keywords: ${getErrorMessage(error)}`, payload: undefined });
    } finally {
      setUpdateResponseModalOpen(true);
      setIsSendingNegativeKeywords(false);
    }
  }

  // KEYWORD HARVESTING PARAMS MODAL
  const [isKeywordHarvestingParamsModalOpen, setIsKeywordHarvestingParamsModalOpen] = useState(false);

  // KEYWORD HARVESTING MODAL
  const [isKeywordHarvestingModalOpen, setIsKeywordHarvestingModalOpen] = useState(false);
  const [harvestingData, setHarvestingData] = useState<KeywordHarvestingModel | null>(null);
  const [isLoadingHarvestingData, setIsRequestingHarvestingData] = useState(false);
  const [isSendingHarvestingData, setIsSendingHarvestingData] = useState(false);

  // Triggered by harvesting params modal
  function onPreviewClicked(harvestingParams: HarvestingParams, filters: AlFilterModel[]) {
    const fetchPreviewResults = async () => {
      try {
        setIsRequestingHarvestingData(true);

        const response = await keywordHarvestingService.getKeywordHarvestingPreview(selectedSearchTerms, harvestingParams, filters);
        if (response.isSuccess) {
          setHarvestingData(response.payload);
          setIsKeywordHarvestingModalOpen(true);
        } else {
          toast.error('Error loading harvesting data ' + JSON.stringify(response));
        }
      } catch (error) {
        toast.error('Error loading harvesting data');
        console.error(error);
      } finally {
        setIsRequestingHarvestingData(false);
      }
    };
    fetchPreviewResults();
  }

  function onKeywordHarvestingModalClose() {
    setIsKeywordHarvestingModalOpen(false);
    setHarvestingData(null);
  }

  async function onSendHarvestingData(harvestingPreviewSelection: KeywordHarvestingPreviewDataRow[]) {
    setIsSendingHarvestingData(true);
    onKeywordHarvestingModalClose();
    try {
      const response = await keywordHarvestingService.applyHarvestingData(harvestingPreviewSelection);

      if (response.isSuccess) {
        setApplyResponse({ responseErrorMsg: null, payload: response.payload.created });

        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
          invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile.id);
          invalidateProfile_searchTermsWithTimelineQueryKeys(queryClient, activeProfile.id);
        }
        setUpdateResponseModalOpen(true);
        if (searchTermsTableGridApiRef.current && !searchTermsTableGridApiRef.current.isDestroyed()) {
          searchTermsTableGridApiRef.current.deselectAll();
        }
      } else {
        setApplyResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
        setUpdateResponseModalOpen(true);
      }
    } catch (error) {
      toast.error('Error sending harvesting data');
      console.error(error);
    } finally {
      setIsSendingHarvestingData(false);
    }
  }

  useEffect(() => {
    if (isCreateNegativesPreviewModalOpen || isKeywordHarvestingModalOpen) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [isCreateNegativesPreviewModalOpen, isKeywordHarvestingModalOpen]);

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex flex-row items-center gap-4">
              {t('search_terms_page.header')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                How to Harvest Keywords & Products Targets
              </Button>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <SearchTermsProvider filters={filters} setFilters={setFilters}>
              <div className="mt-2">
                <AlErrorBoundary>
                  <SearchTermsMetrics />
                </AlErrorBoundary>
              </div>

              <div className="my-2">
                <AlErrorBoundary>
                  <SearchTermsTimelineChart />
                </AlErrorBoundary>
              </div>

              <AlErrorBoundary>
                <SearchTermsFilterBar withTimeline={searchTermsWithTimeline} gridApiRef={searchTermsTableGridApiRef} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <SearchTermsTable
                  withTimeline={searchTermsWithTimeline}
                  isLoading={isSearchTermsRowDataLoading}
                  selectedSearchTerms={selectedSearchTerms}
                  setSelectedSearchTerms={setSelectedSearchTerms}
                  searchTermsLoadingErrorMessage={searchTermsLoadingError instanceof Error ? searchTermsLoadingError.message : ''}
                  isSearchTermsLoadingError={isSearchTermsLoadingError}
                  onGridReadyCallback={onSearchTermsTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <KeywordHarvestingParamsModal
                  isOpen={isKeywordHarvestingParamsModalOpen}
                  onClose={() => setIsKeywordHarvestingParamsModalOpen(false)}
                  selectedSearchTerms={selectedSearchTerms}
                  onPreviewClicked={onPreviewClicked}
                />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <KeywordHarvestingPreviewModal
                  isOpen={isKeywordHarvestingModalOpen}
                  onClose={onKeywordHarvestingModalClose}
                  onApplyChanges={onSendHarvestingData}
                  filtersUsed={filters}
                  keywordHarvestingData={harvestingData}
                />
              </AlErrorBoundary>
            </SearchTermsProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <SearchTermsSelectionActionsBar
          selectedSearchTerms={selectedSearchTerms}
          setSelectedSearchTerms={setSelectedSearchTerms}
          totalSearchTermsCount={searchTermsWithTimeline?.searchTerms ? searchTermsWithTimeline.searchTerms.length : 0}
          onDiscardClicked={clearSelections}
          onCreateNegativesParamsClicked={onCreateNegativesParamsClicked}
          searchTermsTableGridApiRef={searchTermsTableGridApiRef}
          onHarvestClicked={() => setIsKeywordHarvestingParamsModalOpen(true)}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CreateNegativesParamsModal
          isOpen={isCreateNegativesParamsModalOpen}
          onClose={() => setIsCreateNegativesParamsModalOpen(false)}
          selectedSearchTerms={selectedSearchTerms}
          onCreateNegativesParamsConfirm={onCreateNegativesParamsConfirm}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CreateNegativesPreviewModal
          isOpen={isCreateNegativesPreviewModalOpen}
          onClose={() => setIsCreateNegativesPreviewModalOpen(false)}
          negativeKeywordsToAdd={negativeKeywordsToAdd}
          onApplyAddingNegativeKeywords={onApplyAddingNegativeKeywords}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <UpdateResponseModal
          isOpen={updateResponseModalOpen}
          onClose={() => setUpdateResponseModalOpen(false)}
          updateResponse={applyResponse}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.KW_HARVESTING}
        title={'How to Harvest Keywords & Products Targets'}
      />

      <LoadingOverlay isVisible={isLoadingHarvestingData} message="Reaping the harvest..." />
      <LoadingOverlay isVisible={isSendingNegativeKeywords || isSendingHarvestingData} message="Sending new data to Amazon..." />
      {paywallModalElement}
      {navigationModal}
    </>
  );
};

export default SearchTermsPage;
